import { Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPairList, isLevel3 } from "../../../helpers";
import { viewForTrans } from "../../../redux/feature";
import {
  getBalanceHistory,
  getDefiWalletData,
  getDepositHistory,
  getMarketList,
  getTransHisTrades,
  getWithdrawHistory,
} from "../../../redux/services";
import { fetchBuySellHistory } from "../../../redux/services/buySellServices";
import {
  exchangeOrdersTabs,
  settingHisotryTabsKey,
} from "../../../staticObjects";
import { ReactComponent as FilterIcon } from "../../Assets/Images/filter.svg";
import Orders from "./Orders";
import {
  Buy,
  Receive,
  Sell,
  Send,
  Trades,
  TransferHistory,
} from "./TransHistoryComponents";
import style from "./style.module.scss";

import Export from "../../Assets/Images/export.svg";
import { setFilter } from "../../../redux/feature/exchange/exchanges.slice";
import { useTranslation } from "react-i18next";
import { Convert } from "./ConvertHistory";
import { WalletDetails } from "../Metamask/WalletDetails";
import CustomTabs from "../../CustomTabs/CustomTabs";

const TransactionsHistory = ({ state }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { clickViewFrom } = useSelector((state) => state?.commonApiData);
  const { data } = useSelector((state) => state?.user);
  const { level } = data;
  const { marketPairList } = useSelector((state) => state.transactionHistories);
  const { currentMarketId } = useSelector((state) => state.commonApiData);
  const [depositType, setDepositType] = useState("internal");
  const [withdrawType, setWithdrawType] = useState("internal");
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [isExportVisible, setIsExportVisible] = useState(false);

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
    setIsExportVisible(false);
  };

  const toggleExport = () => {
    if (!isLevel3(level)) {
      message.error(t("transhistory.errors.complete_kyc"));
      return;
    }
    setIsExportVisible(!isExportVisible);
    setIsFilterVisible(false);
  };

  const {
    buy,
    sell,
    send,
    receive,
    orders,
    trades,
    transfer,
    convert,
    wallet,
  } = settingHisotryTabsKey;
  const { openOrder, allOrder, tradeHistory } = exchangeOrdersTabs;

  const [currentTab, setCurrentTab] = useState(send);

  const commProp = {
    isFilterVisible: isFilterVisible,
    isExportVisible: isExportVisible,
  };

  const onDepositTabChage = (tabname) => {
    setDepositType(tabname);
    let sendData = {
      limit: 10,
      page: 1,
    };
    sendData["transaction_type"] = tabname == "internal" ? 300 : 400;
    dispatch(getDepositHistory({ ...sendData }));
  };

  const onWithdrawTabChage = (tabname) => {
    setWithdrawType(tabname);
    let sendData = {
      limit: 10,
      page: 1,
    };
    sendData["transaction_type"] = tabname;
    dispatch(getWithdrawHistory({ ...sendData }));
  };

  useEffect(() => {
    if ([openOrder, allOrder].includes(clickViewFrom)) {
      setCurrentTab(orders);
    }
    if ([tradeHistory].includes(clickViewFrom)) {
      if (!marketPairList.length) return;
      setCurrentTab(trades);
      hitApi(trades);
      return;
    }
    if (clickViewFrom === "convert") {
      setCurrentTab(convert);
    }
    if (clickViewFrom === "wallet") {
      setCurrentTab(wallet);
      hitApi(wallet);
    }
    return () => {
      dispatch(viewForTrans({ clickViewFrom: "", marketId: "" }));
    };
  }, [clickViewFrom]);

  const withdrawTab = [
    {
      key: "internal",
      label: t("titles.internal"),
      children: <Send {...commProp} withdrawType={withdrawType} />,
    },
    {
      key: "external",
      label: t("titles.external"),
      children: <Send {...commProp} withdrawType={withdrawType} />,
    },
  ];

  const depositTab = [
    {
      key: "internal",
      label: t("titles.internal"),
      children: <Receive {...commProp} depositType={depositType} />,
    },
    {
      key: "external",
      label: t("titles.external"),
      children: <Receive {...commProp} depositType={depositType} />,
    },
  ];

  const tabsObj = [
    // {
    //   tab: t("tabs.buy"),
    //   keys: buy,
    //   component: currentTab === buy && (
    //     <div className={style.buyTable}>
    //       <Buy {...commProp} />
    //     </div>
    //   ),
    // },
    // {
    //   tab: t("tabs.sell"),
    //   keys: sell,
    //   component: currentTab === sell && (
    //     <div className={style.buyTable}>
    //       <Sell {...commProp} />
    //     </div>
    //   ),
    // },
    {
      tab: t("tabs.send"),
      keys: send,
      component: currentTab === send && (
        <div className={style.sendTable}>
          <CustomTabs
            main
            type="card"
            items={withdrawTab}
            activekey={withdrawType}
            hanldeTabChange={onWithdrawTabChage}
          />
        </div>
      ),
    },
    {
      tab: t("tabs.recieve"),
      keys: receive,
      component: currentTab === receive && (
        <div className={style.sendTable}>
          <CustomTabs
            main
            type="card"
            items={depositTab}
            activekey={depositType}
            hanldeTabChange={onDepositTabChage}
          />
        </div>
      ),
    },
    {
      tab: t("tabs.orders"),
      keys: orders,
      component: currentTab === orders && (
        <div className={style.orderTable}>
          <Orders {...commProp} />
        </div>
      ),
    },
    {
      tab: t("tabs.trades"),
      keys: trades,
      component: currentTab === trades && (
        <div className={style.tradeTable}>
          <Trades {...commProp} />
        </div>
      ),
    },
    {
      tab: t("tabs.transfer"),
      keys: transfer,
      component: currentTab === transfer && (
        <div className={style.tradeTable}>
          <TransferHistory {...commProp} />
        </div>
      ),
    },
    {
      tab: "Convert",
      keys: convert,
      component: currentTab === convert && (
        <div className={style.convertTable}>
          <Convert paginate={true} {...commProp} />
        </div>
      ),
    },
    {
      tab: "Wallet Data",
      keys: wallet,
      component: currentTab === wallet && (
        <div className={style.walletTable}>
          <WalletDetails paginate={true} {...commProp} />
        </div>
      ),
    },
  ];

  const hitApi = (tabName) => {
    const sendData = {
      limit: 10,
      page: 1,
    };
    switch (tabName) {
      case buy:
        sendData["transak_type"] = "BUY";
        dispatch(fetchBuySellHistory({ ...sendData }));
        break;
      case sell:
        sendData["transak_type"] = "SELL";
        dispatch(fetchBuySellHistory({ ...sendData }));
        break;
      case send:
        setDepositType("internal");
        sendData["transaction_type"] = "internal";
        dispatch(getWithdrawHistory({ ...sendData }));
        break;
      case receive:
        setWithdrawType("internal");
        sendData["transaction_type"] = 300;
        dispatch(getDepositHistory({ ...sendData }));
        break;
      case trades:
        if (!marketPairList.length) return;
        const pairList = createPairList(marketPairList, t);
        // let toSendMarket = currentMarketId
        //   ? currentMarketId
        //   : pairList[0].value;
        // dispatch(getTrades({ ...sendData, market: toSendMarket }));
        dispatch(getTransHisTrades({ ...sendData, market: pairList[0].value }));
        break;
      case transfer:
        dispatch(
          getBalanceHistory({
            ...sendData,
            // reference_type: "Account",
          })
        );
        break;
      case wallet:
        dispatch(
          getDefiWalletData({
            ...sendData,
          })
        );
        break;
      default:
    }
  };

  useEffect(() => {
    hitApi(currentTab);
    dispatch(getMarketList());
  }, []);

  useEffect(() => {
    if ([openOrder, allOrder].includes(state)) {
      setCurrentTab(orders);
      return;
    }
    if ([tradeHistory].includes(state)) {
      if (!marketPairList.length) return;
      setCurrentTab(trades);
      hitApi(trades);
      return;
    }
  }, [state, marketPairList]);

  const onTabChage = (tabname) => {
    setCurrentTab(tabname);
    dispatch(setFilter({}));
    hitApi(tabname);
    setIsFilterVisible(true);
    setIsExportVisible(false);
    dispatch(viewForTrans({ clickViewFrom: "", marketId: "" }));
  };

  let isShowExport = [
    buy,
    sell,
    send,
    receive,
    orders,
    trades,
    transfer,
    convert,
    wallet,
  ].includes(currentTab);
  let isShowFilter = [
    buy,
    sell,
    send,
    receive,
    orders,
    trades,
    transfer,
    convert,
    wallet,
  ].includes(currentTab);

  return (
    <div
      style={{ borderRadius: "20px", overflow: "hidden" }}
      className="textDecoration"
    >
      <div className="filter_tabs filer">
        {isShowExport && (
          <p
            title={t("titles.export")}
            onClick={toggleExport}
            className={isExportVisible ? "exportBtn" : "exportBtn"}
          >
            <img src={Export} alt="" />
            {t("titles.export")}
          </p>
        )}
        {isShowFilter && (
          <FilterIcon
            onClick={toggleFilter}
            title={t("titles.filter")}
            className={isFilterVisible ? "active" : ""}
          />
        )}
      </div>
      <Tabs
        className="transactionHistory"
        onChange={onTabChage}
        defaultActiveKey={currentTab}
        activeKey={currentTab}
        animated
      >
        <>
          {tabsObj?.map((item, idx) => {
            return (
              <TabPane tab={item.tab} key={item.keys}>
                {item.component}
              </TabPane>
            );
          })}
        </>
      </Tabs>
    </div>
  );
};

export default TransactionsHistory;
