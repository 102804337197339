export const confirmModalVal = {
  amount: "",
  apy: "",
  id: "",
  duration: "",
  name: "",
  earn: "",
  curId: "",
};

export const timeLimitObjDropOpt = (t) => [

  {
    label: `15 ${t("static_objs.min")}`,
    value: 15,
  },
  {
    label: `30 ${t("static_objs.min")}`,
    value: 30,
  },
  {
    label: `1 ${t("static_objs.hour")}`,
    value: 60,
  },
  {
    label: `2 ${t("static_objs.hours")}`,
    value: 120,
  },
  {
    label: `3 ${t("static_objs.hours")}`,
    value: 180,
  },
];

export const timeLimitObject = [
  {
    label: "15 Min",
    value: 15,
  },
  {
    label: "30 Min",
    value: 30,
  },
  {
    label: "1 Hour",
    value: 60,
  },
  {
    label: "2 Hours",
    value: 120,
  },
  {
    label: "3 Hours",
    value: 180,
  },
];

export const adStatusOptionDropOpt = (t) => [
  {
    label: t("static_objs.all_status"),
    value: "",
  },
  {
    label: t("static_objs.online"),
    value: "online",
  },
  {
    label: t("static_objs.offline"),
    value: "offline",
  },
  {
    label: t("static_objs.completed"),
    value: "completed",
  },
];

export const bidFilterStateDropOpt = (t) => [
  {
    label: t("static_objs.all_states"),
    value: "",
  },

  {
    label: t("static_objs.canceled"),
    value: "canceled",
  },
  {
    label: t("static_objs.processing"),
    value: "processing",
  },

  {
    label: t("static_objs.completed"),
    value: "done",
  },
];

export const orderTypeDropOpt = (t) => [
  {
    label: t("static_objs.all_types"),
    value: "",
  },
  {
    label: t("static_objs.buy"),
    value: "sell",
  },
  {
    label: t("static_objs.sell"),
    value: "bid",
  },
];

export const onOffRadio = (t) => [
  {
    label: t("static_objs.on_line"),
    value: "online",
  },
  {
    label: t("static_objs.offline_now"),
    value: "offline",
  },
];

export const adTypeDropOpt = (t) => [
  {
    label: t("static_objs.all_types"),
    value: "",
  },
  {
    label: t("static_objs.buy"),
    value: "sell",
  },
  {
    label: t("static_objs.sell"),
    value: "bid",
  },
];

export const reportReason = (t) => [
  {
    value: "Trading order fraud or scam",
    label: t("static_objs.trading_order"),
  },
  {
    value: " Advertisement conditions unreasonable",
    label: t("static_objs.advertisement"),
  },
  {
    value: "Other Reason",
    label: t("static_objs.other"),
  },
];