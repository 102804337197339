import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";
import { Col, Row, Grid, Table } from "antd";
import {
  portfolioOverviewSubTabsKey,
  upDownPriceColor,
} from "../../../../staticObjects";
import {
  getCloneData,
  localeDateSec,
  preciseData,
  removeTrailingZero,
  truncateData,
  uppercase,
} from "../../../../helpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NoRecord } from "../../../NoRecord";

function WorkingDetails({
  data,
  market_id,
  quote_unit,
  quantity_per_order,
  base_unit,
}) {
  const { useBreakpoint } = Grid;
  const { t } = useTranslation();
  const { up, down } = upDownPriceColor;
  const [lastPriceChanged, setLastPriceChanged] = useState(
    t("workingdetails.positive")
  );
  const { wallet, coin } = portfolioOverviewSubTabsKey;
  const { marketTickers, marketList, kline } = useSelector(
    (state) => state?.exchange
  );
  const [prevVal, setPrevVal] = useState("");

  const defaultTicker = {
    low: 0,
    last: 0,
    high: 0,
    total_volume: 0,
    price_change_percent: "0.00%",
    total_volume_base_currency: 0,
  };

  const getTickerValue = (value) => {
    return marketTickers[market_id]?.ticker !== undefined
      ? (marketTickers[market_id]?.ticker || defaultTicker)[value]
      : (marketTickers[market_id] || defaultTicker)[value];
  };

  useEffect(() => {
    let change = "";
    if (market_id !== undefined) {
      let currLastVal = marketTickers[market_id]?.last;
      if (currLastVal < prevVal) {
        change = "negative";
        setLastPriceChanged(t("workingdetails.negative"));
      } else if (currLastVal > prevVal) {
        change = "positive";
        setLastPriceChanged(t("workingdetails.positive"));
      } else {
        change = "stable";
        setLastPriceChanged(t("workingdetails.stable"));
      }

      setPrevVal(currLastVal);
    }
  }, [marketTickers]);

  const sideColor = (status) => {
    let orderStatus = status.toLowerCase();
    switch (orderStatus) {
      case "sell": {
        return "#b42463";
      }
      case "buy": {
        return "#389694";
      }
      case "wait": {
        return "yellow";
      }
      case "pending": {
        return "yellow";
      }
      case "done": {
        return "green";
      }
      case "cancel": {
        return "red";
      }
      case "reject": {
        return "red";
      }
      default: {
        return "";
      }
    }
  };

  const last = getTickerValue("last")

  const { md } = useBreakpoint();

  const buyOrders = useMemo(() => {
    let filterData = data.filter((item) => item.side === "buy");
    return filterData.length > 0 ? filterData.sort((a, b) => parseFloat(b.price) - parseFloat(a.price)) : [];
  });

  const sellOrders = useMemo(() => {
    let filterData = data.filter((item) => item.side === "sell");
    return filterData.length > 0 ? filterData.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)) : [];

  });

  const columns = [
    {
      title: `${t("workingdetails.price")}  (${uppercase(quote_unit)})`,
      dataIndex: `price`,
    },
    {
      title: `${t("workingdetails.amount")} (${uppercase(base_unit)})`,
      dataIndex: `amount`,
    },
    {
      title: t("workingdetails.remaining_amt"),
      dataIndex: `remainingAmt`,
    },
    {
      title: `${t("workingdetails.total")} (${uppercase(quote_unit)})`,
      dataIndex: "total",
    },
  ];

  const checkState = (state) => {
    switch (state) {
      case "wait": {
        return t("workingdetails.new");
      }
      case "pending": {
        return t("workingdetails.partial");
      }
      case "done": {
        return t("workingdetails.done");
      }
      case "cancel": {
        return t("workingdetails.canceled");
      }
      case "reject": {
        return t("workingdetails.rejected");
      }
      default: {
        return t("workingdetails.new");
      }
    }
  };

  const getMarketName = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined) {
      return market.name;
    } else {
      return "-";
    }
  };

  const getAmountPercision = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined) return market.amount_precision;
  };

  const getPricePercision = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined) return market.price_precision;
  };

  const getTotalPercision = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined)
      return market.amount_precision + market.amount_precision;
  };

  const datax = (row) => {
    return (
      row.length > 0 &&
      row.map((row, index) => {
        const amountFixed = getAmountPercision(marketList, row?.market);
        const priceFixed = getPricePercision(marketList, row?.market);
        const totalPrecision = getTotalPercision(marketList, row?.market);
        return {
          id: <span>{index + 1}</span>,
          order_id: <span>{row?.id}</span>,
          datetime: <p>{localeDateSec(row?.updated_at)}</p>,
          pair: <p>{getMarketName(marketList, row?.market)}</p>,
          type: (
            <p>
              {row?.ord_type.charAt(0).toUpperCase() + row?.ord_type.slice(1)}
            </p>
          ),
          side: (
            <div className="flexCenter">
              <div
                className="flexCenter orderCls"
                style={{
                  backgroundColor: sideColor(row?.side),
                }}
              >
                <small>
                  {row?.side.charAt(0).toUpperCase() + row?.side.slice(1)}{" "}
                </small>
              </div>
            </div>
          ),
          average: (
            <div>
              {row?.state === "cancel" && row?.trades_count === 0
                ? "--"
                : `${removeTrailingZero(
                  preciseData(row?.avg_price, priceFixed)
                )}(${uppercase(row?.bid)})`}
            </div>
          ),
          price: (
            <div>
              {["limit", "stop_loss_limit", "take_profit_limit"].includes(
                row?.ord_type
              )
                ? `${removeTrailingZero(preciseData(row?.price, priceFixed))}`
                : t("workingdetails.market")}
            </div>
          ),
          stop: (
            <div className="flexCenter">
              <span>
                {[
                  "stop_loss_limit",
                  "take_profit_limit",
                  "stop_loss_order",
                  "take_profit_limit",
                ].includes(row?.ord_type)
                  ? removeTrailingZero(preciseData(row?.stop_price, priceFixed))
                  : "-"}
              </span>
            </div>
          ),
          amount: (
            <span>
              {`${removeTrailingZero(
                preciseData(row?.origin_volume, amountFixed)
              )}`}
            </span>
          ),
          filled: (
            <div className="flexCenter">
              {removeTrailingZero(
                preciseData(row?.executed_volume, amountFixed)
              )}
            </div>
          ),
          remainingAmt: (
            <div className="flexCenter">
              <p>
                {removeTrailingZero(
                  preciseData(row?.remaining_volume, amountFixed)
                )}
              </p>
            </div>
          ),
          trade_fee: (
            <span>
              {`${removeTrailingZero(row?.trade_fee)} ${row?.side === "buy" ? uppercase(row?.ask) : uppercase(row?.bid)
                }`}
            </span>
          ),
          total: (
            <p>
              {row?.state === "cancel"
                ? "--"
                : `${removeTrailingZero(
                  preciseData(
                    row?.price * row?.remaining_volume,
                    totalPrecision
                  )
                )}`}
            </p>
          ),
          status: (
            <span style={{ color: sideColor(row?.state) }}>
              {checkState(row?.state)}
            </span>
          ),
        };
      })
    );
  };

  return (
    <div className={style.workingDetails}>
      <div className={style.workingDetails__rate}>
        <p>
          {t("workingdetails.qty_per_order")} :{" "}
          <span>
            {" "}
            {quantity_per_order} {base_unit}
          </span>
        </p>{" "}
        <p>
          {t("workingdetails.last_price")} :{" "}
          <span
            style={{
              color:
                lastPriceChanged == "positive"
                  ? up
                  : lastPriceChanged == "negative"
                    ? down
                    : "",
            }}
          >
            {last} {quote_unit}
          </span>
        </p>
      </div>
      <div className={`${style.workingDetails__table} workingTable`}>
        <Table
          columns={[
            ...columns,
            {
              title: t("trade_history.order_id"),
              dataIndex: "order_id",
            },
            {
              title: `Buy(${buyOrders.length})`,
              dataIndex: "id",
            },
          ]}
          dataSource={datax(buyOrders)}
          pagination={false}
          // className="portfolioTable tableBorderNone buyOrderTable"
          className={`portfolioTable tableBorderNone ${style.buyOrderTable}`}
          locale={{ emptyText: <NoRecord /> }}
        />

        <Table
          columns={[
            ...columns,
            {
              title: t("trade_history.order_id"),
              dataIndex: "order_id",
            },
            {
              title: `Sell(${sellOrders.length})`,
              dataIndex: "id",
            },
          ]}
          dataSource={datax(sellOrders)}
          pagination={false}
          className={`portfolioTable tableBorderNone  ${style.sellOrderTable}`}
          locale={{ emptyText: <NoRecord /> }}
        />
      </div>
    </div>
  );
}

export default WorkingDetails;
