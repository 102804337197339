import { createSlice } from "@reduxjs/toolkit";
import { getNickName, setNickName, setEditNickName } from "../../../services/P2P/nicknameService";
export const nicknameSlice = createSlice({
  name: "nickName",
  initialState: {
    nickname: [],
    error: false,
    status: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNickName.pending, (state) => {
        state.error = false;
      })
      .addCase(getNickName.fulfilled, (state, action) => {
        state.nickname = action.payload;
      })
      .addCase(getNickName.rejected, (state) => {
        state.error = true;
      })
      .addCase(setNickName.pending, (state) => {
        state.error = false;
      })
      .addCase(setNickName.fulfilled, (state, action) => {
        state.nickname = action.payload.data;
      })
      .addCase(setNickName.rejected, (state) => {
        state.error = true;
      })
      .addCase(setEditNickName.pending, (state) => {
        state.error = false;
        state.status = false
      })
      .addCase(setEditNickName.fulfilled, (state, action) => {
        state.nickname = action.payload.data;
        state.status = true
      })
      .addCase(setEditNickName.rejected, (state) => {
        state.error = true;
        state.status = false
      });
  },
});
export default nicknameSlice.reducer;
