import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import * as config from "../../../utils/apiVersion";
import { buildQueryString } from "../../../helpers";

export const getPaymentMethods = createAsyncThunk(
    "payment/getPaymentMethods",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            let url = `/market/payment_method`;
            const res = await API.get(config.p2p)(url);
            return res;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getAllMethods = createAsyncThunk(
    "payment/getAllMethods",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            let url = `/market/payment_method/name?${buildQueryString(payload)}`;
            const res = await API.get(config.p2p)(url);
            return res;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);