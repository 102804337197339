import { createSlice } from "@reduxjs/toolkit";
import { getIdentity, getReactivateOtp } from "../../services";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    error: false,
    identityData: {},
    enableUser : false,
    uid : "",
    enableUserData: {}
  },
  reducers: {
    setEnableUser: (state, action) => {
      state.enableUser = action.payload;
    },
    setEnableUserData: (state, action) => {
      state.enableUserData = action.payload;
    },
    setEnableUid: (state, action) => {
      state.uid = action.payload;

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdentity.pending, (state) => {
        state.error = false;
      })
      .addCase(getIdentity.fulfilled, (state, action) => {
        state.identityData = action.payload.data;
      })
      .addCase(getIdentity.rejected, (state) => {
        state.error = true;
      }).addCase(getReactivateOtp.pending, (state) => {
        state.error = false;
      })
      .addCase(getReactivateOtp.fulfilled, (state, action) => {
        state.uid = action.payload.uid;
      })
      .addCase(getReactivateOtp.rejected, (state) => {
        state.error = true;
      });
  },
});
export default loginSlice.reducer;
export const {setEnableUser, setEnableUserData, setEnableUid} = loginSlice.actions;
