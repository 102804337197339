import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import { buildQueryString } from "../../../helpers";
import * as config from "../../../utils/apiVersion";
import {
  createApealdone,
  documentsUploaded,
  startLoading,
  stopLoading,
} from "../../feature";
import { toastSelector } from "../../selector/toastSelector";
import { getMessage } from "./chatService";

export const createAppeal = createAsyncThunk(
  "appeal/createAppeal",
  async ({ formData, t }, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/appeal?${buildQueryString(formData)}`;
      const res = await API.post(config.p2p)(url, formData);
      dispatch(stopLoading());
      dispatch(
        toastSelector({ message: t("services.appeal.created") }, "success")
      );
      if (res) {
        dispatch(createApealdone({ appealCreated: true, appealError: false }));
      }
      return { appealId: res.id, status: true };
    } catch (e) {
      dispatch(createApealdone({ appealCreated: false, appealError: true }));
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);
export const acceptConsensus = createAsyncThunk(
  "appeal/acceptConsensus",
  async (payload, { dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      let url = `/appeal/consensus_reached`;
      const res = await API.post(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(getSingleAppeal(payload));

      dispatch(
        toastSelector(
          { message: t("services.appeal.pening") },
          "success"
        )
      );
      if (res) {
        return res;
      }
    } catch (e) {
      dispatch(stopLoading());
      return false;
    }
  }
);
export const sendConsensusOtp = createAsyncThunk(
  "appeal/sendConsensusOtp",
  async (payload, { dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      let url = `/appeal/send_code`;
      const res = await API.post(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(toastSelector({ message: t("services.appeal.otp_sent") }, "success"));
      if (res) {
        return res;
      }
    } catch (e) {
      dispatch(stopLoading());
      return false;
    }
  }
);
export const appealAction = createAsyncThunk(
  "appeal/appealAction",
  async (payload, { dispatch }) => {
    const { data } = payload;

    try {
      dispatch(startLoading());
      let url = `/appeal/action`;
      const res = await API.put(config.p2p)(url, data);
      dispatch(stopLoading());
      if (res) {
        return res;
      }
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const cancelBids = createAsyncThunk(
  "appeal/cancelBids",
  async (payload, { dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      let url = `/market/bids/action`;
      await API.put(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(
        toastSelector({ message: t("services.appeal.order_can") }, "success")
      );
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const partialyPaymentStatus = createAsyncThunk(
  "appeal/partialyPaymentStatus",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/bids/payment_status/partial`;
      await API.put(config.p2p)(url, payload);
      dispatch(
        getMessage({
          bid_id: payload.bid_id,
          page: 1,
          limit: 100,
        })
      );
      dispatch(stopLoading());

      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const getAppeals = createAsyncThunk(
  "appeal/getAppeals",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/appeal/list?${buildQueryString(payload)}`;
      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      if (e.message[0] !== "bid.not_found") {
        dispatch(toastSelector(e, "error"));
      }
      dispatch(stopLoading());
      return rejectWithValue(e);
    }
  }
);

export const cancelAppeal = createAsyncThunk(
  "appeal/cancelAppeal",
  async (payload, { dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      let url = `/appeal/action`;
      await API.put(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(
        toastSelector({ message: t("services.appeal.appeal_can") }, "success")
      );
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const getSingleAppeal = createAsyncThunk(
  "appeal/getSingleAppeal",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/appeal?${buildQueryString(payload)}`;
      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const paymentStatusComplete = createAsyncThunk(
  "appeal/paymentStatusComplete",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `market/bids/payment_status/completed`;
      let res = await API.put(config.p2p)(url, payload);
      dispatch(stopLoading());
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const reAppeal = createAsyncThunk(
  "appeal/reAppeal",
  async ({ formData, t }, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/appeal/appeal_histories`;
      const res = await API.post(config.p2p)(url, formData);
      dispatch(stopLoading());
      dispatch(
        toastSelector({ message: t("services.appeal.docs") }, "success")
      );
      if (res) {
        dispatch(
          documentsUploaded({
            documentUploadedStatus: true,
            docuentsError: false,
          })
        );
      }
      return { appealId: res.id, status: true };
    } catch (e) {
      dispatch(
        documentsUploaded({
          documentUploadedStatus: false,
          docuentsError: true,
        })
      );
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const appealActions = createAsyncThunk(
  "appeal/appealActions",
  async (payload, { dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      let url = `/appeal/action`;
      const res = await API.put(config.p2p)(url, payload);
      dispatch(toastSelector({ message: t("services.appeal.submitted") }, "success"));
      dispatch(stopLoading());
      return { list: res, state: res.state };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const getAppealsReason = createAsyncThunk(
  "appeal/getAppealsReason",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());

      let url = payload
        ? `/market/appeal_reason?${buildQueryString(payload)}`
        : "/market/appeal_reason";

      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
