import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";

export const genrateWalletAddress = createAsyncThunk(
    "walletConnect/genrateWalletAddress",
    async (payload, { dispatch }) => {
        try {
            const { id, blockchain_key } = payload
            dispatch(startLoading());
            let url = `account/create_address/${id}?blockchain_key=${blockchain_key}`;
            const res = await API.post(config.peatio)(url, payload);
            dispatch(stopLoading());
            return { data: res.address, status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));

            return false;
        }
    }
);

export const sendDefiWalletData = createAsyncThunk(
    "walletConnect/sendDefiWalletData",
    async (payload, { dispatch }) => {
        try {
            // dispatch(startLoading());
            let url = `account/defi_wallet`;
            const res = await API.post(config.peatio)(url, payload);
            // dispatch(stopLoading());
            return { data: res, status: true };
        } catch (e) {
            // dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const getDefiWalletData = createAsyncThunk(
    "walletConnect/getDefiWalletData",
    async (payload, { dispatch }) => {
        const { loadingFalse } = payload;
        delete payload.loadingFalse
        try {
            if (!loadingFalse) {
                dispatch(startLoading());
            }
            let url = `account/defi_wallet?${buildQueryString(payload)}`;
            const res = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: res?.data, total: res.headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const sendDefiWalleReport = createAsyncThunk(
    "walletConnect/sendDefiWalleReport",
    async (payload, { dispatch }) => {
        try {
            let url = `account/defi_wallet/update`;
            const res = await API.put(config.peatio)(url, payload);
            return { data: res, status: true };
        } catch (e) {
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);