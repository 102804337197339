import { lowercase, uppercase } from ".";

export const currencyDropObj = (currencylist, t) => {
  let arr = [{ label: t("helpers.all_curr"), value: "" }];
  currencylist.length > 0 &&
    currencylist
      .filter((data) => data.type === "coin")
      .map((items) => {
        let data = {
          label: uppercase(items.id),
          value: lowercase(items.id),
          icon: items?.icon_url,
        };
        arr.push(data);
      });
  return arr;
};

export const currencyDropObjWallet = (currencylist, t) => {
  let arr = [];
  currencylist.length > 0 &&
    currencylist
      .filter((data) => data.type === "coin")
      .map((items) => {
        let data = {
          label: uppercase(items.id),
          value: lowercase(items.id),
          icon: items?.icon_url,
        };
        arr.push(data);
      });
  return arr;
};
