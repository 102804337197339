import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { errorToastSelector } from "../selector";

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/notification?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.p2pWithHeader)(url);
      return { list: data, total: headers?.total };
    } catch (e) {
      if (e.code !== 401) {
        dispatch(errorToastSelector(e));
      }
      return rejectWithValue(e);
    }
  }
);

export const readNotification = createAsyncThunk(
  "notification/readNotification",
  async (payload, { dispatch }) => {
    try {
      let url = `/notification/action`;
      const res = await API.put(config.p2p)(url, payload);
      return { state: res.state, status: true };
    } catch (e) {
      if (e.message[0] !== "notification.cannot_read") {
        dispatch(errorToastSelector(e));
      }
      return false;
    }
  }
);

export const getUserNotification = createAsyncThunk(
  "notification/getUserNotification",
  async (payload, { rejectWithValue, dispatch }) => {
    const type = payload.notifiable_type;
    if (payload.notifiable_type === "Copy") {
      delete payload.notifiable_type;
    }

    try {
      let url;
      if (payload.notifiable_type === "Currency") {
        url = `/account/announcements?${buildQueryString(payload)}`;
      } else {
        url = `account/notifications?${buildQueryString(payload)}`;
      }

      const { data, headers } = await API.get(
        type === "Copy"
          ? config.copyWithHeader
          : type === "GridBotSetting"
            ? config.gridWithHeader
            : config.peatioWithHeader
      )(url);
      return { list: data, total: headers?.total || 0 };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const readUserNotification = createAsyncThunk(
  "notification/readUserNotification",
  async (payload, { dispatch }) => {
    const type = payload.from;
    delete payload.from;

    try {
      let url = `account/notifications/action`;
      const res = await API.put(
        type === "Copy"
          ? config.copy
          : type === "GridBotSetting"
            ? config.grid
            : config.peatio
      )(url, payload);
      return { state: res.state, status: true };
    } catch (e) {
      if (e.message[0] !== "notification.cannot_read") {
        dispatch(errorToastSelector(e));
      }
      return false;
    }
  }
);

export const getAnnouncement = createAsyncThunk(
  "notification/getAnnouncement",
  async (payload, { dispatch }) => {
    try {
      let url = `/account/announcements?${buildQueryString(payload)}`;
      const res = await API.get(config.peatioWithHeader)(url);
      return res;
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);

export const getNotificationsCount = createAsyncThunk(
  "notification/getNotificationsCount",
  async (payload, { dispatch }) => {
    try {
      let url = `/account/notifications/unread_count`;
      const res = await API.get(config.peatioWithHeader)(url);
      return res;
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);
