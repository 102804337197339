import { createSlice } from "@reduxjs/toolkit";
import { getAppeals, getAppealsReason, getSingleAppeal } from "../../../services/P2P";

export const appealSlice = createSlice({
  name: "appeal",
  initialState: {
    appealList: [],
    singleappeal: {},
    error: false,
    appealError: false,
    appealCreated: false,
    documentUploadedStatus: false,
    docuentsError: false,
    appealReasons: [],
    btnStatus: false,
    appealStatus: false,
    consensusModal: false
  },
  reducers: {
    initBlankSingleAppeal: (state) => {
      state.singleappeal = {};
    },
    createApealdone: (state, action) => {
      state.appealCreated = action.payload.appealCreated;
      state.appealError = action.payload.appealError;
    },
    documentsUploaded: (state, action) => {
      state.documentUploadedStatus = action.payload.documentUploadedStatus;
      state.docuentsError = action.payload.docuentsError;
    },
    hideExtraBtns: (state, action) => {
      state.btnStatus = action.payload.btnStatus;
    },
    cancelAppealStatus: (state, action) => {
      state.appealStatus = action.payload.appealStatus;
    },
    setConsensusModal: (state, action) => {
      state.consensusModal = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppeals.pending, (state) => {
        state.error = false;
      })
      .addCase(getAppeals.fulfilled, (state, action) => {
        state.appealList = action.payload;
      })
      .addCase(getAppeals.rejected, (state) => {
        state.error = true;
      })
      .addCase(getSingleAppeal.pending, (state) => {
        state.error = false;
      })
      .addCase(getSingleAppeal.fulfilled, (state, action) => {
        state.singleappeal = action.payload;
      })
      .addCase(getSingleAppeal.rejected, (state) => {
        state.error = true;
      })
      .addCase(getAppealsReason.pending, (state) => {
        state.error = false;
      })
      .addCase(getAppealsReason.fulfilled, (state, action) => {
        state.appealReasons = action.payload;
      })
      .addCase(getAppealsReason.rejected, (state) => {
        state.error = true;
      });
  },
});

export default appealSlice.reducer;
export const { setConsensusModal, initBlankSingleAppeal, createApealdone, documentsUploaded, hideExtraBtns, cancelAppealStatus } = appealSlice.actions;
