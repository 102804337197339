import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import StepContent from "./StepContent";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { truncateFun, uppercase } from "../../../helpers";
import { useTranslation } from "react-i18next";

const LaunchpadStepList = () => {
  const { t } = useTranslation();
  const [stepperState, setStepperState] = useState(0);
  const balanceList = useSelector((state) => state?.commonApiData?.balanceList);
  const selectedLaunchpadCoinList = useSelector((state) => state?.launchpad?.selectedLaunchpadCoinList);
  useEffect(() => {
    if (selectedLaunchpadCoinList?.state === "online") {
      setStepperState(0);
    } else if (selectedLaunchpadCoinList?.state === "calculation") {
      setStepperState(1);
    } else if (selectedLaunchpadCoinList?.state === "calculation_completed" || selectedLaunchpadCoinList?.state === "released") {
      setStepperState(2);
    } else {
      setStepperState(0);
    }
  }, [selectedLaunchpadCoinList]);

  return (
    <div className={`customStep ${style.TimeLine}`}>
      <Steps
        direction="vertical"
        current={stepperState}
        items={[
          {
            title: t("launchpad.subscription_period"),
            description: (
              <StepContent
                commitment={t("launchpad.your_max")}
                display={true}
                greenCommitment={`${balanceList.length > 0 && balanceList[0]?.balance && selectedLaunchpadCoinList?.public_sale_token_price
                  ? truncateFun(
                    balanceList[0]?.balance /
                    Number(selectedLaunchpadCoinList?.public_sale_token_price),
                    6
                  )
                  : "0.00"} 
                  ${selectedLaunchpadCoinList?.symbol ? uppercase(selectedLaunchpadCoinList?.symbol) : ""}`}
                title={t("launchpad.your_commited", { curr: "USDT" })}
                Detail={`${selectedLaunchpadCoinList?.commited_mnt
                  ? truncateFun(selectedLaunchpadCoinList?.commited_mnt, 2)
                  : selectedLaunchpadCoinList?.commited_mnt == 0
                    ? "0.00"
                    : "0.00"
                  } USDT`}
                showCountDown={false}
                heading={t("launchpad.reminder", { curr: "USDT" })}
                mint
                modalopen
              />
            ),
          },
          {
            title: t("launchpad.calculation_period"),
            description: (
              <StepContent
                commitment={t("launchpad.your_commited", { curr: "USDT" })}
                display={stepperState > 0}
                greenCommitment={`${selectedLaunchpadCoinList?.state === "calculation" ||
                  selectedLaunchpadCoinList?.state === "released" || selectedLaunchpadCoinList?.state === "calculation_completed"
                  ? `${selectedLaunchpadCoinList?.commited_mnt
                    ? truncateFun(selectedLaunchpadCoinList?.commited_mnt, 2)
                    : selectedLaunchpadCoinList?.commited_mnt === 0
                      ? "0.00"
                      : "0.00"
                  } USDT`
                  : " 0.00 USDT"
                  }`}
                title={t("launchpad.your_token")}
                Detail={
                  selectedLaunchpadCoinList?.state === "calculation"
                    ? t("launchpad.cal_in_progress")
                    : selectedLaunchpadCoinList?.state === "calculation_completed" || selectedLaunchpadCoinList?.state === "released"
                      ? t("launchpad.cal_done")
                      : ""
                }
                showCountDown={
                  selectedLaunchpadCoinList?.state === "calculation"
                }
                timer={selectedLaunchpadCoinList?.stage_end_date}
                heading={t("launchpad.your_final_token")}
                mint
                buttontext="dskjfjkds"
              />
            ),
          },
          {
            title: t("launchpad.final_token"),
            description: (
              <StepContent
                display={stepperState > 1}
                commitment={t("launchpad.your_final_token", { curr: "USDT" })}
                title={t("launchpad.to_allocated")}
                greenCommitment={`${selectedLaunchpadCoinList?.state === "released"
                  ? `${selectedLaunchpadCoinList?.funds_allocated
                    ? truncateFun(selectedLaunchpadCoinList?.funds_allocated * selectedLaunchpadCoinList?.public_sale_token_price, 2)
                    : selectedLaunchpadCoinList?.funds_allocated === 0
                      ? "0.00"
                      : "0.00"
                  } USDT`
                  : "0.00 USDT"
                  }`}
                Detail={`${selectedLaunchpadCoinList?.state === "released"
                  ? `${selectedLaunchpadCoinList?.funds_allocated
                    ? selectedLaunchpadCoinList?.funds_allocated
                    : selectedLaunchpadCoinList?.funds_allocated === 0
                      ? "0.00"
                      : "0.00"
                  } `
                  : "0.00 "
                  } ${uppercase(selectedLaunchpadCoinList?.symbol)}`}
                showCountDown={selectedLaunchpadCoinList?.state === "calculation_completed"}
                timer={selectedLaunchpadCoinList?.stage_end_date}
                heading={t("launchpad.the_allocation", { curr: "USDT" })}
                mint
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default LaunchpadStepList;
