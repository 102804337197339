import * as yup from "yup";
import { downloadDateName } from "../staticObjects";
const { startDate, endDate } = downloadDateName;

export const startEndDateSchema = (t) => {
  return yup.object().shape({
    [startDate]: yup.date().required(t("schemas.date.start_date")),
    [endDate]: yup
      .date()
      .required(t("schemas.date.end_date"))
      .test(
        "is-greater-or-equal",
        t("schemas.date.must"),
        function (endDateNew) {
          const startDateNew = this.parent[startDate];
          return endDateNew >= startDateNew;
        }
      ),
  });
}
