import { createSlice } from "@reduxjs/toolkit";
import { getPriceAlertList, getSingleMarketPriceAlert } from "../../services/priceAlertService";

const priceAlertSlice = createSlice({
  name: "buySell",
  initialState: {
    allPriceAlertList: [],
    allPriceAlertListTotal: 0,
    singlePriceAlertList: [],
    singlePriceAlertTotal: 0,
    loading: false,
    error: false,
    priceAlertModal: false,
  },
  reducers: {
    setPriceAlertModal: (state, action) => {
      state.priceAlertModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPriceAlertList.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.allPriceAlertList = [];
        state.allPriceAlertListTotal = 0;
      })
      .addCase(getPriceAlertList.fulfilled, (state, action) => {
        state.loading = false;
        state.allPriceAlertList = action.payload.list;
        state.allPriceAlertListTotal = action.payload.total;
      })
      .addCase(getPriceAlertList.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getSingleMarketPriceAlert.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.singlePriceAlertList = [];
        state.singlePriceAlertTotal = 0;
      })
      .addCase(getSingleMarketPriceAlert.fulfilled, (state, action) => {
        state.loading = false;
        state.singlePriceAlertList = action.payload.list;
        state.singlePriceAlertTotal = action.payload.total;
      })
      .addCase(getSingleMarketPriceAlert.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const { setPriceAlertModal } = priceAlertSlice.actions;

export default priceAlertSlice.reducer;
