import { createSlice } from "@reduxjs/toolkit";
import { getBarCode, getBeneficiaryList, getSingleBeneficiary } from "../../../services/P2P";

export const advertisementSlice = createSlice({
  name: "advertisement",
  initialState: {
    beneficiaryList: [],
    beneficiaryListTotal: 0,
    singleBeneficiary: {},
    error: false,
    barcode: {},
    popupStatus: false,
    beneficiaryLoading: false,
    popStatus: false
  },
  reducers: {
    closePaymentPopup: (state, action) => {
      state.popupStatus = action.payload.popupStatus;
    },
    benePopupSttaus: (state, action) => {
      state.popStatus = action.payload.status;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getBeneficiaryList.pending, (state) => {
        state.error = false;
        state.beneficiaryLoading = true;
      })
      .addCase(getBeneficiaryList.fulfilled, (state, action) => {
        state.beneficiaryList = action.payload.list;
        state.beneficiaryListTotal = action.payload.total || 0;

        state.beneficiaryLoading = false;

      })
      .addCase(getBeneficiaryList.rejected, (state) => {
        state.error = true;
        state.beneficiaryLoading = false;
      })

      .addCase(getSingleBeneficiary.pending, (state) => {
        state.error = false;
      })
      .addCase(getSingleBeneficiary.fulfilled, (state, action) => {
        state.singleBeneficiary = action.payload.data;
      })
      .addCase(getSingleBeneficiary.rejected, (state) => {
        state.singleBeneficiary = {};
        state.error = true;
      })
      .addCase(getBarCode.pending, (state) => {
        state.error = false;
      })
      .addCase(getBarCode.fulfilled, (state, action) => {
        state.barcode = action.payload.data;
      })
      .addCase(getBarCode.rejected, (state) => {
        state.error = true;
      });
  },
});

export default advertisementSlice.reducer;
export const { closePaymentPopup, benePopupSttaus } = advertisementSlice.actions
