import axios from 'axios';
import { getCsrfToken } from '../helpers';
import { encryptMessage } from '../helpers/encryptPayload';

const {
  REACT_APP_BARONG_URL,
  REACT_APP_PEATIO_URL,
  REACT_APP_COPY_URL,
  REACT_APP_GRID_URL,
  REACT_APP_P2P_URL,
} = process.env;

const getAPI = () => ({
  barong: REACT_APP_BARONG_URL,
  p2p: REACT_APP_P2P_URL,
  peatio: REACT_APP_PEATIO_URL,
  grid: REACT_APP_GRID_URL,
  copy: REACT_APP_COPY_URL,
});

const buildRequest = (request, configData) => {
  const { body, method, url } = request;
  const { apiVersion, headers } = configData;
  const api = getAPI();
  const contentType =
    body instanceof FormData ? 'multipart/form-data' : 'application/json';

  const defaultHeaders = {
    'content-type': contentType,
    'X-CSRF-Token': getCsrfToken()
  };
  const apiUrl = api[apiVersion];
  const data = (contentType == 'multipart/form-data' || url.includes("public")) ? body : encryptMessage(body)

  const requestConfig = {
    baseURL: apiUrl,
    data: data,
    headers: { ...headers, ...defaultHeaders },
    method,
    url,
    withCredentials: true
  };

  return requestConfig;
};

export const defaultResponse = {
  status: 500,
  data: {
    error: 'Server error'
  }
};

export const formatError = responseError => {
  const response = responseError.response || defaultResponse;
  const errors =
    response.data && (response.data.errors || [response.data.error]);
  return {
    code: response.status,
    message: errors
  };
};

export const makeRequest = async (request, configData) => {
  const requestConfig = buildRequest(request, configData);

  return new Promise((resolve, reject) => {
    const axiosRequest = axios(requestConfig);
    axiosRequest
      .then(response => {
        if (configData.withHeaders) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch(error => {
        reject(formatError(error));
      });
  });
};

/////////////////////////////////////////

const buildRequest2 = (request, configData) => {
  const { body, method, url, params } = request;
  const { apiVersion, headers } = configData;
  const api = getAPI();
  const contentType =
    body instanceof FormData ? 'multipart/form-data' : 'application/json';

  const defaultHeaders = {
    'content-type': contentType,
    'X-CSRF-Token': getCsrfToken()
  };
  const apiUrl = api[apiVersion];

  const data = (contentType == 'multipart/form-data' || url.includes("public")) ? body : encryptMessage(body)
  const requestConfig = {
    baseURL: apiUrl,
    data: data,
    headers: { ...headers, ...defaultHeaders },
    method,
    url,
    params,
    withCredentials: true
  };

  return requestConfig;
};

export const formatError2 = responseError => {
  const response = responseError.response || defaultResponse;
  const errors =
    response.data && (response.data.errors || [response.data.error]);
  return {
    code: response.status,
    message: errors
  };
};

export const makeRequest2 = async (request, configData) => {
  const requestConfig = buildRequest2(request, configData);

  return new Promise((resolve, reject) => {
    const axiosRequest = axios(requestConfig);
    axiosRequest
      .then(response => {
        if (configData.withHeaders) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch(error => {
        reject(formatError(error));
      });
  });
};