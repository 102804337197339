import React, { useEffect, useState } from "react";
import style from "../../AssetsTable/style.module.scss";
import { useTranslation } from "react-i18next";
import { Button, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../customHooks/customPagination";
import { dateToUtc, getBrowserName, getCsrfToken } from "../../../helpers";
import {
  deleteDeviceData,
  getDeviceData,
} from "../../../redux/services/securityServie";

const DeviceManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { list, totalDevice } = useSelector((state) => state.security);
  const [deviceData, setDeviceData] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(
      getDeviceData({
        limit: limit,
        page: pageNo,
      })
    );
  }, [limit, pageNo]);

  const tableCol = [
    { dataIndex: "user_agent", title: <p className="coins">{t("settingsVeri.device_management.device")}</p> },
    { dataIndex: "created_at", title: <p className="coins">{t("settingsVeri.device_management.date_time")}</p> },
    { dataIndex: "user_ip", title: <p className="coins">{t("settingsVeri.device_management.ip_address")}</p> },
    { dataIndex: "action", title: <p className="coins">{t("settingsVeri.device_management.action")}</p> },
  ];

  const onDelete = (token, uid) => {
    if (token && uid) {
      setDeleteModal(true);
      setDeviceData(token + "_" + uid);
    }
  };

  const deleteRecord = async () => {
    if (deviceData) {
      let res = await dispatch(
        deleteDeviceData({
          action: "delete",
          store_session_id: deviceData,
          platform: "web",
          t
        })
      );
      if (res.payload.status) {
        setDeleteModal(false);
      }
    }
  };

  const datax = () => {
    let newData = [];
    list?.length > 0 &&
      list?.map((items, idx) => {
        let temp = {
          keyId: idx,
          user_agent: getBrowserName(items?.user_agent, t) || "-",
          created_at:
            dateToUtc(items?.created_at, "DD/MM/YYYY  HH:mm") || "-",
          user_ip: items?.user_ip || "-",
          action: (
            <Button
              className={"delButton"}
              type="link"
              onClick={() => {
                if (getCsrfToken() === items?.csrf_token) return;
                onDelete(items?.csrf_token, items?.uid);
              }}
            >
              {getCsrfToken() !== items?.csrf_token ? t("settingsVeri.device_management.delete") : "-"}
            </Button>
          ),
        };

        newData.push(temp);
      });
    return newData;
  };

  return (
    <>
      <div className="style_earn__9r+gR">
        <div className={style.earn__assets}>
          <div className={`${style.asset} assetTable`}>
            <div className="topHeading">
              <h4>
                {t("device_management_page.heading")}
              </h4>
              <p className="text-black">{t("device_management_page.desc")}</p>
            </div>
            <div
              className={`deviceManaTable fundingtable ${style.port__leftData} ${style.fundingtable}`}
            >
              <Table
                rowKey={(obj) => obj.keyId}
                columns={tableCol}
                dataSource={datax()}
                pagination={false}
              />
            </div>
            <CustomPagination
              pageNo={pageNo}
              limit={limit}
              total={totalDevice}
              onChange={(pageNo, pageSize) => {
                setPageNo(pageNo);
                setLimit(pageSize);
              }}
            />
          </div>
        </div>
      </div>

      {deleteModal && (
        <Modal
          className="deleteModal"
          open={() => setDeleteModal(true)}
          onOk={() => deleteRecord()}
          onCancel={() => setDeleteModal(false)}
          okText={t("modals.yes")}
          cancelText={t("modals.no")}
          closable={false}
          centered
        >
          <p>{t("settingsVeri.device_management.are_you")}</p>
        </Modal>
      )}
    </>
  );
};

export default DeviceManagement;
