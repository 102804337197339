import React from "react";
import style from "./style.module.scss";
import CommitModaldata from "./CommitModaldata";
import Countdown from "./CountDown";
const StepContent = ({
  date,
  heading,
  mint,
  title,
  Detail,
  commitment,
  greenCommitment,
  modalopen,
  display,
  showCountDown,
  timer
}) => {
  if (!display) {
    return <></>
  }
  return (
    <div>
      <h6>{date}</h6>
      <p>{heading}</p>
      <div className={style.gradientborderCard}>
        {mint && (
          <h5>
            {commitment}
            <span>{greenCommitment}</span>
          </h5>
        )}
        <div className={style.gradientborderCard_flex}>
          <div>
            <h2>
              <span>{title}</span>
              <br />
              {Detail}
            </h2>
          </div>

          {modalopen && <CommitModaldata />}
          {showCountDown && <Countdown targetDate={timer} showCountDown={showCountDown} />}
        </div>
      </div>
    </div>
  );
};

export default StepContent;
