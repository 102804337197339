import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import InputCustom from "../../Ui/input/InputCustom";
import CommonButton from "../../Ui/button/CommonButton";
import CheckboxCustom from "../../Ui/checkbox/CheckboxCustom.jsx";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBalance } from "../../../redux/services/commonServices.js";
import { useParams } from "react-router-dom";
import {
  postCommitMntFormData,
  selectedLaunchpadCoinListings,
} from "../../../redux/services/launchpadService.js";
import { truncateFun } from "../../../helpers/getToFixed.js";
import styles from "./style.module.scss";
import { uppercase } from "../../../helpers/uppercase.js";

function CommitModaldata() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [amount, setAmount] = useState("");
  const [displayAmount, setDisplayAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const [commit, setCommit] = useState(false);
  const [commitNow, setCommmitNow] = useState(false);
  const balanceList = useSelector((state) => state.commonApiData.balanceList);
  const selectedLaunchpadCoinList = useSelector(
    (state) => state.launchpad.selectedLaunchpadCoinList
  );

  const getDetails = async (id) => {
    await dispatch(
      getBalance({
        search: {
          code: "usdt",
        },
      })
    );
  };

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, [id]);

  const {
    remaining_supply,
    symbol,
    max_buy_limit,
    public_sale_token_price,
    state,
    user_bid_status,
    min_buy_limit,
    amount_precision,
    self_listed,
  } = selectedLaunchpadCoinList || {};

  const maxLimit =
    balanceList.length > 0
      ? balanceList[0]?.balance / Number(public_sale_token_price) ??
      Number(public_sale_token_price)
      : "0.00";

  const commitments = [
    {
      commitmentText: t("launchpad.max_commitment_limit"),
      commitmentLimit: `${truncateFun(maxLimit, 6)} ${symbol ? uppercase(symbol) : "-"
        }`,
    },
    {
      commitmentText: t("launchpad.single_initial"),
      commitmentLimit: `${min_buy_limit ? truncateFun(min_buy_limit, amount_precision) : "0.00"
        } ${symbol ? uppercase(symbol) : "-"}`,
    },
    {
      commitmentText: t("launchpad.public_sale"),
      commitmentLimit: `${public_sale_token_price
        ? truncateFun(public_sale_token_price, amount_precision)
        : "0.00"
        } ${symbol ? uppercase(symbol) : "-"}`,
    },
  ];

  const showCommit = () => {
    setCommit(true);
  };

  const handleCancel = () => {
    setAmount("");
    setisChecked(false);
    setCommmitNow(false);
    setCommit(false);
    setErrorMessage("");
  };

  const finalHandleCancel = () => {
    setCommmitNow(false);
  };

  const onFinalCommitSubmit = async () => {
    try {
      let res = await dispatch(
        postCommitMntFormData({
          amount: amount,
          price: public_sale_token_price,
          fiat_currency: "usdt",
          listing_id: Number(id),
        })
      );
      await dispatch(
        selectedLaunchpadCoinListings({
          id: id,
        })
      );
      if (res.payload.status) {
        handleCancel();
      }
    } catch (error) {
      // handleCancel();
    }
  };

  const onCommitSubmit = () => {
    setCommmitNow(true);
  };

  const handleOnChange = (e) => {
    const value = e.target.value;
    const decimalValue = value ? value.split(".")[1] : 0;
    if (!decimalValue || decimalValue.length <= amount_precision) {
      const numberPattern = /^[0-9]*(\.[0-9]{0,20})?$/;
      if (value === "" && amount !== "") {
        setErrorMessage(t("errors.amount_is_req"));
        setAmount("");
        setDisplayAmount(0);
      } else if (numberPattern.test(value)) {
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
          setAmount(value);
          return;
        }
        if (numberValue < Number(min_buy_limit ? min_buy_limit : 0)) {
          setErrorMessage(
            `${t("errors.min_value_is")} ${min_buy_limit ? min_buy_limit : "0.1"}.`
          );
          setAmount(value);
          setDisplayAmount(0);
        } else if (numberValue > Number(maxLimit)) {
          setErrorMessage(
            `${t("errors.max_value_is")} ${maxLimit} ${symbol ? uppercase(symbol) : "-"
            }.`
          );
          setAmount(value);
          setDisplayAmount(0);
        } else {
          setErrorMessage("");
          setAmount(value);
          if (public_sale_token_price) {
            setDisplayAmount(numberValue * Number(public_sale_token_price));
          }
        }
      } else {
        setErrorMessage(t("errors.only_positive"));
        setAmount(value);
        setDisplayAmount(0);
      }
    }
  };

  function findSmallest(maxAmount, remaining_supply, max_buy_limit) {
    let smallest = maxAmount;

    if (remaining_supply < smallest) {
      smallest = remaining_supply;
    }

    if (max_buy_limit < smallest) {
      smallest = max_buy_limit;
    }

    return smallest;
  }

  return (
    <div>
      <Button
        className="btn_custom"
        disabled={state !== "online" || user_bid_status === true || self_listed}
        type="primary"
        onClick={showCommit}
      >
        {`Commit ${selectedLaunchpadCoinList?.symbol
          ? uppercase(selectedLaunchpadCoinList?.symbol)
          : ""
          }`}
      </Button>
      {!commitNow && (
        <Modal
          centered
          width={574}
          className="commitModalstyle launcModal"
          open={commit}
          footer={false}
          // onOk={handleOk}
          onCancel={handleCancel}
        >
          <h3>{t("launchpad.commit_usdt", { curr: "USDT" })}</h3>
          <div className="commitModalstyle_content">
            <ul>
              {commitments.map((commitment, index) => (

                <li key={index}>
                  {commitment.commitmentText}
                  <span style={{ marginLeft: "5px" }}>{commitment.commitmentLimit}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="commitModalstyle_inputs input_textimg ">
            <InputCustom
              placeholder={t("market.enter_amount")}
              label={`${t("launchpad.amount_in")} ${symbol ? uppercase(symbol) : "-"}`}
              basicinput
              value={amount}
              suffix={
                <span
                  style={{
                    color: "#00A79E",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (balanceList.length > 0) {
                      const maxAmount =
                        balanceList[0]?.balance / public_sale_token_price;
                      setErrorMessage("");
                      const smallest = findSmallest(
                        Number(maxAmount),
                        Number(remaining_supply),
                        Number(max_buy_limit)
                      );
                      setAmount(truncateFun(smallest, amount_precision));
                      if (public_sale_token_price) {
                        setDisplayAmount(
                          truncateFun(
                            smallest * Number(public_sale_token_price),
                            amount_precision
                          )
                        );
                      }
                    }
                  }}
                >
                  {t("launchpad.max")}
                </span>
              }
              handleOnChange={handleOnChange}
            />
            {errorMessage && (
              <span className="errorMessage">{errorMessage}</span>
            )}
            <div
              style={{
                display: "flex",
                aligIitems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginTop: "7px" }}>
                <span style={{ fontSize: "17px" }}>{t("launchpad.balance")}:</span>
                <span
                  style={{
                    marginLeft: "7px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {balanceList.length > 0
                    ? balanceList[0]?.balance
                      ? balanceList[0]?.balance
                      : "0.00"
                    : "0.00"}{" "}
                  USDT
                </span>
              </div>
              <div style={{}}>{displayAmount} USDT</div>
            </div>
            <CheckboxCustom
              style={{ display: "flex", marginTop: "10px" }}
              checkboxTxt={
                <span className="launchpadAgre">
                  {t("launchpad.i_have_read")} <span>{t("launchpad.purchase")}</span>
                </span>
              }
              onChange={(e) => setisChecked(e.target.checked)}
              // isChecked={isChecked}
              checked={isChecked}
            />
            <CommonButton
              style={{ width: "100%", marginTop: "52px" }}
              btn_custom
              title={t("launchpad.commit_now")}
              onClick={() => onCommitSubmit()}
              disabled={
                errorMessage !== "" ||
                isChecked === false ||
                !amount ||
                amount === "."
              }
            />
          </div>
        </Modal>
      )}
      {commitNow && (
        <Modal
          centered
          width={574}
          className="commitModalstyle launcModal"
          open={commit}
          footer={false}
          // onOk={handleOk}
          onCancel={finalHandleCancel}
        >
          <h3 className={styles.finalCommitModalHeading}>
            {t("launchpad.confirm_commitment", { curr: "USDT" })}
          </h3>
          <div className={styles.finalCommitModalSubHeading}>
            <h5 className={styles.finalCommitModalFirstSubHeading}>
              USDT {t("launchpad.ammount_to_commit")}
            </h5>{" "}
            <h5 className={styles.finalCommitModalSecondSubHeading}>
              {displayAmount} USDT
            </h5>
          </div>
          <p className={styles.finalCommitModalDescription}>
            {t("launchpad.once_succ")}
            <span> {t("launchpad.temporarily_locked")} </span>
            {t("launchpad.until_the")}
          </p>
          <div className={styles.finalCommitModalbuttonContainer}>
            <CommonButton
              style={{ width: "100%", marginTop: "52px" }}
              btn_custom
              title={t("launchpad.cancel")}
              className={styles.finalCommitModalCancelbutton}
              onClick={() => finalHandleCancel()}
            />
            <CommonButton
              style={{
                width: "100%",
                marginTop: "52px",
                borderRadius: "5px",
                backgroundColor: "#00A79E",
                color: "white",
              }}
              btn_custom
              title={t("launchpad.commit_now")}
              className={styles.finalCommitModalSubmitbutton}
              onClick={() => onFinalCommitSubmit()}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CommitModaldata;
