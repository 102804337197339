import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import { buildQueryString } from "../../../helpers";
import * as config from "../../../utils/apiVersion";
import { startLoading, stopLoading } from "../../feature";
import { toastSelector } from "../../selector/toastSelector";

export const getOneUnitPrice = createAsyncThunk(
  "conversion/oneUnitPrice",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/amount_quote?${buildQueryString(payload)}`;
      const res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getConversionValue = createAsyncThunk(
  "conversion/convertedValue",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `account/amount_quote?${buildQueryString(payload)}`;
      const res = await API.get(config.peatio)(url);
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getOtp = createAsyncThunk(
  "conversion/getOtp",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    try {
      let type = payload.type;
      delete payload.type;
      let url =
        type === "payReceived"
          ? "market/bids/send_code"
          : "market/beneficiaries/send_code";
      const res = await API.post(config.p2p)(url, payload);
      type === "editBankModal" ? dispatch(toastSelector({ message: t("services.conversion.otp_sent") }, "success")) :
        dispatch(toastSelector({ message: t("services.conversion.otp_resent") }, "success"));
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
