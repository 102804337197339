import React, { useState } from "react";
import { Table } from "antd";
import {
  TradesTableDataIndex,
  settingHisotryTabsKey,
} from "../../../staticObjects";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../customHooks/customPagination";
import {
  getBalanceHistory,
  getDepositHistory,
  getTransHisTrades,
  getWithdrawHistory,
} from "../../../redux/services";
import i18n from "../../../i18n";
import {
  createPairList,
  dateToUtc,
  exponentialToDecimalConvert,
  lowercase,
  truncateData2,
  uppercase,
} from "../../../helpers";
import Filter from "../../Ui/filter/Filter";
import ExportData from "../../Ui/exportData";
import { fetchBuySellHistory } from "../../../redux/services/buySellServices";
import { useTranslation } from "react-i18next";

const { buy, sell, send, receive, trades, transfer, convert } =
  settingHisotryTabsKey;
const { date, pair, side, avg_price, price, amount, total, fee } =
  TradesTableDataIndex;

const replaceTxid = (link, repl) => {
  return link !== undefined && link !== null && link.replace("#{txid}", repl);
};

const sendReceiveColumns = (t) => {
  return [
    {
      title: <p className="coins">{t("titles.status")}</p>,
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: t("titles.amount"),
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: t("titles.currency"),
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: t("titles.network"),
      dataIndex: "Network",
      key: "Network",
    },
    {
      title: t("titles.datetime"),
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: t("titles.txid"),
      dataIndex: "Txid",
      key: "Txid",
    },
  ];
};

const tradeColumns = (t) => {
  return [
    {
      title: t("titles.datetime"),
      dataIndex: date,
    },
    {
      title: t("titles.pair"),
      dataIndex: pair,
    },
    {
      title: t("titles.side"),
      dataIndex: side,
    },
    {
      title: t("titles.average"),
      dataIndex: avg_price,
    },
    {
      title: t("titles.price"),
      dataIndex: price,
    },
    {
      title: t("titles.amount"),
      dataIndex: amount,
    },
    {
      title: t("titles.total"),
      dataIndex: total,
    },
    {
      title: t("titles.fee"),
      dataIndex: fee,
    },
  ];
};

const buySellColumns = (current_tab, t) => {
  return [
    {
      title: <p className="coins">{t("transhistory.status")}</p>,
      dataIndex: "Status",
    },
    {
      title:
        current_tab === "buy"
          ? t("transhistory.you_rec")
          : t("transhistory.you_pay"),
      dataIndex: "crypto_amount",
    },
    {
      title:
        current_tab === "buy"
          ? t("transhistory.you_pay")
          : t("transhistory.you_rec"),
      dataIndex: "fiat_amount",
    },
    {
      title: t("titles.fee"),
      dataIndex: "Fee",
    },
    {
      title: t("titles.network"),
      dataIndex: "Network",
    },
    {
      title: t("titles.datetime"),
      dataIndex: "Date",
    },
    {
      title: t("titles.txid"),
      dataIndex: "Txid",
    },
  ];
};

const transferColumns = (t) => {
  return [
    {
      title: <p className="coins">{t("titles.datetime")}</p>,
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: t("titles.currency"),
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: t("titles.ref_type"),
      dataIndex: "ref_type",
      key: "ref_type",
    },
    // {
    //   title: t("titles.from"),
    //   dataIndex: "From",
    //   key: "From",
    // },
    // {
    //   title: t("titles.to"),
    //   dataIndex: "To",
    //   key: "To",
    // },

    {
      title: t("titles.amount"),
      dataIndex: "Amount",
      key: "Amount",
    },
  ];
};

const getFromTo = (reference_type) => {
  let from = "";
  let to = "";

  switch (reference_type) {
    case "transfer_to_gridbot":
      from = "SPOT";
      to = "GRID";
      break;

    case "transfer_from_gridbot":
      from = "GRID";
      to = "SPOT";

      break;

    case "transfer_to_copybot":
      from = "SPOT";
      to = "COPY";
      break;

    case "transfer_from_copybot":
      from = "COPY";
      to = "SPOT";
      break;

    case "spot_from_p2p":
      from = "SPOT";
      to = "P2P";
      break;

    case "spot_to_p2p":
      from = "P2P";
      to = "SPOT";
      break;

    default:
  }
  return { from, to };
};

const genrateReferenceKind = (reference_type, reference_kind) => {
  let res = "";
  switch (reference_kind) {
    case "transfer_to_gridbot":
      res = "SPOT TO GRID";
      break;

    case "transfer_from_gridbot":
      res = "GRID TO SPOT";
      break;

    case "transfer_to_copybot":
      res = "SPOT TO COPY";
      break;

    case "transfer_from_copybot":
      res = "COPY TO SPOT";
      break;

    default:
      res = reference_type;
      break;
  }
  return res;
};

const modifiedStr = (text) => {
  let modifiedStr = text.replace(/::/g, "/");
  return modifiedStr ? uppercase(modifiedStr) : "-";
};

const genrateReferenceType = (
  reference_type,
  reference_kind,
  previous_balance,
  new_balance
) => {
  let res = "";
  switch (reference_type) {
    case "transfer_to_gridbot":
      res = "SPOT TO GRID";
      break;

    case "transfer_from_gridbot":
      res = "GRID TO SPOT";
      break;

    case "transfer_to_copybot":
      res = "SPOT TO COPY";
      break;

    case "transfer_from_copybot":
      res = "COPY TO SPOT";
      break;

    case "accountadjustment":
      res = previous_balance > new_balance ? "SWAP (sell)" : "SWAP (buy)";
      break;

    case "rewards::referraltradereward":
      res = "Rewards";
      break;

    case "redeemreward":
      res = "Redeem Reward";
      break;

    case "spot_from_p2p":
      res = "SPOT TO P2P";
      break;

    case "spot_to_p2p":
      res = "P2P TO SPOT";
      break;

    default:
      if (
        reference_type?.includes("order") ||
        reference_type?.includes("trade")
      ) {
        res = modifiedStr(reference_type);
      } else if (reference_type?.includes("account")) {
        res = modifiedStr(genrateReferenceKind(reference_type, reference_kind));
      } else {
        res = modifiedStr(reference_type);
      }
      break;
  }
  return res;
};

const transferData = (datalist) => {
  let createList = datalist.map((val, idx) => {
    const {
      reference_type,
      new_balance,
      previous_balance,
      precision,
      created_at,
      currency,
    } = val;
    const { from, to } = getFromTo(reference_type);

    return {
      key: idx,
      Date: dateToUtc(created_at, "DD/MM/YYYY  HH:mm"),
      Currency: uppercase(currency),
      // From: from,
      // To: to,
      ref_type: val.reference_type
        ? genrateReferenceType(
            val.reference_type,
            val.reference_kind,
            val.previous_balance,
            val.new_balance
          )
        : " -",

      Amount: truncateData2(
        exponentialToDecimalConvert(new_balance - previous_balance),
        precision
      ),
    };
  });
  return createList;
};

const createLink = (txid, isInternal, explorer_transaction, t) => {
  return txid ? (
    <>
      {isInternal ? (
        <div>{t("transhistory.internal")}</div>
      ) : (
        <a
          target="_blank"
          className="overflowText"
          href={replaceTxid(explorer_transaction, txid)}
          title={txid}
        >
          {txid}
        </a>
      )}
    </>
  ) : (
    t("transhistory.na")
  );
};

const buySellData = (dataList, currentTab) => {
  const isBuy = currentTab === buy;
  const { t } = i18n;

  let createList = dataList.map((val, idx) => {
    const {
      blockchain_txid,
      txid,
      fiat_amount,
      fee_in_fiat,
      crypto_amount,
      explorer_transaction,
      network,
      created_at,
      status,
      crypto_currency,
      currency,
      fiat_currency,
    } = val;

    const state = `${t(`statusState.${status}`)}`;
    const isInternal = val?.transaction_type === "internal";

    return {
      key: idx,
      Status: <h6 className={`${lowercase(state)}`}>{state}</h6>,
      Date: dateToUtc(created_at, "DD/MM/YYYY  HH:mm"),
      Fee: `${fee_in_fiat} ${fiat_currency}`,
      fiat_amount: `${fiat_amount} ${fiat_currency}`,
      crypto_amount: `${crypto_amount} ${crypto_currency}`,

      Currency: uppercase(currency),
      Network: uppercase(network),
      Txid: createLink(
        isBuy ? blockchain_txid : txid,
        isInternal,
        explorer_transaction,
        t
      ),
    };
  });

  return createList;
};

const sendReceiveData = (datalist, currentTab) => {
  const { t } = i18n;
  const isSend = currentTab === send;
  let createList = datalist.map((val, idx) => {
    const {
      blockchain_txid,
      txid,
      explorer_transaction,
      blockchain_key,
      amount,
      created_at,
      state,
      currency,
      internal_type,
      internal_member_uid
    } = val;

    const status = `${t(`statusState.${state}`)}`;
    const isInternal = val?.transaction_type === "internal";

    return {
      key: idx,
      Status: <h6 className={`${lowercase(state)}`}>{status}</h6>,
      Date: dateToUtc(created_at, "DD/MM/YYYY  HH:mm"),
      Amount: amount,
      Currency: uppercase(currency),
      Network: blockchain_key?.toUpperCase(),
      Txid: createLink(
        isSend ? blockchain_txid : txid,
        isInternal,
        explorer_transaction,
        t
      ),
      internal_type: internal_type
        ? uppercase(internal_type)
        : uppercase(t("portfolio.wallet")),
        internal_member_uid : internal_member_uid ? internal_member_uid : "-"
    };
  });
  return createList;
};

const tradesData = (tradeList) => {
  const { t } = i18n;
  let updatedList =
    tradeList.length > 0 &&
    tradeList.map((item, idx) => {
      const price_value =
        item?.ord_type === "market" ? t("transhistory.market") : item?.price;
      return {
        [date]: <p>{dateToUtc(item?.created_at, "DD/MM/YYYY  HH:mm")}</p>,
        [pair]: <p>{uppercase(item?.market)}</p>,
        [side]: <p>{uppercase(item?.side)}</p>,
        [price]: <p>{price_value}</p>,
        [avg_price]: <p>{item?.avg_price}</p>,
        [amount]: <p>{item?.amount}</p>,
        [total]: <p>{item?.total}</p>,
        [fee]: <p>{item?.fee_amount}</p>,
      };
    });
  return updatedList;
};

const CommonTable = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentMarketId } = useSelector((state) => state.commonApiData);
  const { marketPairList } = useSelector((state) => state.transactionHistories);
  const { filter } = useSelector((state) => state.exchange);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterObj, setFilterObj] = useState({});
  const { list, column, total, currentTab } = props;

  const hitApi = (tabName, data) => {
    setPage(data.page);
    setLimit(data.limit);
    let sendData = {
      page: page,
      limit: limit,
      ...filterObj,
      ...data,
    };
    switch (tabName) {
      case buy:
        sendData["transak_type"] = "BUY";
        dispatch(fetchBuySellHistory({ ...sendData }));
        break;
      case sell:
        sendData["transak_type"] = "SELL";
        dispatch(fetchBuySellHistory({ ...sendData }));
        break;
      case send:
        sendData["transaction_type"] = props?.withdrawType;
        dispatch(getWithdrawHistory({ ...sendData }));
        break;
      case receive:
        sendData["transaction_type"] =
          props?.depositType == "internal" ? 300 : 400;
        dispatch(getDepositHistory({ ...sendData }));
        break;
      case trades:
        if (!marketPairList.length) return;
        const pairList = createPairList(marketPairList, t);
        let toSendMarket = currentMarketId
          ? currentMarketId
          : pairList[0].value;
        dispatch(
          getTransHisTrades({
            ...sendData,
            market:
              Object.keys(filter).length > 0 ? filter.market : toSendMarket,
          })
        );
        break;
      case convert:
        break;
      case transfer:
        dispatch(
          getBalanceHistory({
            ...sendData,
          })
        );
        break;
      default:
    }
  };

  const callBackFun = (obj) => {
    const { page, limit, ...rest } = obj;
    setPage(page);
    setLimit(limit);
    setFilterObj({ ...rest });
  };

  return (
    <>
      {props.isFilterVisible && (
        <Filter
          currentTab={currentTab}
          cbFun={callBackFun}
          depositType={props?.depositType}
          withdrawType={props?.withdrawType}
        />
      )}
      {props.isExportVisible && (
        <ExportData
          currentTab={currentTab}
          depositType={props?.depositType}
          withdrawType={props?.withdrawType}
        />
      )}
      <Table
        dataSource={list}
        columns={column}
        pagination={false}
        className="portfolioTable"
        rowKey={(obj) => obj.keyId}
      />
      <CustomPagination
        pageNo={page}
        limit={limit}
        total={total}
        onChange={(pageNo, pageSize) => {
          hitApi(currentTab, { page: pageNo, limit: pageSize });
        }}
      />
    </>
  );
};

export const Buy = (props) => {
  const { t } = i18n;
  const { list, total } = useSelector((state) => state.buySell);

  return (
    <CommonTable
      list={buySellData(list, buy)}
      column={buySellColumns(buy, t)}
      total={total}
      currentTab={buy}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};

export const Sell = (props) => {
  const { list, total } = useSelector((state) => state.buySell);
  const { t } = i18n;
  return (
    <CommonTable
      list={buySellData(list, sell)}
      column={buySellColumns(sell, t)}
      total={total}
      currentTab={sell}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};

export const Send = (props) => {
  const { t } = i18n;
  const { withdrawHistory, withdrawHistoryTotal } = useSelector(
    (state) => state.transactionHistories
  );
  const columns = sendReceiveColumns(t);

  if (props?.withdrawType === "internal") {
    columns.push({
      title: t("titles.internal_type"),
      dataIndex: "internal_type",
    });

    columns.push({
      title: t("labels.uid"),
      dataIndex: "internal_member_uid",
    });
  }
  return (
    <CommonTable
      list={sendReceiveData(withdrawHistory, send)}
      column={columns}
      total={withdrawHistoryTotal}
      currentTab={send}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
      withdrawType={props?.withdrawType}
    />
  );
};

export const Receive = (props) => {
  const { t } = i18n;
  const { depositHistory, depositHistoryTotal } = useSelector(
    (state) => state.transactionHistories
  );

  const columns = sendReceiveColumns(t);

  if (props?.depositType === "internal") {
    columns.push({
      title: t("titles.internal_type"),
      dataIndex: "internal_type",
    });

    columns.push({
      title: t("labels.uid"),
      dataIndex: "internal_member_uid",
    });
  }

  return (
    <CommonTable
      list={sendReceiveData(depositHistory, receive)}
      column={columns}
      total={depositHistoryTotal}
      currentTab={receive}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
      depositType={props?.depositType}
    />
  );
};

export const Trades = (props) => {
  const { t } = i18n;
  const { tradeList, tradesCount } = useSelector(
    (state) => state?.transactionHistories
  );
  return (
    <CommonTable
      list={tradesData(tradeList)}
      column={tradeColumns(t)}
      total={tradesCount}
      currentTab={trades}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};

export const TransferHistory = (props) => {
  const { t } = i18n;
  const { balanceHistoryTotal, balanceHistory } = useSelector(
    (state) => state.commonApiData
  );

  return (
    <CommonTable
      list={transferData(balanceHistory)}
      column={transferColumns(t)}
      total={balanceHistoryTotal}
      currentTab={transfer}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};
