import React from "react";
import { Tabs } from "antd";

const CustomTabs = (props) => {

  const { items, size, type, whiteTab, border, main, activekey, hanldeTabChange, ...rest } = props;
  let bottoBorderTab = whiteTab ? "whiteBorder" : "greenBorder";
  let btnTab = main ? "btnMain" : "";
  return (
    <div className="Custom-tabs " >
      <Tabs
        className={`${bottoBorderTab} ${btnTab} `}
        type={type}
        size={size}
        defaultActiveKey={activekey}
        activeKey={activekey}
        items={items}
        onChange={hanldeTabChange}
        {...rest}
      />
    </div>
  );
};

export default CustomTabs;
