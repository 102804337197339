import { createSlice } from "@reduxjs/toolkit";
import { getAllMethods, getPaymentMethods } from "../../../services/P2P/paymentMethodService";

export const paymentMethodSlice = createSlice({
    name: "payment",
    initialState: {
        paymentMethods: [],
        error: false,
        status: false,
        allMethods: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentMethods.pending, (state) => {
                state.error = false;
            })
            .addCase(getPaymentMethods.fulfilled, (state, action) => {
                state.paymentMethods = action.payload;
            })
            .addCase(getPaymentMethods.rejected, (state) => {
                state.error = true;
            })

            .addCase(getAllMethods.pending, (state) => {
                state.error = false;
            })
            .addCase(getAllMethods.fulfilled, (state, action) => {
                state.allMethods = action.payload;
            })
            .addCase(getAllMethods.rejected, (state) => {
                state.error = true;
            })

    },
});
export default paymentMethodSlice.reducer;
