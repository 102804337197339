import React from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <p className="footer__text">
        {t("footer.future")}© {new Date().getFullYear()} {t("footer.rights")}
      </p>
    </div>
  );
}

export default Footer;
