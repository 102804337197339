import { p2pRootName } from "../utils/constant";

export const publicRouteObj = {
  login: "login",
  changePassword: "changePassword",
  resetPassword: "resetPassword",
  createAccount: "createAccount",
  listingApplication: "listingApplication",
  emailVerify: "emailVerify",
  emailConfirmation: "emailConfirmation",
  identity: "identity",
  verifyIdentity: "verifyIdentity",
  reactiveUser: "reactiveUser",
  contactPublic: "contact-us",
  privacy: "privacy",
  launchpadViewAll: "launchpadViewAll",
  listing: "listing"
};

// Sidebar obj
export const sidebarRouteObj = {
  swap: "swap",
  home: "home",
  port: "portfolio",
  advTrade: "advanceTrading",
  gridtrade: "gridDetails",
  copytrade: "copytrade",
  p2p: "p2p",
  referral: "referral",
  transactions: "transactions",
  listing: "listing"
};

const {
  home,
  port,
  advTrade,
  gridtrade,
  copytrade,
  p2p,
  transactions,
  referral,
  swap,
  listing
} = sidebarRouteObj;

export const defaultActiveSidebarObj = {
  [home]: ["1", "home", "homeIcon"],
  [port]: ["2", "port", "portIcon"],
  [swap]: ["3", "swap", "swapIcon"],
  [advTrade]: ["4", "advTrade", "advTradeIcon"],
  [gridtrade]: ["5", "gridtrading"],
  [copytrade]: ["6", "copytrading"],
  [p2p]: ["7", "p2p"],
  [listing]: ["8", "listing", ""],
  [referral]: ["9", "referral", "referralIcon"],
};

export const routeExists = [
  home,
  port,
  advTrade,
  gridtrade,
  copytrade,
  p2p,
  referral,
  swap
];

export const primaryRoutes = {
  verification: "verification",
  verificationCode: "verificationCode",
  overview: "overview",
  setting: "setting",
  accountActivity: "account-activity",
  order: "order",
  transactions: "transactions",
  landing: "landing",
  notfication: "notfication",
  addressManagement: "address-management",
  introWithoutId: "intro",
  authLanding: "authLanding",
  kyc: "kyc",
  deviceManagement: "device-management",
  gridDetails: "gridDetails",
  referralViewAll: "referral_view_all",
  sumSub: "sumsub-kyc",
  myCopyTrade: "mycopytrade",
  traderDetail: "traderDetail",
  tradingParameter: "tradingParameter",
  copybotdetail: "copybotdetail",
  announcement: "announcement",
  notfication: "notification",
  contactPrivate: "contact_us",
  priceAlert: "priceAlert",
  launchpadViewAll: "launchpadViewAll",
  launchpadDetail: "launchpadDetail",
  launchpadHistory: "launchpadHistory",

  p2p: "p2p",
  orders: `${p2pRootName}/orders`,
  orderId: `${p2pRootName}/orders/:Oid`,
  buysellorderinfo: `${p2pRootName}/buysellorderinfo/:id`,
  progress: `${p2pRootName}/progress`,
  editAd: `${p2pRootName}/editAd/:id`,
  paymentMethod: `${p2pRootName}/payment-method`,
  profilecomp: `${p2pRootName}/profilecomp/:id`,
  remark: `${p2pRootName}/remark`,
  paymentstep: `${p2pRootName}/paymentStep/:id`,
  appeal: `${p2pRootName}/appeal/:id`,
  appealhistory: `${p2pRootName}/appealhistory/:id`

};

const {
  setting,
  order,
  addressManagement,
  viewAll,
  viewHistory,
  mycopytrade,
  announcement,
  notfication
} = primaryRoutes;

export const headerHeadingObj = {
  [home]: "Home",
  [port]: "Portfolio",
  [swap]: "Swap",
  [advTrade]: "Trading",
  [gridtrade]: "Grid Trading",
  [copytrade]: "Copy Trade",
  [p2p]: "Peer To Peer",
  [transactions]: "Transactions History",
  [setting]: "Settings",
  [order]: "Orders",
  [addressManagement]: "Address Management",
  [viewAll]: "My Earnings",
  [viewHistory]: "History",
  [referral]: "Referral",
  [announcement]: "Announcements",
  [notfication]: "Notifications",
};

export const rangerRoutes = [advTrade, port, gridtrade, copytrade, gridtrade, mycopytrade];
