import { createSlice } from "@reduxjs/toolkit";
import { getFeedback, getUserFeedback } from "../../../services/P2P/feedbackService";

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    list: [],
    percentage: "",
    total: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFeedback.pending, (state) => {
        state.error = false;
      })
      .addCase(getFeedback.fulfilled, (state, action) => {
        state.list = action.payload.list;
      })
      .addCase(getFeedback.rejected, (state) => {
        state.error = true;
      })

      .addCase(getUserFeedback.pending, (state) => {
        state.error = false;
      })
      .addCase(getUserFeedback.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.total = action.payload.total;

      })
      .addCase(getUserFeedback.rejected, (state) => {
        state.error = true;
      });
  },
});

export default feedbackSlice.reducer;