import React from "react";
import { Card, Row, Col, Tag } from "antd";
import style from "./style.module.scss";
import { FinishIcon } from "../../Assets/Svg/Svg";
import Countdown from "./CountDown.jsx";
import { truncateFun } from "../../../helpers/getToFixed.js";
import { uppercase } from "../../../helpers/uppercase.js";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../../helpers/capitalize.js";

const LaunchpadCard = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Card className="LaunchpadCard_custom" onClick={props.onClick}>
        <div className="LaunchpadCard 22">
          <div className="block imageblock">
            <div
              className={`iconOuter ${style.cardBlock_img}`}
              style={{
                border: "1px solid #00a3d0",
              }}
            >
              <div className={style.cardBlock_img_icon}>
                {props?.iconUrl ? (
                  <img src={props?.iconUrl} alt="icon" className={style.launchpadCardIcon} />
                ) : (
                  <FinishIcon />
                )}
              </div>
              <Tag
                style={{ border: "unset" }}
                className={`customTag ${style.cardBlock_img_tag}`}
                color={props.tagtext === "verified" ? "success" :
                  props.tagtext === "online" ? "success"
                    : props.color}
              >
                {capitalize(t(props.tagtext).replace("_", " "))}
              </Tag>
            </div>
          </div>
          <div className="block">
            <p className="title" style={{ paddingBottom: "29px" }}>
              {props?.project_name}{" "}
            </p>
            <Row gutter={[29, 15]}>
              <Col xs={24} sm={12}>
                <p className={style.cardBlock_Content_contentText}>
                  <span>{t("launchpad.tokens_offered")}</span>
                  <br />
                  {props?.hard_cap ? props?.hard_cap : "0.0"}{" "}
                  {uppercase(props?.token_name)}
                </p>
              </Col>
              <Col xs={24} sm={12}>
                <p className={style.cardBlock_Content_contentText}>
                  <span>{t("launchpad.sale_price")}</span>
                  <br />1 {uppercase(props?.token_name)} ={" "}
                  {props?.public_sale_token_price
                    ? truncateFun(props?.public_sale_token_price, 4)
                    : 0.0}{" "}
                  USDT
                </p>
              </Col>
              <Col xs={24} sm={12}>
                <p className={style.cardBlock_Content_contentText}>
                  <span>{t("launchpad.participants")}</span>
                  <br />
                  {props?.participants
                    ? props?.participants
                    : props?.participants === 0
                      ? 0
                      : "0.00"}
                </p>
              </Col>
              <Col xs={24} sm={12}>
                <p className={style.cardBlock_Content_contentText}>
                  <span>{t("launchpad.total_commited")}</span>
                  <br />
                  {!isNaN(props?.total_comt)
                    ? truncateFun(props?.total_comt, 2)
                    : "0.0"}{" "}
                  USDT {/* {uppercase(props?.token_name)} */}
                </p>
              </Col>
            </Row>
            {props.DayCount && (
              <div className={`Durationblock ${style.cardBlock_Content_flex} `}>
                <p style={{ paddingTop: "29px" }}>
                  {props.subscription}
                  {props.state === "online"
                    ? t("launchpad.time_left")
                    : props.state === "calculation"
                      ? t("launchpad.time_left_until")
                      : ""}
                </p>
                {props?.state === "online" ||
                  props?.state === "calculation" ||
                  props?.state === "calculation_completed" ? (
                  <Countdown
                    targetDate={props?.stage_end_date}
                    afterTimeCompletionHandler={
                      props?.afterTimeCompletionHandler
                    }
                  />
                ) : props?.state === "released" ? (
                  t("launchpad.finished")
                ) : props?.state === "verified" ? (
                  t("launchpad.upcoming")
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </Card >
    </>
  );
};

export default LaunchpadCard;
