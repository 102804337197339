import { createSlice } from "@reduxjs/toolkit";
import { genrateWalletAddress, getDefiWalletData } from "../../services";

export const walletConnectSlice = createSlice({
    name: "walletConnect",
    initialState: {
        loading: false,
        address: "",
        cryptoAddress: null,
        walletData: [],
        walletDataTotal: 0
    },
    reducers: {
        setCryptoAddress: (state, action) => {
            state.cryptoAddress = action.payload;
        },
        clearCryptoAddress: (state, action) => {
            state.cryptoAddress = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(genrateWalletAddress.pending, (state) => {
                state.address = "";
            })
            .addCase(genrateWalletAddress.fulfilled, (state, action) => {
                state.address = action.payload.data;
            })
            .addCase(genrateWalletAddress.rejected, (state) => {
                state.address = "";
            })

            .addCase(getDefiWalletData.pending, (state) => {
                state.walletData = [];
                state.loading = true
            })
            .addCase(getDefiWalletData.fulfilled, (state, action) => {
                state.walletData = action.payload.list;
                state.walletDataTotal = action.payload.total;
                state.loading = false
            })
            .addCase(getDefiWalletData.rejected, (state) => {
                state.walletData = [];
                state.loading = false
            })


    },
});
export default walletConnectSlice.reducer;
export const { setCryptoAddress, clearCryptoAddress } = walletConnectSlice.actions;
