import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../../Assets/Svg/Svg.jsx";
import Footer from "../../Layout/Footer/Footer.jsx";
import {
  getLaunchPadBidHistory,
} from "../../../redux/services/launchpadService.js";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Landing/Header.jsx";
import moment from "moment";
import styles from "./style.module.scss";
import { capitalize } from "../../../helpers/capitalize.js";
import { useTranslation } from "react-i18next";

function LaunchpadTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const { launchPadBidHistory, launchPadBidHistoryTotal } = useSelector(
    (state) => state?.launchpad
  );

  const getDetails = async (id) => {
    await dispatch(getLaunchPadBidHistory({ listing_id: id }));

  };

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, [id]);

  useEffect(() => {
    const alphadataSource =
      launchPadBidHistory?.length > 0 ?
        launchPadBidHistory?.map((data, i) => {
          return {
            id: data?.id ? data?.id : "-",
            price: data?.price ? `${data?.price} USDT` : "0.0 USDT",
            amount: data?.amount ? `${data?.amount} ${data?.token_name}` : "0.0",
            total: data?.total ? `${data?.total} USDT` : "0.0 USDT",
            state: data?.state ? capitalize(data?.state) : "-",
            date: data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : "-",
          };
        }) : [];
    setDataSource(alphadataSource);
  }, [launchPadBidHistory]);

  const columns = [
    {
      title: t("launchpad.id"),
      dataIndex: "id",
    },
    {
      title: t("launchpad.price"),
      dataIndex: "price",
    },
    {
      title: t("launchpad.amount"),
      dataIndex: "amount",
      key: "tokenName",
    },
    {
      title: t("launchpad.total"),
      dataIndex: "total",
    },
    {
      title: t("launchpad.date"),
      dataIndex: "date",
    },
    {
      title: t("launchpad.state"),
      dataIndex: "state",
    },
  ];
  return (
    <div className="listingSec">
      <Header className="listingHeader" />
      <div className="launchpadHis">
        <div className="container ">
          <Button
            className={`backbtn  modalBackBtn `}
            style={{ marginBottom: "28px" }}
            type="text"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackIcon style={{ marginRight: "5px" }} />{t("launchpad.back_to_prev")}
          </Button>
          {dataSource?.length ? <div className="Custom-table">
            <Table
              className="portfolioTable"
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div> : <h1 className={styles.noRecordFound}>{t("launchpad.no_record")}</h1>}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LaunchpadTable;
