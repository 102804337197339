import { createSlice } from "@reduxjs/toolkit";
import {
  getUserProfile,
  getBidUserProfile,
  getBlockedUsers,
} from "../../../services/P2P/userprofileService";
export const userprofileSlice = createSlice({
  name: "userProfile",
  initialState: {
    userprofile: [],
    userBls: [],
    userbidprofile: {},
    error: false,
    blockedList: [],
    blockedListTotal: 0,
    loading: false,
    reportStatus: false,
    reportError: false,
  },
  reducers: {
    reportedUserSuccessfully: (state, action) => {
      state.reportStatus = action.payload.reportStatus;
      state.reportError = action.payload.reportError;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userprofile = action.payload;
        state.loading = false;
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })

      .addCase(getBidUserProfile.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getBidUserProfile.fulfilled, (state, action) => {
        state.userbidprofile = action.payload;
        state.loading = false;
      })
      .addCase(getBidUserProfile.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })

      .addCase(getBlockedUsers.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getBlockedUsers.fulfilled, (state, action) => {
        state.blockedList = action.payload.list;
        state.blockedListTotal = action.payload.total || 0;
        state.loading = false;
      })
      .addCase(getBlockedUsers.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  },
});
export default userprofileSlice.reducer;
export const { reportedUserSuccessfully } = userprofileSlice.actions;
