import { createSlice } from "@reduxjs/toolkit";
import { fetchSumsubToken } from "../../services/SumSub";

export const SumsubSlice = createSlice({
    name: "sumsub",
    initialState: {
        loading: false,
        docs: [],
        access_token: {}
    },
    reducers: {

        clearSumsubToken: (state, action) => {
            state.access_token = {}
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSumsubToken.pending, (state, { meta }) => {
                state.loading = true;
            })
            .addCase(fetchSumsubToken.fulfilled, (state, action) => {
                state.loading = false;
                state.access_token = action.payload;
            })
            .addCase(fetchSumsubToken.rejected, (state) => {
                state.loading = false;
                state.error = true
            })

    },
});

export default SumsubSlice.reducer;
export const { clearSumsubToken } = SumsubSlice.actions;

