import { combineReducers } from "@reduxjs/toolkit";
import alertReducer from "./feature/alert/alert.slice";
import loaderReducer from "./feature/loader/loader.slice";
import loginReducer from "./feature/login/login.slice";
import userReducer from "./feature/user/user.slices";
import commonReducer from "./feature/common/common.Slice";
import exchangesReducer from "./feature/exchange/exchanges.slice";
import sendReceiveReducer from "./feature/sendReceive/sendReceive.slice";
import transHistoriesReducer from "./feature/transHistories/transHistories.Slice";
import settingReducer from "./feature/setting/setting.Slice";
import kycReducer from "./feature/kyc/kyc.slice"
import securityReducer from "./feature/securityManagement/security.Slices"
import themeReducer from "./feature/theme/theme.slice"
import buySellReducer from "./feature/buySell/buySell.slice"
import transakWidgetReducer from "./feature/transakWidgets/transakWidget.slice"
import referralReducer from "./feature/referral/referral.Slices";
import notificationReducer from "./feature/notification/notification.slice";
import sumsubReducer from "./feature/sumsub/SumsubSlice"
import cryptoAddressReducer from "./feature/crypto-address/crypto-address.slice"
import walletConnectReducer from "./feature/metaMask/walletConnect.slice"
import copyTradeReducer from "./feature/copyTrade/copyTrade.slice"
import priceAlertReducer from "./feature/priceAlert/priceAlert.slice";
import launchpadReducer from "./feature/launchpad/launchpad.slice";

import orderReducer from "./feature/P2P/order/order.slice";
import nicknameReducer from "./feature/P2P/nickname/nickname.slice";
import advertisementReducer from "./feature/P2P/advertisement/advertisement.slice";
import selfSelectedDataReducer from "./feature/P2P/selectedData/selectedData.slice";
import conversionReducer from "./feature/P2P/conversion/conversion.slices";
import appealReducer from "./feature/P2P/appeal/appeal.slice";
import chatReducer from "./feature/P2P/chat/chat.slices";
import feedbackSlice from "./feature/P2P/feedback/feedback.slice"
import userprofileSlice from "./feature/P2P/userprofile/userprofile.slice";
import paymentMethodSlice from "./feature/P2P/paymentMethods/paymentMethod.slice";

export const rootReducer = combineReducers({
  alert: alertReducer,
  loader: loaderReducer,
  login: loginReducer,
  user: userReducer,
  commonApiData: commonReducer,
  sendReceive: sendReceiveReducer,
  exchange: exchangesReducer,
  transactionHistories: transHistoriesReducer,
  setting: settingReducer,
  kyc: kycReducer,
  security: securityReducer,
  theme: themeReducer,
  buySell: buySellReducer,
  transakWidget: transakWidgetReducer,
  referral: referralReducer,
  notification: notificationReducer,
  cryptoAddress: cryptoAddressReducer,
  wallet: walletConnectReducer,
  documents: sumsubReducer,
  copyTrade: copyTradeReducer,
  priceAlert: priceAlertReducer,
  launchpad: launchpadReducer,

  order: orderReducer,
  nickname: nicknameReducer,
  advertisment: advertisementReducer,
  selectedData: selfSelectedDataReducer,
  conversion: conversionReducer,
  appeal: appealReducer,
  chat: chatReducer,
  userprofile: userprofileSlice,
  feedback: feedbackSlice,
  beneficiaries: paymentMethodSlice,
});
