import { upDownPriceColor } from "../staticObjects";

export const checkIsPositive = (num) => {
  const theme = localStorage.getItem("theme")
  const { up, down } = upDownPriceColor;
  switch (Math.sign(num)) {
    case 1:
      return up;
    case -1:
      return down;
    case 0:
      return theme === "theme-light" ? "#392765" : "#fff";
    default:
      return "";
  }
};
