import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { getBalance, getTotalUsdtBal } from "./commonServices";

export const genrateAddress = createAsyncThunk(
  "sendReceive/genrateAddress",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/create_address/${payload.id}`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { data: res.address, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));

      return false;
    }
  }
);

export const getAddress = createAsyncThunk(
  "sendReceive/getAddress",
  async (payload, { dispatch }) => {
    try {
      let url = `account/deposit_address/${payload.id}?blockchain_key=${payload.blockchain_key}`;
      const res = await API.get(config.peatio)(url);
      return { data: res.address, status: true };
    } catch (e) {
      return false;
    }
  }
);

export const fetchBeneficiary = createAsyncThunk(
  "sendReceive/fetchBeneficiary",
  async (payload, { dispatch }) => {
    try {
      let url = `account/beneficiaries?${buildQueryString(payload)}`;
      const res = await API.get(config.peatio)(url);
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const fetchAllBeneficiary = createAsyncThunk(
  "sendReceive/fetchAllBeneficiary",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/beneficiaries?${buildQueryString(payload)}`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return {
        data: res.data,
        total: res.headers.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const addBeneficiary = createAsyncThunk(
  "sendReceive/addBeneficiary",
  async (payload, { dispatch }) => {
    const { t } = payload
    try {
      dispatch(startLoading());
      let url = `account/beneficiaries`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(
        successToastSelector({
          message: t("services.beneficiary_added"),
        })
      );
      dispatch(fetchAllBeneficiary({}));
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const removeBeneficiary = createAsyncThunk(
  "sendReceive/removeBeneficiary",
  async (payload, { dispatch }) => {
    const { t } = payload
    try {
      dispatch(startLoading());
      let url = `account/beneficiaries/${payload.id}`;
      await API.delete(config.peatio)(url, payload);
      dispatch(
        successToastSelector({
          message: t("services.beneficiary_del"),
        })
      );
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      dispatch(stopLoading());
      return false;
    }
  }
);

export const verifyWithdraw = createAsyncThunk(
  "sendReceive/verifyWithdraw",
  async (payload, { dispatch }) => {
    const { t } = payload
    try {
      dispatch(startLoading());
      let url = `account/withdraw/verify`;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { status: true, res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const createWithdraw = createAsyncThunk(
  "sendReceive/createWithdraw",
  async (payload, { dispatch }) => {
    const { t } = payload
    try {
      dispatch(startLoading());
      let url = `account/withdraws`;
      await API.post(config.peatio)(url, payload);
      dispatch(successToastSelector({ message: t("services.send_succ") }));
      dispatch(getTotalUsdtBal())
      dispatch(getBalance());
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const transferAmount = createAsyncThunk(
  "sendReceive/transferAmount",
  async (payload, { dispatch }) => {
    const { t } = payload
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `account/interchange/holdings`;
      await API.put(config.p2p)(url, payload);
      dispatch(successToastSelector({ message: t("services.transfer_succ") }));
      dispatch(getBalance());
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
