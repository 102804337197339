import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import IdleTimer from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Modal, message } from "antd";
import { rootName } from "./utils/constant";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import GoAlert from "./config/goAlert";
import { commonRoutesData, privateRoutesData, publicRoutesData } from "./routes/routingData";
import {
  Mainlayout,
  PageNotFound,
  Verifiaction,
  VerificationCode,
  Landing,
  Exchange,
  ListingApplication,
} from "./routes/lazyRoute";
import { checkIsProTrader, getUser } from "./redux/services";
import { isAuthenticated } from "./redux/selector";
import { fetchLogout } from "./redux/services/logout";
import { primaryRoutes, publicRouteObj } from "./staticObjects";
import Ranger from "./config/ranger";
import { useLocation } from "react-router-dom";
import { Loader } from "./Components/loader";
import { ButtonCustom } from "./Components/Ui/button/ButtonCustom";
import { getUrlPart } from "./helpers";
import LaunchpadDetail from "./Components/Pages/LaunchpadViewAll/LaunchpadDetail";
import LaunchpadTable from "./Components/Pages/LaunchpadViewAll/LaunchpadTable";
import { setEnableUser } from "./redux/feature";
import EnableUser from "./Components/Common/EnableUser";
import CommonModal from "./Components/CommonModal";
import { getNotifications, getNotificationsCount } from "./redux/services/notificationService";
import { getUserProfile } from "./redux/services/P2P";

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAuthenticate = useSelector((state) => isAuthenticated(state));
  const { data, userIsLoading } = useSelector((state) => state.user);
  const { enableUser } = useSelector((state) => state.login);
  const isloading = useSelector((state) => state.loader.loading);
  const location = useLocation();
  const [sessionModal, setSessionModal] = useState(false); // Set initial state to true
  const path = getUrlPart(1, location.pathname);
  const { err } = useSelector((state) => state.alert);

  useEffect(() => {
    dispatch(getUser({ isNull: true }));
  }, []);

  useEffect(() => {
    if (data?.level === 3) {
      dispatch(checkIsProTrader());
      dispatch(getNotifications({}));
    }
    // dispatch(getNotificationsCount());

    if (isAuthenticate) {
      dispatch(getNotificationsCount());
      dispatch(getUserProfile())

    }

  }, [data]);

  useEffect(() => {
    localStorage.setItem("theme", "theme-dark");
    document.documentElement.className = `theme-dark ${path}`;
    document.body.className = `theme-dark ${path}`;
  }, [data, path]);

  message.config({
    maxCount: 1,
    duration: 2,
  });

  useEffect(() => {
    if (err) {
      let showMessHide = true
      hideSessionModal(showMessHide)
    }
  }, [err])

  const handleOnIdle = async () => {
    setSessionModal(true);
  };

  const hideSessionModal = async (showMessHide) => {
    let res = await dispatch(
      fetchLogout({
        uid: data?.uid,
        t,
        showMessHide: showMessHide ? showMessHide : ""
      })
    );
    if (res.payload) {
      // navigate(`${rootName}${publicRouteObj.login}`);
      window.location.href = `${rootName}${publicRouteObj.login}`;
      setSessionModal(false);
    }
  };

  const { verificationCode, verification } = primaryRoutes;
  let route = [`/${verificationCode}`, `/${verification}`].includes(pathname);
  if (data?.level === 1 && !route) {
    navigate(`${rootName}${verification}`);
    return;
  }

  if (userIsLoading === null) {
    if (path !== "sumsub-kyc") {
      return <Loader loading={true} />;
    }
  }

  return (
    <>
      <Loader loading={isloading} />
      <Suspense fallback={<Loader loading={true} />}>
        <Routes>
          <Route element={<PublicRoute isAuthenticated={isAuthenticate} />}>
            {publicRoutesData?.map((item, idx) => {
              return (
                <Route
                  key={idx}
                  index
                  path={`${rootName}${item.path}`}
                  element={item.component}
                />
              );
            })}
            {!isAuthenticate && (
              <Route path={`${rootName}`} index element={<Landing />} />
            )}
          </Route>

          <Route element={<PrivateRoute isAuthenticated={isAuthenticate} />}>
            <Route path={`${rootName}`} element={<Mainlayout />}>
              {privateRoutesData?.map((item, idx) => {
                return (
                  <Route
                    index
                    key={idx}
                    path={`${rootName}${item?.path}`}
                    element={item?.component}
                  />
                );
              })}
              <Route path="/" element={<Navigate to={`${rootName}`} />} />

            </Route>
            <Route path={`launchpadDetail/:id`} element={<LaunchpadDetail />} />
            <Route path={`launchpadHistory/:id`} element={<LaunchpadTable />} />

            {/* For verify account */}

            <Route path={""} element={<Verifiaction />} />
            <Route
              path={primaryRoutes.verification}
              element={<Verifiaction />}
            />
            <Route
              path={primaryRoutes.verificationCode}
              element={<VerificationCode />}
            />
          </Route>

          <Route
            path={"listingApplication"}
            element={<ListingApplication />}
          />

          <Route path={`${rootName}`} element={<Mainlayout />}>

            <Route
              path={`${rootName}advanceTrading`}
              element={<Exchange />}
            />
            <Route
              path={`${rootName}advanceTrading/:marketId`}
              element={<Exchange />}
            />

          </Route>
          <Route path="*" element={<PageNotFound />} />

          {commonRoutesData?.map((item, idx) => {
            return (
              <Route
                key={idx}
                path={`${rootName}${item.path}`}
                element={item.component}
              />
            );
          })}

        </Routes>
      </Suspense>

      {/* For Ranger */}
      <Ranger />

      {/* For Alert */}
      <GoAlert uid={data?.uid} />

      {isAuthenticate && (
        <IdleTimer
          ref={() => { }}
          timeout={60 * 60 * 1000} // 1 hour
          onActive={() => { }}
          onIdle={handleOnIdle}
          onAction={() => { }}
          debounce={250}
        />
      )}

      {sessionModal && (
        <Modal
          className="modalapp"
          open={sessionModal}
          onCancel={hideSessionModal}
          maskClosable={false}
        >
          <p>{t("session_expired")}</p>
          <div className="modalbtnSet">
            <ButtonCustom label={t("buttons.ok")} onClick={hideSessionModal} />
          </div>
        </Modal>
      )}

      {enableUser && (
        <CommonModal
          isOpen={enableUser}
          onCancel={() => dispatch(setEnableUser(false))}
        >
          <EnableUser onClose={() => dispatch(setEnableUser(false))} />
        </CommonModal>
      )}

    </>
  );
}
export default withTranslation()(App);
