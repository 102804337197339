import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../../utils/apiVersion";
import { toastSelector } from "../../selector/toastSelector";
import { API } from "../../../api";
import { buildQueryString } from "../../../helpers";
import { startLoading, stopLoading } from "../../feature";

export const createAd = createAsyncThunk(
  "advertisement/createAd",
  async (payload, { dispatch }) => {
    const { t } = payload;
    delete payload.t
    try {
      dispatch(startLoading());
      let url = `/market/orders`;
      let res = await API.post(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(
        toastSelector({ message: t("services.advertisement.created") }, "success")
      );
      return { status: true, adId: res.id };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const updateAd = createAsyncThunk(
  "advertisement/updateAd",
  async (payload, { dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      let url = `/market/orders`;
      await API.put(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(toastSelector({ message: `${t("services.advertisement.updated")} ${t("services.advertisement.success")}` }, "success"));
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const onlineOffline = createAsyncThunk(
  "advertisement/onlineOffline",
  async (payload, { dispatch }) => {
    const { t } = payload;
    const delAd = payload.action === "cancel";
    delete payload.t;
    try {
      dispatch(startLoading());
      if (delAd) {
        await API.put(config.p2p)(
          `/market/orders/actions`,
          payload
        );
      } else {
        await API.put(config.p2p)(
          "/market/orders/actions",
          payload
        );
      }
      dispatch(stopLoading());
      if (delAd) {
        dispatch(toastSelector({ message: `${t("services.advertisement.deleted")} ${t("services.advertisement.success")}` }, "success"));
      } else {
        dispatch(toastSelector({ message: `${t("services.advertisement.updated")} ${t("services.advertisement.success")}` }, "success"));
      }
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const getBeneficiaryList = createAsyncThunk(
  "advertisement/beneficiaryList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/beneficiaries?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.p2pWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, total: headers?.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleBeneficiary = createAsyncThunk(
  "advertisement/singleBeneficiary",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/beneficiaries/${payload.id}`;
      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createBeneficiary = createAsyncThunk(
  "advertisement/createBeneficiary",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/beneficiaries`;
      const res = await API.post(config.p2p)(url, payload);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const verifyBeneficiary = createAsyncThunk(
  "advertisement/verifyBeneficiary",
  async (payload, { dispatch }) => {
    const { t, status, formData, from } = payload;
    delete payload.status;
    delete payload.FormData;

    try {
      dispatch(startLoading());
      let url = `/market/beneficiaries/verify`;
      const res = await API.post(config.p2p)(url, from === "add" ? payload : formData);
      dispatch(stopLoading());
      dispatch(
        toastSelector(
          {
            message: `${t("services.advertisement.success")} ${status === "edit" ? t("services.advertisement.updated") : t("services.advertisement.added")
              } `,
          },
          `success`
        )
      );

      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const updateBeneficiary = createAsyncThunk(
  "advertisement/updateBeneficiary",
  async (payload, { dispatch }) => {
    const { formData, t } = payload
    try {
      dispatch(startLoading());
      let url = `/market/beneficiaries`;
      await API.put(config.p2p)(url, formData);
      dispatch(stopLoading());
      dispatch(
        toastSelector(
          {
            message: `${t("services.advertisement.success")} ${t("services.advertisement.updated")}`,
          },
          `success`
        )
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const deleteBeneficiary = createAsyncThunk(
  "advertisement/deleteBeneficiary",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/beneficiaries`;
      await API.delete(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(toastSelector({ message: "benefeciary.delete" }, "success"));
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const getBarCode = createAsyncThunk(
  "advertisement/getBarCode",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());

      let url = `/resource/otp/generate_qrcode`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const enableGoogleAuth = createAsyncThunk(
  "advertisement/enableGoogleAuth",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/otp/enable`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(toastSelector({ message: "googleAuth.success" }, "success"));
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);
