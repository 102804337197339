import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";

import { buildQueryString } from "../../helpers";
import { fetchBuySellHistory } from "./buySellServices";
import {
  failedSendTransData,
  setTransStatus,
  startLoading,
  stopLoading,
} from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";

export const initValidateTrans = createAsyncThunk(
  "transakWidget/initValidateTrans",
  async (payload, { rejectWithValue, dispatch }) => {
    const type = payload.isBuyOrSell;
    const t = payload.t;
    delete payload.t;

    try {
      dispatch(startLoading());
      let url = `/account/transak_validation?${buildQueryString(payload)}`;
      const data = await API.get(config.peatio)(url);
      if (data.status === 400) {
        dispatch(
          successToastSelector({
            message: [data.message],
            code: data.code,
            type: "error",
          })
        );
        return;
      }
      dispatch(setTransStatus(true));
      dispatch(stopLoading());
      return data;
    } catch (e) {
      dispatch(stopLoading());

      if (type === "SELL") {
        if (e.message[0]?.split(":")[0] === "minAmount") {
          dispatch(
            errorToastSelector({
              message: [
                `${t("buy_sell.error.greater")} ${e.message[0]?.split(":")[1]}`,
              ],
              code: e.code,
              type: "error",
            })
          );
        } else if (e.message[0]?.split(":")[0] === "maxAmount") {
          dispatch(
            errorToastSelector({
              message: [
                `${t("buy_sell.error.less")} ${e.message[0]?.split(":")[1]}`,
              ],
              code: e.code,
              type: "error",
            })
          );
        }
      } else {
        if (e.code === 400) {
          let minAmount = e.message[0]
            ?.split(",")[0]
            ?.replace("minAmount:", "");
          let maxAmount = e.message[0]
            ?.split(",")[1]
            ?.replace("maxAmount:", "");

          dispatch(
            errorToastSelector({
              message: [
                `${t("buy_sell.error.in_between")} ${minAmount} ${maxAmount ? `${t("buy_sell.error.and")} ${maxAmount}` : ""
                }.`,
              ],
              code: e.code,
              type: "error",
            })
          );
        } else {
          dispatch(
            errorToastSelector({
              message: [e.message],
              code: e.code,
              type: "error",
            })
          );
        }
      }
      dispatch(setTransStatus(false));
      return rejectWithValue(e);
    }
  }
);

export const sendTransakData = createAsyncThunk(
  "transakWidget/sendTransakData",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `/account/tranask`;
      let data = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());
      switch (payload.status) {
        case "PAYMENT_DONE_MARKED_BY_USER":
          dispatch(
            successToastSelector({
              message: ["account.transak.payment_done"],
              code: data.code,
              type: "success",
            })
          );

          break;
        case "PROCESSING":
          dispatch(
            successToastSelector({
              message: ["account.transak.processing"],
              code: data.code,
              type: "success",
            })
          );
          break;
        case "PENDING_DELIVERY_FROM_TRANSAK":
          dispatch(
            successToastSelector({
              message: ["account.transak.processing"],
              code: data.code,
              type: "success",
            })
          );
          break;
        default:
          break;
      }

      dispatch(
        fetchBuySellHistory({
          transak_type: payload.transak_type,
          currency: payload.crypto_currency,
          network: payload.network,
        })
      );
      return data;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(failedSendTransData());
      dispatch(setTransStatus(false));
      if (e.message !== t("services.mercent")) {
        dispatch(
          errorToastSelector({
            message: [e.message],
            code: e.code,
            type: "error",
          })
        );
      } else {
        dispatch(errorToastSelector(e, "error"));
      }
      return rejectWithValue(e);
    }
  }
);
