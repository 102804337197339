import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import * as config from "../../../utils/apiVersion";
import { toastSelector } from "../../selector/toastSelector";
import { startLoading, stopLoading } from "../../feature";

export const getUser = createAsyncThunk(
  "user/getUserData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "resource/users/me";
      const res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const updateUserExtraData = createAsyncThunk(
  "user/updateUserExtraData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/users/preference`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(getUser());
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const getTranslationList = createAsyncThunk(
  "commonServices/getTranslationList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/identity/users/translations`;
      let data = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return { res: data, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);