import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import moment from "moment";
import { getConvertHistory } from "../../../redux/services";
import CustomPagination from "../../customHooks/customPagination";
import {
  capitalize,
  dateToUtc,
  lowercase,
  toOptionalFixed,
  uppercase,
} from "../../../helpers";
import { settingHisotryTabsKey } from "../../../staticObjects";
import Filter from "../../Ui/filter/Filter";
import ExportData from "../../Ui/exportData";

export const WalletDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { walletData, walletDataTotal } = useSelector((state) => state.wallet);
  const { currenciesList } = useSelector((state) => state.commonApiData);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterObj, setFilterObj] = useState({});

  const replaceTxid = (link, repl) => {
    return link !== undefined && link !== null && link.replace("#{txid}", repl);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const createLink = (txid, explorer_transaction, t) => {
    return txid ? (
      <>
        {
          <a
            target="_blank"
            className="overflowText"
            href={replaceTxid(explorer_transaction, txid)}
            title={txid}
          >
            {truncate(txid, 30)}
          </a>
        }
      </>
    ) : (
      t("transhistory.na")
    );
  };

  const getAddressLink = (
    deposit_address = "-",
    curency_id,
    currency_object,
    transaction_type
  ) => {
    let address_url = "#";
    let address_link = "";

    for (let i = 0; i < currency_object?.length; i++) {
      if (currency_object[i]?.id.toUpperCase() === curency_id?.toUpperCase()) {
        currency_object[i]?.networks?.map((val) => {
          if (val.id === currency_object[i]?.default_network_id) {
            address_url = val.explorer_address?.replace(
              "#{address}",
              deposit_address
            );
          }
          return val;
        });
        break;
      }
    }

    if (
      deposit_address &&
      deposit_address !== "-" &&
      address_url !== "#" &&
      !transaction_type
    ) {
      address_link = (
        <a href={address_url} target="_blank" rel="noopener noreferrer">
          {truncate(deposit_address, 30)}
        </a>
      );
    } else {
      address_link = deposit_address ? truncate(deposit_address, 30) : "-";
    }

    return address_link;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <p className="coins">{dateToUtc(text, "DD-MM-YYYY  HH:mm")}</p>
      ),
    },
    {
      title: "Currency",
      dataIndex: "currency_id",
      key: "currency_id",
      render: (text) => uppercase(text),
    },
    // {
    //   title: "Total",
    //   dataIndex: "amount",
    //   key: "amount",
    // },
    {
      title: "Fee",
      dataIndex: "deposit_fee",
      key: "deposit_fee",
    },
    {
      title: "Amount",
      dataIndex: "deposit_amount",
      key: "deposit_amount",
    },
    {
      title: "Tx ID",
      dataIndex: "txid",
      key: "txid",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <h6 className={`${lowercase(text)}`}>{capitalize(text)}</h6>
      ),
    },
  ];

  const columnsTransHis = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <p className="coins">{dateToUtc(text, "DD-MM-YYYY  HH:mm")}</p>
      ),
    },
    {
      title: "Currency",
      dataIndex: "currency_id",
      key: "currency_id",
      render: (text) => uppercase(text),
    },
    {
      title: "Fee",
      dataIndex: "deposit_fee",
      key: "deposit_fee",
    },
    {
      title: "Amount",
      dataIndex: "deposit_amount",
      key: "deposit_amount",
    },
    {
      title: "Total",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Tx ID",
      dataIndex: "txid",
      key: "txid",
    },
    {
      title: "From Address",
      dataIndex: "from_addresses",
      key: "from_addresses",
    },
    {
      title: "Wallet Type",
      dataIndex: "wallet_type",
      key: "wallet_type",
    },
    {
      title: "Network",
      dataIndex: "network",
      key: "network",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <h6 className={`${lowercase(text)}`}>{capitalize(text)}</h6>
      ),
    },
  ];

  function getExplorerTransaction(currencyId, blockchainKey) {
    const mainObj =
      currenciesList.length > 0 &&
      currenciesList?.find((item) => item.id === currencyId);
    if (!mainObj) {
      return null;
    }
    const networkObj = mainObj.networks.find(
      (network) => network.blockchain_key === blockchainKey
    );
    if (!networkObj) {
      return mainObj.explorer_transaction; // Return the main explorer_transaction if no matching blockchain_key is found
    }
    return networkObj.explorer_transaction;
  }

  const createData = () => {
    return walletData?.length > 0
      ? walletData.map((item, idx) => {
        let getExplore =
          currenciesList?.length > 0 &&
          currenciesList.filter((items) => items.id === item.currency_id)[0];
        const getTxId = getExplorerTransaction(
          item?.currency_id,
          item?.network
        );
        return {
          keyId: idx, // Unique key for each row
          created_at: item?.created_at,
          currency_id: item?.currency_id,
          txid: createLink(item?.txid, getTxId),
          // from_addresses: item?.from_addresses,
          from_addresses: getAddressLink(
            item?.from_addresses,
            item?.currency_id,
            currenciesList
          ),
          amount: item?.amount,
          deposit_fee: item?.deposit_fee,
          deposit_amount: item?.deposit_amount,
          wallet_type: item?.wallet_type,
          status: item?.status,
          network: item?.network,
        };
      })
      : [];
  };
  const hitApi = (data) => {
    setPage(data.page);
    setLimit(data.limit);
    let sendData = {
      page: page,
      limit: limit,
      ...filterObj,
      ...data,
    };
    dispatch(getConvertHistory({ ...sendData }));
  };

  useEffect(() => {
    hitApi({ page: page, limit: limit });
  }, []);

  const callBackFun = (obj) => {
    const { page, limit, ...rest } = obj;
    setPage(page);
    setLimit(limit);
    setFilterObj({ ...rest });
  };

  let isPagi = props.paginate;
  return (
    <div>
      {props.isFilterVisible && (
        <Filter currentTab={settingHisotryTabsKey.wallet} cbFun={callBackFun} />
      )}
      {props.isExportVisible && (
        <ExportData currentTab={settingHisotryTabsKey.wallet} />
      )}
      <Table
        dataSource={createData()}
        columns={isPagi ? columnsTransHis : columns}
        pagination={false}
        className="portfolioTable cursorInitial convertHisTable"
        rowKey={(obj) => obj.keyId}
      />

      {isPagi && (
        <CustomPagination
          pageNo={page}
          limit={limit}
          total={walletDataTotal}
          onChange={(pageNo, pageSize) => {
            hitApi({ page: pageNo, limit: pageSize });
          }}
        />
      )}
    </div>
  );
};
