import { createSlice } from "@reduxjs/toolkit";
import {
  getBlogPosts,
  getSinglePost,
  launchpadCoinListings,
  selectedLaunchpadCoinListings,
  postApplicationFormData,
  postCommitMntFormData,
  coinListingStake,
  getLaunchPadHistory,
  getLaunchPadBidHistory,
  getLaunchPadTransBidHistory,
  getBlockchainNetworks,
  getTokenNames,
} from "../../services";

const launchpadSlice = createSlice({
  name: "launchpad",
  initialState: {
    error: false,
    loading: false,
    launchpadCoinList: [],
    launchpadCoinListTotal: 0,
    selectedLaunchpadCoinList: {},
    coinListingStakeData: {},
    postApiRes: {},
    total: 0,
    projectDetailFormData: {},
    socialMediaFormData: {},
    tokenDetailFormData: {},
    uploadFormData: {
      files: [
        {
          type: "icon",
          error: "",
          file: "",
        },
        {
          type: "whitepaper",
          error: "",
          file: "",
        },
        {
          type: "incorporation_documents",
          error: "",
          file: "",
        },
        {
          type: "tokenomics",
          error: "",
          file: "",
        },
      ],
    },
    launchPadHistory: [],
    launchPadHistoryTotal: 0,
    launchPadBidHistory: [],
    launchPadBidHistoryTotal: 0, 
    launchPadTransBidHistory: [],
    launchPadTransBidHistoryTotal: 0,
    blockchainNetworks:[],
    tokenNames:[]
  },
  reducers: {
    setProjectDetailFormData: (state, action) => {
      state.projectDetailFormData = { ...action.payload };
    },
    setSocialMediaFormData: (state, action) => {
      state.socialMediaFormData = { ...action.payload };
    },
    setTokenDetailFormData: (state, action) => {
      state.tokenDetailFormData = { ...action.payload };
    },
    setUploadFormData: (state, action) => {
      state.tokenDetailFormData = { ...action.payload };
    },
    resetFormData: (state) => {
      state.projectDetailFormData = {};
      state.socialMediaFormData = {};
      state.tokenDetailFormData = {};
      state.uploadFormData = {
        files: [
          {
            type: "icon",
            error: "",
            file: "",
          },
          {
            type: "whitepaper",
            error: "",
            file: "",
          },
          {
            type: "incorporation_documents",
            error: "",
            file: "",
          },
          {
            type: "tokenomics",
            error: "",
            file: "",
          },
        ],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(launchpadCoinListings.pending, (state) => {
        state.error = false;
        state.launchpadCoinList = [];
      })
      .addCase(launchpadCoinListings.fulfilled, (state, action) => {
        state.launchpadCoinList = action?.payload?.data;
        state.launchpadCoinListTotal = action?.payload?.total; 
      })
      .addCase(launchpadCoinListings.rejected, (state) => {
        state.launchpadCoinList = [];
        state.error = true;
      })
      .addCase(getBlockchainNetworks.pending, (state) => {
        state.error = false;
        state.blockchainNetworks = [];
      })
      .addCase(getBlockchainNetworks.fulfilled, (state, action) => {
        state.blockchainNetworks = action?.payload?.data;
      })
      .addCase(getBlockchainNetworks.rejected, (state) => {
        state.blockchainNetworks = [];
        state.error = true;
      })
      .addCase(getTokenNames.pending, (state) => {
        state.error = false;
        state.tokenNames = [];
      })
      .addCase(getTokenNames.fulfilled, (state, action) => {
        state.tokenNames = action?.payload?.data;
      })
      .addCase(getTokenNames.rejected, (state) => {
        state.tokenNames = [];
        state.error = true;
      })
      .addCase(selectedLaunchpadCoinListings.pending, (state) => {
        state.error = false;
        state.selectedLaunchpadCoinList = {};
      })
      .addCase(selectedLaunchpadCoinListings.fulfilled, (state, action) => {
        state.selectedLaunchpadCoinList = action?.payload?.data;
      })
      .addCase(selectedLaunchpadCoinListings.rejected, (state) => {
        state.selectedLaunchpadCoinList = {};
        state.error = true;
      })
      .addCase(getLaunchPadHistory.pending, (state) => {
        state.error = false;
        state.launchPadHistory = [];
      })
      .addCase(getLaunchPadHistory.fulfilled, (state, action) => { 
        state.launchPadHistory = action?.payload?.data;
        state.launchPadHistoryTotal = action?.payload?.total;
      })
      .addCase(getLaunchPadHistory.rejected, (state) => {
        state.launchPadHistory = [];
        state.error = true;
      })

      .addCase(getLaunchPadBidHistory.pending, (state) => {
        state.error = false; 
      })
      .addCase(getLaunchPadBidHistory.fulfilled, (state, action) => { 
        state.launchPadBidHistory = action?.payload?.data;
        state.launchPadBidHistoryTotal = action?.payload?.total;
      })
      .addCase(getLaunchPadBidHistory.rejected, (state) => {
        state.launchPadBidHistory = [];
        state.error = true;
      })

      // getLaunchPadTransBidHistory
      .addCase(getLaunchPadTransBidHistory.pending, (state) => {
        state.error = false; 
      })
      .addCase(getLaunchPadTransBidHistory.fulfilled, (state, action) => {  
        state.launchPadTransBidHistory = action?.payload?.data;
        state.launchPadTransBidHistoryTotal = action?.payload?.total;
      })
      .addCase(getLaunchPadTransBidHistory.rejected, (state) => {
        state.launchPadTransBidHistory = [];
        state.error = true;
      })

      .addCase(coinListingStake.pending, (state) => {
        state.error = false;
        state.coinListingStakeData = {};
      })
      .addCase(coinListingStake.fulfilled, (state, action) => {
        state.coinListingStakeData = action?.payload?.data;
      })
      .addCase(coinListingStake.rejected, (state) => {
        state.coinListingStakeData = {};
        state.error = true;
      })

      .addCase(postCommitMntFormData.pending, (state) => {
        state.error = false;
        state.postApiRes = {};
      })
      .addCase(postCommitMntFormData.fulfilled, (state, action) => {
        state.postApiRes = action?.payload.data;
      })
      .addCase(postCommitMntFormData.rejected, (state) => {
        state.postApiRes = {};
        state.error = true;
      })

      .addCase(postApplicationFormData.pending, (state) => {
        state.error = false;
        state.postApiRes = {};
      })
      .addCase(postApplicationFormData.fulfilled, (state, action) => {
        state.postApiRes = action?.payload.data;
      })
      .addCase(postApplicationFormData.rejected, (state) => {
        state.postApiRes = {};
        state.error = true;
      });
  },
});

export const {
  setProjectDetailFormData,
  setSocialMediaFormData,
  setTokenDetailFormData,
  setUploadFormData,
  resetFormData,
} = launchpadSlice.actions;
export default launchpadSlice.reducer;
