import React, { useEffect } from "react";
import LaunchpadStepList from "./LaunchpadStepList";
import LaunchpadCard from "./LaunchpadCard";
import style from "./style.module.scss";
import { Typography, Button } from "antd";
import Header from "../Landing/Header.jsx";
import Footer from "../../Layout/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { BackIcon, AheadIcon } from "../../Assets/Svg/Svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { selectedLaunchpadCoinListings } from "../../../redux/services/launchpadService.js";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const Listingppoints = ({ title, lineText }) => {

  return (
    <>
      <h6>{title}</h6>
      {lineText?.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};
const LaunchpadDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { selectedLaunchpadCoinList } = useSelector(
    (state) => state?.launchpad
  );
  const selectedLaunchpadCoinListingsFn = async (payload) => {
    await dispatch(selectedLaunchpadCoinListings(payload));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      selectedLaunchpadCoinListingsFn({ id: id });
    }
  }, [id]);

  const afterTimeCompletionHandler = () => {
    if (id) {
      setTimeout(() => {
        selectedLaunchpadCoinListingsFn({ id: id, reload: true });
      }, 2000);
    }
  };

  const historyPage = () => {
    // Call the navigate function to redirect to a specific route
    navigate(`/launchpadHistory/${id}`); // Replace '/desired-route' with the route you want to navigate to
  };
  return (
    <>
      <Header className="listingHeader" />
      <div className={`LaunchpadDetail-page  commanBgFull`}>
        <div className="container ">
          <Button
            className={`backbtn modalBackBtn ${style.back}`}
            style={{ marginBottom: "28px" }}
            type="text"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackIcon style={{ marginRight: "5px" }} /> {t("launchpad.back_to_prev")}
          </Button>
          <LaunchpadCard
            tagtext={selectedLaunchpadCoinList?.state}
            state={selectedLaunchpadCoinList?.state}
            color="error"
            timeCount={moment(selectedLaunchpadCoinList?.created_at).format(
              "DD-MM-YYYY"
            )}
            title={selectedLaunchpadCoinList?.project_name}
            description={selectedLaunchpadCoinList?.project_description}
            cardblockimg={true}
            monero={true}
            tokensOffered={selectedLaunchpadCoinList?.total_supply}
            salePrice={selectedLaunchpadCoinList?.public_sale_token_price}
            symbol={selectedLaunchpadCoinList?.symbol}
            participants={selectedLaunchpadCoinList?.total_participants}
            total_comt={selectedLaunchpadCoinList?.total_commited}
            pricePrecision={selectedLaunchpadCoinList?.price_precision}
            stage_end_date={selectedLaunchpadCoinList?.stage_end_date}
            iconUrl={selectedLaunchpadCoinList?.metadata?.icon}
            public_sale_token_price={
              selectedLaunchpadCoinList?.public_sale_token_price
            }
            afterTimeCompletionHandler={afterTimeCompletionHandler}
            token_name={selectedLaunchpadCoinList?.token_name}
            hard_cap={selectedLaunchpadCoinList?.hard_cap}
            project_name={selectedLaunchpadCoinList?.project_name}
            DayCount
          />
          {selectedLaunchpadCoinList?.self_listed && (
            <Button
              className={`backbtn modalBackBtn ${style.launchpadHistory}`}
              type="text"
              onClick={historyPage}
            >
              {t("launchpad.launchpad_history")} <AheadIcon style={{ marginLeft: "5px" }} />
            </Button>
          )}
          {selectedLaunchpadCoinList?.state !== "verified" ? (
            <div className={style.LaunchpadDetail}>
              <section className={`${style.stepTimeline} subscriptionSec`}>
                <Title level={3}>{t("launchpad.subscription_timeline")}</Title>
                <LaunchpadStepList />
              </section>
            </div>
          ) : (
            <></>
          )}
          <section className={style.blockchainAI}>
            {/* <Title level={3}>
              Arkham- An intel-to-earn token powering the deanonymization of the
              blockchain with AI.
            </Title> */}
            {selectedLaunchpadCoinList?.project_description ? (
              <Listingppoints
                title={t("launchpad.project_description")}
                lineText={[selectedLaunchpadCoinList?.project_description]}
              />
            ) : (
              <></>
            )}
            {selectedLaunchpadCoinList?.admin_description ? (
              <Listingppoints
                // title="Key Features and Highlights"
                lineText={[selectedLaunchpadCoinList?.admin_description]}
              />
            ) : (
              <></>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LaunchpadDetail;
