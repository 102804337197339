import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import moment from "moment";
import { getConvertHistory } from "../../../redux/services";
import CustomPagination from "../../customHooks/customPagination";
import {
  dateToUtc,
  exponentialToDecimalConvert,
  lowercase,
  toOptionalFixed,
  uppercase,
} from "../../../helpers";
import {
  ConvertTableDataIndex,
  settingHisotryTabsKey,
} from "../../../staticObjects";
import Filter from "../../Ui/filter/Filter";
import ExportData from "../../Ui/exportData";

export const Convert = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { convertHistory, convertTotal } = useSelector(
    (state) => state.transactionHistories
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterObj, setFilterObj] = useState({});
  const { currenciesList } = useSelector((state) => state.commonApiData);
  const { date, you_swap, you_get, status } = ConvertTableDataIndex;

  const filterSelectCurrObj = (value) => {
    return currenciesList?.filter((item) => item.id === value)[0];
  };

  const columns = [
    {
      title: <p className="coins"> {date}</p>,
      dataIndex: date,
      key: date,
    },
    {
      title: you_swap,
      dataIndex: you_swap,
      key: you_swap,
    },
    {
      title: you_get,
      dataIndex: you_get,
      key: you_get,
    },
    {
      title: status,
      dataIndex: status,
      key: status,
    },
  ];

  const createData = () => {
    return convertHistory?.length
      ? convertHistory?.map((item, idx) => {
          const { state, ask_currency, currency, created_at, price, amount } =
            item;
          const convertStatus = `${t(`statusState.${state}`)}`;
          const swapCurrentCurr = filterSelectCurrObj(ask_currency);
          const getCurrentCurr = filterSelectCurrObj(currency);
          return {
            keyId: currency + idx,
            [date]: (
              <p className="coins">
                {dateToUtc(created_at, "DD-MM-YYYY  HH:mm")}
              </p>
            ),
            [you_swap]: `${exponentialToDecimalConvert(
              toOptionalFixed(price, swapCurrentCurr?.precision)
            )} ${uppercase(ask_currency)}`,
            [you_get]: `${exponentialToDecimalConvert(
              toOptionalFixed(amount, getCurrentCurr?.precision)
            )} ${uppercase(currency)}`,
            [status]: (
              <h6 className={`${lowercase(convertStatus)}`}>{convertStatus}</h6>
            ),
          };
        })
      : [];
  };

  const hitApi = (data) => {
    setPage(data.page);
    setLimit(data.limit);
    let sendData = {
      page: page,
      limit: limit,
      ...filterObj,
      ...data,
    };
    dispatch(getConvertHistory({ ...sendData }));
  };

  useEffect(() => {
    hitApi({ page: page, limit: limit });
  }, []);

  const callBackFun = (obj) => {
    const { page, limit, ...rest } = obj;
    setPage(page);
    setLimit(limit);
    setFilterObj({ ...rest });
  };

  let isPagi = props.paginate;
  return (
    <div>
      {props.isFilterVisible && (
        <Filter
          currentTab={settingHisotryTabsKey.convert}
          cbFun={callBackFun}
        />
      )}
      {props.isExportVisible && (
        <ExportData currentTab={settingHisotryTabsKey.convert} />
      )}
      <Table
        dataSource={createData()}
        columns={columns}
        pagination={false}
        className="portfolioTable cursorInitial convertHisTable"
        rowKey={(obj) => obj.keyId}
      />
      {isPagi && (
        <CustomPagination
          pageNo={page}
          limit={limit}
          total={convertTotal}
          onChange={(pageNo, pageSize) => {
            hitApi({ page: pageNo, limit: pageSize });
          }}
        />
      )}
    </div>
  );
};
