import React, { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import CommonButton from "../button/CommonButton.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  downloadDateName,
  exportObj,
  exportTypeObj,
  fieldType,
  sendExportType,
} from "../../../staticObjects/primary.jsx";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { startEndDateSchema } from "../../../Schema/date";
import CommonRenderFields from "../../Ui/commonRenderFields";
import CommonModal from "../../CommonModal/index.jsx";
import CustomSelect from "../CustomSelect/CustomSelect.jsx";
import { downloadData } from "../../../redux/services/transHistories.js";
import { useDispatch, useSelector } from "react-redux";
import { createPairList, createPairListWithoutAll } from "../../../helpers/dropdownObj.js";
import { currencyDropObj } from "../../../helpers/currencyDropObj.js";

const initData = {
  startDate: null,
  endDate: null,
  status: "",
};

function ExportData(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [moduleType, setModuleType] = useState("");
  const [exportModal, setExportModal] = useState(false);
  const [exportType, setExportType] = useState(exportObj[0].value);
  const [selectPair, setSelectPair] = useState("");
  const { currentTab } = props;
  const { startDate, endDate } = downloadDateName;
  const { marketPairList } = useSelector((state) => state.transactionHistories);
  const { currentMarketId, currenciesList } = useSelector((state) => state.commonApiData);
  const isShowPair = currentTab === "orders" || currentTab === "trades";
  const [selectCurrency, setSelectCurrency] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors, isValid: formIsvalid },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(startEndDateSchema(t)),
    defaultValues: initData,
  });

  const isShowCurrency = currentTab === "transfer"

  useEffect(() => {
    setModuleType(currentTab);
  }, [currentTab]);

  const onloadSelectPair = () => {
    if (!marketPairList.length) return;
    const pairList = createPairListWithoutAll(marketPairList, t);
    let pair = currentMarketId ? currentMarketId : pairList[0].value;
    return { pair, zerothIndexPair: pairList[0].value };
  };

  useEffect(() => {
    if (!marketPairList.length) return;
    setSelectPair(onloadSelectPair().pair);
  }, [marketPairList]);

  const onSubmit = (data) => {
    if (formIsvalid) {
      setExportModal(true);
    }
  };

  const commanProp = {
    errors,
    control,
    type: fieldType.dateInput,
    inputProps: { type: "date" },
  };

  const exportHandle = async () => {
    if (!exportType) {
      message.error(t("export_data.export_type"));
      return;
    }

    let stDate = new Date(getValues().startDate);
    stDate.setUTCHours(0, 0, 0, 0);
    let newStartDate = stDate.toUTCString();

    let enDate = new Date(getValues().endDate);
    enDate.setUTCHours(23, 59, 59, 999);
    let newEndDate = enDate.toUTCString();

    let data = {
      from: newStartDate,
      to: newEndDate,
      csv_type: sendExportType[moduleType],
      download_type: exportType,
      currency: selectCurrency,
      type:
        currentTab === "orders"
          ? selectPair
          : currentTab === "trades"
            ? selectPair
            : currentTab === "buy"
              ? "BUY"
              : currentTab === "sell"
                ? "SELL"
                : "",
      tab: currentTab,

    };
    if (currentTab === "transfer") {
      delete data.currency
      data["currency_id"] = selectCurrency
    }
    if (currentTab === "send") {
      data["transaction_type"] = props?.withdrawType
    }
    else {
      data["transaction_type"] = props?.depositType == "internal" ? 300 : 400;
    }
    let res = await dispatch(downloadData(data));

    if (res.payload.status) {
      setExportModal(false);
      setExportType(exportObj[0].value);
      let win = window.open(res.payload.link);
      win?.focus();
    }
  };

  return (
    <>
      <div className="filtrspacing 1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[21, 21]}>
            <Col lg={20} md={14} xs={24}>
              <Row gutter={[21, 15]}>
                <Col lg={4} md={12} xs={24} className="text_size">
                  <CommonRenderFields
                    {...commanProp}
                    name={startDate}
                    label={t("download.start_date")}
                  />
                </Col>
                <Col lg={4} md={12} xs={24} className="text_size">
                  <CommonRenderFields
                    {...commanProp}
                    name={endDate}
                    label={t("download.end_date")}
                  />
                </Col>

                {isShowPair && (
                  <Col lg={4} md={12} xs={24}>
                    <CustomSelect
                      placeholder={t("placeholder.all_pair")}
                      label={t("labels.pair")}
                      drop_data={createPairListWithoutAll(marketPairList, t)}
                      onChange={(val) => setSelectPair(val)}
                      value={selectPair}
                    />
                  </Col>
                )}

                {isShowCurrency && (
                  <Col lg={6} xl={4} md={12} xs={24}>
                    <CustomSelect
                      placeholder={t("placeholder.all_curr")}
                      label={t("labels.select_currency")}
                      drop_data={currencyDropObj(currenciesList, t)}
                      onChange={(currency) => setSelectCurrency(currency)}
                      value={selectCurrency}
                    />
                  </Col>
                )}

              </Row>
            </Col>
            <Col lg={4} md={10} xs={24}>
              <div className="mb24 centerbutns">
                <CommonButton
                  title={t("buttons.submit")}
                  className="btn"
                  onClick={handleSubmit(onSubmit)}
                />
                <CommonButton
                  title={t("buttons.reset_btn")}
                  className="btn"
                  onClick={() => {
                    reset();
                    setModuleType(currentTab);
                  }}
                />
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <CommonModal
        centered
        isOpen={exportModal}
        onCancel={() => setExportModal(false)}
        className="export_modal"
      >
        <h2 className="titleModal">{t("export_data.statement")}</h2>
        <CustomSelect
          placeholder={t("placeholder.pdf")}
          label={t("labels.export_type")}
          drop_data={exportObj}
          onChange={(val) => setExportType(val)}
          value={exportType}
        />
        <div className="mb24 centerbutns">
          <CommonButton
            title={t("buttons.cancel")}
            className="btn"
            onClick={() => {
              setExportModal(false);
            }}
          />
          <CommonButton title={t("buttons.export")} className="btn" onClick={exportHandle} />
        </div>
      </CommonModal>
    </>
  );
}

export default ExportData;
