import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import { buildQueryString } from "../../../helpers";
import * as config from "../../../utils/apiVersion";
import { startLoading, stopLoading } from "../../feature";
import { toastSelector } from "../../selector/toastSelector";

export const getMessage = createAsyncThunk(
  "chat/getMessage",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/message?${buildQueryString(payload)}`;
      const res = await API.get(config.p2pWithHeader)(url);
      return res;
    } catch (e) {
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async (payload, { rejectWithValue, dispatch }) => {
    let fromWhere = payload.fromWhere;
    try {
      let url = `/message`;
      const res = await API.post(config.p2p)(url, payload.formData);
      if (fromWhere === "enter") {
        return { res, fromWhere };
      } else {
        return res;
      }
    } catch (e) {
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "chat/deleteImage",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/message/attachment/${payload.id}`;
      await API.delete(config.p2p)(url, payload);
      dispatch(stopLoading());
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
