import { createSlice } from "@reduxjs/toolkit";
import {
  checkIsProTrader,
  getBotProperties,
  getCopierDetails,
  getCopyMarketPosition,
  getCopyOrderHistory,
  getCopyPnlGraph,
  getCopyRoiGraph,
  getCopyUsdtBalance,
  getFollowerList,
  getProTraderAvatar,
  getProTraderBalance,
  getProTraderCommision,
  getProTraderConversion,
  getProTraderFollowerList,
  getProTraderRevenue,
  getProTradersList,
  getSingleBotSettingData,
  getTradingVolumeGraph,
  getWatchList,
  singleProTraderDetails,
  submitCopyOrders,
  updateProTraderPairs,
} from "../../services/copyTradeService";
import { getCloneData, insertOrUpdate, insertOrUpdatePosition } from "../../../helpers";

const initialState = {
  error: false,
  loading: false,
  userDetail: {},
  proTradersList: [],
  followingList: [],
  followingListTotal: 0,
  watchList: [],
  watchListTotal: 0,
  singleProTraderDetail: {},
  proTraderFollowList: [],
  proTraderFollowListTotal: 0,
  proTraderCommision: [],
  proTraderCommisionTotal: 0,
  copyBalanceList: [],
  proTraderRevenue: [],
  proTraderRevenueTotal: 0,
  myCopyTradeTab: "copy_trade_stats",
  copyStatsTab: "current",
  selectedCopyStat: "positions",
  selectedCopyPane: "Current Positions",
  selectedLeadStat: "positions",
  proTraderStatTab: "current",
  profileDetailTab: "follower_details",
  copierDetails: {},
  positionList: [],
  positionListTotal: 0,
  copyUsdtBalance: "",
  botProperties: {},
  copySuccess: false,
  copyOrders: [],
  copyOrdersCount: 0,
  singleBotData: {},
  pnlGraph: [],
  roiGraph: [],
  selectedOpt: {},
  avatar: []
};

export const copyTradeSlice = createSlice({
  name: "copyTradeSlice",
  initialState: initialState,
  reducers: {
    setMyCopyTradeTab: (state, action) => {
      state.myCopyTradeTab = action?.payload;
    },
    setCopyStatsTab: (state, action) => {
      state.copyStatsTab = action?.payload;
    },
    setSelectedCopyStats: (state, action) => {
      state.selectedCopyStat = action?.payload;
    },
    setProfileDetailTab: (state, action) => {
      state.profileDetailTab = action?.payload;
    },
    setCopyPane: (state, action) => {
      state.selectedCopyPane = action?.payload;
    },
    setSelectedLeadStat: (state, action) => {
      state.selectedLeadStat = action?.payload;
    },
    setProTraderStatsTab: (state, action) => {
      state.proTraderStatTab = action?.payload;
    },
    setCopySuccess: (state, action) => {
      state.copySuccess = action.payload;
    },
    getSelectedOptionData: (state, action) => {
      state.selectedOpt = action.payload;
    },
    getClearOptionData: (state, action) => {
      state.selectedOpt = {};
    },
    clearState: (state) => {
      Object.assign(state, initialState);
    },
    updateCopyOrder: (state, action) => {
      let currentOrderList = getCloneData(state.copyOrders);
      let newData = insertOrUpdate(currentOrderList, action.payload);
      state.copyOrders = newData;
    },
    updateCopyPosition: (state, action) => {
      let currentCopyPosition = getCloneData(state.positionList);
      let newData = insertOrUpdatePosition(currentCopyPosition, action.payload);
      state.positionList = newData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProTradersList.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getProTradersList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.proTradersList = action.payload.list;
        state.proTraderTotal = action.payload.total;
      })
      .addCase(getProTradersList.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getFollowerList.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getFollowerList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.followingList = action.payload.list;
        state.followingListTotal = action?.payload?.total ?? 0;
      })
      .addCase(getFollowerList.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getWatchList.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getWatchList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.watchList = action?.payload?.list;
        state.watchListTotal = action?.payload?.total ?? 0;
      })
      .addCase(getWatchList.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(checkIsProTrader.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.userDetail = {}
      })
      .addCase(checkIsProTrader.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.userDetail = action.payload.data;
      })
      .addCase(checkIsProTrader.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.userDetail = {};
      })

      .addCase(singleProTraderDetails.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(singleProTraderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.singleProTraderDetail = action.payload.data;
      })
      .addCase(singleProTraderDetails.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })

      .addCase(getProTraderFollowerList.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getProTraderFollowerList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.proTraderFollowList = action?.payload?.list;
        state.proTraderFollowListTotal = action?.payload?.total ?? 0;
      })
      .addCase(getProTraderFollowerList.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })

      .addCase(getProTraderCommision.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getProTraderCommision.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.proTraderCommision = action?.payload?.list;
        state.proTraderCommisionTotal = action?.payload?.total ?? 0;
      })
      .addCase(getProTraderCommision.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getProTraderRevenue.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getProTraderRevenue.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.proTraderRevenue = action?.payload?.list;
        state.proTraderRevenueTotal = action?.payload?.total ?? 0;
      })
      .addCase(getProTraderRevenue.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getProTraderBalance.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getProTraderBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.copyBalanceList = action.payload.list;
      })
      .addCase(getProTraderBalance.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getCopierDetails.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getCopierDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.copierDetails = action.payload.data;
      })
      .addCase(getCopierDetails.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getCopyMarketPosition.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.positionList = [];
      })
      .addCase(getCopyMarketPosition.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.positionList = action?.payload?.list;
        state.positionListTotal = action?.payload?.total ?? 0;
      })
      .addCase(getCopyMarketPosition.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.positionList = [];
      })
      .addCase(getCopyUsdtBalance.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.copyUsdtBalance = "";
      })
      .addCase(getCopyUsdtBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.copyUsdtBalance = action.payload.data;
      })
      .addCase(getCopyUsdtBalance.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.copyUsdtBalance = "";
      })
      .addCase(getBotProperties.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getBotProperties.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.botProperties = action.payload.data;
      })
      .addCase(getBotProperties.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(submitCopyOrders.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.copySuccess = false;
      })
      .addCase(submitCopyOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.copySuccess = true;
      })
      .addCase(submitCopyOrders.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.copySuccess = false;
      })
      .addCase(getCopyOrderHistory.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getCopyOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.copyOrders = action?.payload?.list;
        state.copyOrdersCount = action?.payload?.total ?? 0;
      })
      .addCase(getCopyOrderHistory.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getSingleBotSettingData.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getSingleBotSettingData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.singleBotData = action.payload.data
      })
      .addCase(getSingleBotSettingData.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      }).addCase(getCopyPnlGraph.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getCopyPnlGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.pnlGraph = action.payload.data
      })
      .addCase(getCopyPnlGraph.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getCopyRoiGraph.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getCopyRoiGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.roiGraph = action.payload.data
      })
      .addCase(getCopyRoiGraph.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })

      .addCase(getTradingVolumeGraph.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getTradingVolumeGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.tradingGraph = action.payload.data
      })
      .addCase(getTradingVolumeGraph.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })

      .addCase(getProTraderAvatar.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getProTraderAvatar.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.avatar = action.payload.list
      })
      .addCase(getProTraderAvatar.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
  },
});

export const {
  setMyCopyTradeTab,
  setCopyStatsTab,
  setSelectedCopyStats,
  setCopyPane,
  setSelectedLeadStat,
  updateCopyOrder,
  setProTraderStatsTab,
  setCopySuccess,
  getSelectedOptionData,
  getClearOptionData,
  setProfileDetailTab,
  clearState,
  updateCopyPosition
} = copyTradeSlice.actions;
export default copyTradeSlice.reducer;
