import React, { useCallback } from "react";
import style from "./style.module.scss";
import { Collapse, Grid, Table } from "antd";
import {
  exponentialToDecimalConvert,
  localeDateSec,
  preciseData,
  removeTrailingZero,
  uppercase,
} from "../../../../helpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NoRecord } from "../../../NoRecord";
import { checkIsPositive } from "../../../../helpers/checkIsPositive";
import { upDownPriceColor } from "../../../../staticObjects";

function CompletedDetailsCard({ data }) {
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;

  const { md } = useBreakpoint();

  const marketList = useSelector((state) => state?.exchange?.marketList);

  const { up, down } = upDownPriceColor

  const sideColor = (status) => {
    let orderStatus = status && status.toLowerCase();
    switch (orderStatus) {
      case "sell": {
        return "#b42463";
      }
      case "buy": {
        return "#389694";
      }
      case "wait": {
        return "yellow";
      }
      case "pending": {
        return "yellow";
      }
      case "done": {
        return "green";
      }
      case "cancel": {
        return "red";
      }
      case "reject": {
        return "red";
      }
      default: {
        return "";
      }
    }
  };

  const columns = [
    {
      title: t("titles.datetime"),
      dataIndex: "datetime",
      key: "datetime",
    },
    {
      title: t("titles.pair"),
      dataIndex: "pair",
      key: "pair",
    },
    {
      title: t("titles.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("titles.side"),
      dataIndex: "side",
      key: "side",
    },
    {
      title: t("titles.price"),
      dataIndex: `price`,
      key: `price`,
    },
    {
      title: t("titles.avg_price"),
      dataIndex: `avg_price`,
      key: `avg_price`,
    },
    {
      title: t("titles.amount"),
      dataIndex: `amount`,
      key: `amount`,
    },
    {
      title: t("titles.filled"),
      dataIndex: "filled",
      key: "filled",
    },
    {
      title: t("titles.remaining"),
      dataIndex: `remainingAmt`,
      key: `remainingAmt`,
    },
    {
      title: t("titles.fee"),
      dataIndex: `trade_fee`,
      key: `trade_fee`,
    },
    {
      title: t("titles.total"),
      dataIndex: "total",
      key: "total",
    },
  ];

  const checkState = (state) => {
    switch (state) {
      case "wait": {
        return t("detailedcard.new");
      }
      case "pending": {
        return t("detailedcard.partial");
      }
      case "done": {
        return t("detailedcard.done");
      }
      case "cancel": {
        return t("detailedcard.cancelled");
      }
      case "reject": {
        return t("detailedcard.rejected");
      }
      default: {
        return t("detailedcard.new");
      }
    }
  };

  const getMarketName = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined) {
      return market.name;
    } else {
      return "-";
    }
  };

  const getAmountPercision = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined) return market.amount_precision;
  };

  const getPricePercision = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined) return market.price_precision;
  };

  const getTotalPercision = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined)
      return market.amount_precision + market.amount_precision;
  };

  const getTableData = useCallback((item) => {
    const amountFixed = getAmountPercision(marketList, item?.market);
    const priceFixed = getPricePercision(marketList, item?.market);
    const totalPrecision = getTotalPercision(marketList, item?.market);

    return {
      datetime: <p>{localeDateSec(item?.updated_at)}</p>,
      pair: <p>{getMarketName(marketList, item?.market)}</p>,
      type: (
        <p>
          {item?.ord_type.charAt(0).toUpperCase() + item?.ord_type.slice(1)}
        </p>
      ),
      side: (
        <div className="flexCenter">
          <div
            className="flexCenter orderCls"
            style={{
              backgroundColor: sideColor(item?.side),
            }}
          >
            <small
              style={{
                color: "#fff",
              }}
            >
              {item?.side.charAt(0).toUpperCase() + item?.side.slice(1)}{" "}
            </small>
          </div>
        </div>
      ),
      average: (
        <div>
          {item?.state === "cancel" && item?.trades_count === 0
            ? "--"
            : `${removeTrailingZero(
              preciseData(item?.avg_price, priceFixed)
            )}(${uppercase(item?.bid)})`}Amount
        </div>
      ),
      price: (
        <div>
          {["limit", "stop_loss_limit", "take_profit_limit"].includes(
            item?.ord_type
          )
            ? `${removeTrailingZero(
              preciseData(item?.price, priceFixed)
            )} (${uppercase(item?.bid)})`
            : t("detailedcard.market")}
        </div>
      ),
      avg_price: (
        <div>
          {item?.state === "cancel" && item?.trades_count === 0
            ? "--"
            : `${removeTrailingZero(
              preciseData(item?.avg_price, priceFixed)
            )} (${uppercase(item?.bid)})`}
        </div>
      ),
      stop: (
        <div className="flexCenter">
          <span>
            {[
              "stop_loss_limit",
              "take_profit_limit",
              "stop_loss_order",
              "take_profit_limit",
            ].includes(item?.ord_type)
              ? removeTrailingZero(preciseData(item?.stop_price, priceFixed))
              : "-"}
          </span>
        </div>
      ),
      amount: (
        <span>
          {`${removeTrailingZero(
            preciseData(item?.origin_volume, amountFixed)
          )} ${uppercase(item?.ask)}`}
        </span>
      ),
      filled: (
        <div className="flexCenter">
          {removeTrailingZero(preciseData(item?.executed_volume, amountFixed))}
        </div>
      ),
      remainingAmt: (
        <div className="">
          <p>
            {removeTrailingZero(
              preciseData(item?.remaining_volume, amountFixed)
            )}
          </p>
        </div>
      ),
      total: (
        <p>
          {item?.state === "cancel"
            ? "--"
            : `${removeTrailingZero(
              preciseData(item?.total, totalPrecision)
            )} ${uppercase(item?.bid)}`}
        </p>
      ),
      status: (
        <span style={{ color: sideColor(item?.state) }}>
          {checkState(item?.state)}
        </span>
      ),
      trade_fee: (
        <span>
          {`${exponentialToDecimalConvert(removeTrailingZero(item?.trade_fee))} ${item?.side === "buy" ? uppercase(item?.ask) : uppercase(item?.bid)
            }`}
        </span>
      ),
    };
  });

  return (
    <>
      {/* <Collapse accordion> */}
      {data.length > 0 ? (
        data.map((item, idx) => (
          <div className={style.completedPannel}>
            <p>{localeDateSec(item.time)}</p>
            <Table
              columns={columns}
              pagination={false}
              dataSource={[
                item?.buy && getTableData(item?.buy),
                item.sell && getTableData(item.sell),
              ]}
              className="portfolioTable tableBorderNone"
              scroll={md && { x: 820 }}
              locale={{ emptyText: <NoRecord /> }}
            />
            <p className={`${style.profitDetail} profitDetail`}>
              {t("detailedcard.profits")}: {""}
              <span
                style={{
                  color: checkIsPositive(item?.single_grid_profit),
                }}
              >
                {item?.single_grid_profit} {"USDT"}
              </span>
            </p>
          </div>
        ))
      ) : (
        <div className={style.completedPannel}>
          <NoRecord />
        </div>
      )}
    </>
  );
}

export default CompletedDetailsCard;
