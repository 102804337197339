import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import {
  setCurrentMarket,
  setSuccess,
} from "../feature/exchange/exchanges.slice";

export const getMarkets = createAsyncThunk(
  "exchangeService/getMarkets",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = "public/markets";
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading(false));
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      dispatch(setCurrentMarket({}));
      return rejectWithValue(e);
    }
  }
);

export const getOrderBook = createAsyncThunk(
  "exchangeService/getOrderBook",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      if (payload?.id) {
        let url = `/public/markets/${payload?.id}/depth`;
        let res = await API.get(config.peatio)(url);
        dispatch(stopLoading(false));
        return { list: res, status: true };
      }
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getTrades = createAsyncThunk(
  "exchangeService/getTrades",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url;
      dispatch(startLoading(true));
      if (payload?.id) {
        url = `public/markets/${payload?.id}/trades`;
      } else {
        url = `/market/trades?${buildQueryString(payload)}`;
      }
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total > 0 ? res?.headers?.total : 0,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getMarketTickers = createAsyncThunk(
  "exchangeService/getMarketTickers",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = "public/markets/tickers";
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading(false));
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const submitOrders = createAsyncThunk(
  "exchangeService/submitOrders",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading(true));
      let url = `market/orders`;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: t("services.order_created") })
        );
        dispatch(setSuccess(true));
      }
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "exchangeService/CancelOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading(true));
      let url = `/market/orders/${payload.id}/cancel`;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: t("services.order_cancel") })
        );
        dispatch(setSuccess(true));
      }
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelAllOrder = createAsyncThunk(
  "exchangeService/cancelAllOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading(true));
      let url = `/market/orders/cancel`;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: t("services.all_cancel") })
        );
        dispatch(setSuccess(true));
      }
      return { status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getOrderHistory = createAsyncThunk(
  "exchangeService/getOrderHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    const callFrom = payload?.callFrom;
    delete payload?.callFrom;
    try {
      dispatch(startLoading(true));
      let url = `market/orders?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
        state: payload?.state ? payload?.state[0] : "",
        callFrom: callFrom,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getTradingRules = createAsyncThunk(
  "exchangeService/getTradingRules",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      if (payload?.market_id && payload?.uuid) {
        let url = `public/trading_fees?${buildQueryString(payload)}`;
        let res = await API.get(config.peatioWithHeader)(url);
        dispatch(stopLoading(false));
        return {
          list: res?.data[1] !== undefined ? res?.data[1] : res?.data[0],
          status: true,
        };
      }
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getMarketGraph = createAsyncThunk(
  "exchangeService/getMarketGraph",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      let url = `public/markets/graph`;
      let { data } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: data,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getFavMarketList = createAsyncThunk(
  "exchangeService/getFavMarketList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/account/fav_market/list`;
      let { data } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: data,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const setFavMarket = createAsyncThunk(
  "exchangeService/submitOrders",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/fav_market`;
      let res = await API.patch(config.peatio)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(getFavMarketList());
      }
      return { status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getGridSettings = createAsyncThunk(
  "exchangeService/getGridSettings",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/bot/settings?${buildQueryString(payload)}`;
      let res = await API.get(config.gridWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total > 0 ? res?.headers?.total : 0,
        status: true
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const createGrid = createAsyncThunk(
  "exchangeService/createGrid",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading(true));
      let url = `bot/setting`;
      let res = await API.post(config.grid)(url, payload);

      dispatch(successToastSelector({ message: t("services.grid_created") }));
      dispatch(setSuccess(true));
      dispatch(stopLoading(false));
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getAutoGridData = createAsyncThunk(
  "exchangeService/getAutoGridData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/bot/automated_params?${buildQueryString(payload)}`;
      let res = await API.get(config.grid)(url);
      dispatch(stopLoading(false));
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getGridSettingDetail = createAsyncThunk(
  "exchangeService/getGridSettingDetail",
  async (payload, { rejectWithValue, dispatch }) => {
    const { id, loaderShow } = payload
    try {
      if (loaderShow) {
        dispatch(startLoading(true));
      }
      let url = `/bot/settings/${id}`;
      let res = await API.get(config.grid)(url);
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);


export const getGridDetailPendingOrder = createAsyncThunk(
  "exchangeService/getGridDetailPendingOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/bot/pending_orders?${buildQueryString(payload)}`;
      let res = await API.get(config.grid)(url);
      return { list: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getGridDetailOrderHistory = createAsyncThunk(
  "exchangeService/getGridDetailOrderHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/bot/order_history?${buildQueryString(payload)}`;
      let res = await API.get(config.grid)(url);
      return { data: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelGridSetting = createAsyncThunk(
  "exchangeService/cancelGridSetting",
  async (payload, { dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `/bot/settings/${payload?.id}`;
      delete payload.id;
      const res = await API.put(config.grid)(url, payload);
      dispatch(
        successToastSelector({
          message: t("services.grid_canc"),
        })
      );
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);


export const getGridBotSettings = createAsyncThunk(
  "exchangeService/getGridBotSettings",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { isAuth } = payload;
      delete payload.isAuth;
      dispatch(startLoading(true));
      let url;
      if (isAuth) {
        url = `/bot/bot_setting`;
      }
      else {
        url = `/public/bot_setting`;
      }
      let res = await API.get(config.grid)(url);
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getGridInfo = createAsyncThunk(
  "exchangeService/getGridInfo",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/public/grid_info`;
      let res = await API.get(config.grid)(url);
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);