import moment from "moment";

export const dateFormat = (param) => {
  var dateObj = new Date(param);
  var momentObj = moment(dateObj);
  return momentObj.format("YYYY-MM-DD, HH:mm");
};

export const dateOnly = (param) => {
  var dateObj = new Date(param);
  var momentObj = moment(dateObj);
  return momentObj.format("YYYY-MM-DD");
};
