import { createSlice } from "@reduxjs/toolkit";

import {
  getAllP2pOrders,
  getBid,
  getSingleOrder,
  getAllBids,
  getMyOrders,
  profitandloss,
  getExpressBid,
  getUsdPrice,
  getLimitPrice,
  getCountries,
} from "../../../services/P2P";

export const p2pOrderSlice = createSlice({
  name: "p2pOrder",
  initialState: {
    error: false,
    orderListLoading: false,
    bidListLoading: false,
    orderList: [],
    bestPrice: 0,
    myOrderList: [],
    total: 0,
    singleOrder: {},
    singleBid: {},
    bidsList: [],
    profitandlossdata: [],
    pagename: "",
    paymentMethod: "",
    expressData: [],
    limit: 0,
    pageLoc: "",
    countries: [],
    btnClicked: false,
    filterData: {},
  },

  reducers: {
    initBlankSingleBid: (state) => {
      state.singleBid = {};
    },
    partialPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload.paymentMethod;
    },
    updateLimitPrice: (state, action) => {
      state.limit = action.payload;
    },
    emptyOrderList: (state, action) => {
      state.orderList = {};
    },
    getPageLocation: (state, action) => {
      state.pageLoc = action.payload;
    },
    clickOnViewOrderHis: (state, action) => {
      state.btnClicked = action.payload;
    },
    setMyAddFilter: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllP2pOrders.pending, (state) => {
        state.error = false;
        state.orderListLoading = true;
        state.orderList = [];
      })
      .addCase(getAllP2pOrders.fulfilled, (state, action) => {
        state.orderList = action.payload.list;
        state.total = action.payload.total;
        state.orderListLoading = false;
        state.pagename = action.payload.pagename;
      })
      .addCase(getAllP2pOrders.rejected, (state) => {
        state.error = true;
        state.orderListLoading = false;
        state.orderList = [];
      })

      .addCase(getUsdPrice.pending, (state) => {
        state.error = false;
        state.orderListLoading = true;
      })
      .addCase(getUsdPrice.fulfilled, (state, action) => {
        state.bestPrice = action.payload?.data;
        state.orderListLoading = false;
        state.pagename = action.payload.pagename;
      })
      .addCase(getUsdPrice.rejected, (state) => {
        state.error = true;
        state.orderListLoading = false;
      })

      .addCase(getLimitPrice.pending, (state) => {
        state.error = false;
      })
      .addCase(getLimitPrice.fulfilled, (state, action) => {
        state.limit = action.payload;
      })
      .addCase(getLimitPrice.rejected, (state) => {
        state.error = true;
      })

      .addCase(getMyOrders.pending, (state) => {
        state.error = false;
        state.orderListLoading = true;
      })
      .addCase(getMyOrders.fulfilled, (state, action) => {
        state.myOrderList = action.payload.list;
        state.total = action.payload.total;
        state.orderListLoading = false;
      })
      .addCase(getMyOrders.rejected, (state) => {
        state.error = true;
        state.orderListLoading = false;
      })

      .addCase(getSingleOrder.pending, (state) => {
        state.error = false;
      })
      .addCase(getSingleOrder.fulfilled, (state, action) => {
        state.singleOrder = action.payload.data;
      })
      .addCase(getSingleOrder.rejected, (state) => {
        state.error = true;
      })
      .addCase(getBid.pending, (state) => {
        state.error = false;
      })
      .addCase(getBid.fulfilled, (state, action) => {
        state.singleBid = action.payload.data;
      })
      .addCase(getBid.rejected, (state) => {
        state.error = true;
      })

      .addCase(getAllBids.pending, (state) => {
        state.error = false;
        state.bidListLoading = true;
      })
      .addCase(getAllBids.fulfilled, (state, action) => {
        state.bidsList = action.payload.data;
        state.total = action.payload.total;
        state.bidListLoading = false;
      })
      .addCase(getAllBids.rejected, (state) => {
        state.error = true;
      })

      .addCase(profitandloss.pending, (state) => {
        state.error = false;
      })
      .addCase(profitandloss.fulfilled, (state, action) => {
        state.profitandlossdata = action.payload.data;
      })
      .addCase(profitandloss.rejected, (state) => {
        state.error = true;
        state.bidListLoading = false;
      })

      .addCase(getExpressBid.pending, (state) => {
        state.error = false;
      })
      .addCase(getExpressBid.fulfilled, (state, action) => {
        state.expressData = action.payload.data;
      })
      .addCase(getExpressBid.rejected, (state) => {
        state.error = true;
      })

      .addCase(getCountries.pending, (state) => {
        state.error = false;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countries = action.payload.data;
      })
      .addCase(getCountries.rejected, (state) => {
        state.error = true;
      });
  },
});
export default p2pOrderSlice.reducer;
export const {
  updateLimitPrice,
  setMyAddFilter,
  clickOnViewOrderHis,
  getPageLocation,
  emptyOrderList,
  initBlankSingleBid,
  partialPaymentMethod,
} = p2pOrderSlice.actions;
