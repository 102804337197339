import { Modal } from "antd";

const CommonModal = (props) => {
  const { width, className, isOpen, onCancel, children , ...rest} = props;
  return (
    <Modal
    {...rest}
      className={`headerModals ${className}`}
      width={width ?? 450}
      centered
      visible={isOpen}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
