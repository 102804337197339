import React from "react";
import Turnstile from "react-turnstile";
import { cloudCaptchaSiteKey } from "../../api";
import { useSelector } from "react-redux";
export const CloudflareCaptcha = (props) => {

  const { REACT_APP_CAPTACH_KEY } = process.env
  return (
    <Turnstile
      theme="dark"
      className={`centerCaptcha ${props.className}`}
      sitekey={REACT_APP_CAPTACH_KEY}
      onVerify={(token) => {
        props.onChange(token);
      }}
      onLoad={(widgetId) => {
        // console.log("cloudflare captcha widgetId===>", widgetId);
      }}
      onExpire={(expire) => {
        // console.log("cloudflare captcha expire===>", expire);
      }}
      onError={(err) => {
        // console.log("cloudflare captcha err===>", err);
      }}
    />
  );
};
