import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../customHooks/customPagination";
import { getSecurityActivity } from "../../../redux/services/securityServie";
import { useDispatch, useSelector } from "react-redux";
import { dateToUtc, getBrowserName } from "../../../helpers";
import { NoRecord } from "../../NoRecord";

const SecurityActivity = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { secureActivityList, totalSecureActivity } = useSelector(
        (state) => state.security
    );

    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);

    const getActivity = (items) => {
        let str = "";

        switch (items?.topic) {
            case "session_delete":
                str = "Delete trusted device";
                break;

            case "otp":
                if (items?.action === "enable 2FA") {
                    str = "Enabled 2FA";
                } else if (items?.action === "disable 2FA") {
                    str = "Disabled 2FA";
                } else {
                    str = "Request QR code for 2FA";
                }
                break;

            default:
                str = "-"
                break;
        }

        return str;
    };

    useEffect(() => {
        dispatch(
            getSecurityActivity({
                limit: limit,
                page: pageNo,
                data: "session",
            })
        );
    }, [limit, pageNo]);

    const tableCol = [
        {
            dataIndex: "created_at",
            title: <p className="coins">{t("security_activity_page.date_time")}</p>,
        },
        {
            dataIndex: "source",
            title: <p className="coins">{t("security_activity_page.source")}</p>,
        },
        {
            dataIndex: "activity",
            title: <p className="coins">{t("security_activity_page.activity")}</p>,
        },
        {
            dataIndex: "status",
            title: <p className="coins">{t("security_activity_page.status")}</p>,
        },
        {
            dataIndex: "user_ip",
            title: <p className="coins">{t("security_activity_page.ip_address")}</p>,
        },
    ];

    const datax = () => {
        let newData = [];
        secureActivityList?.length > 0 &&
            secureActivityList?.map((items, idx) => {
                let temp = {
                    keyId: idx,
                    // "created_at": moment(items?.created_at).format("DD-MM-YYYY  HH:mm") || "-",
                    created_at: dateToUtc(items?.created_at, "DD/MM/YYYY  HH:mm") || "-",
                    source: getBrowserName(items?.user_agent, t) || "-",
                    activity: getActivity(items),
                    status:
                        items.result === "succeed"
                            ? "Completed"
                            : items.result === "failed"
                                ? "Failed"
                                : "-",
                    user_ip: items?.user_ip || "-",
                };

                newData.push(temp);
            });
        return newData;
    };

    return (
        <div className="securityInnerDiv">
            <div className="fundingtable securityArbic">
                <Table
                    rowKey={(obj) => obj.keyId}
                    columns={tableCol}
                    dataSource={datax()}
                    pagination={false}
                    className="portfolioTable cursorInitial"
                    locale={{ emptyText: <NoRecord /> }}
                />
                <CustomPagination
                    pageNo={pageNo}
                    limit={limit}
                    total={totalSecureActivity}
                    onChange={(pageNo, pageSize) => {
                        setPageNo(pageNo);
                        setLimit(pageSize);
                    }}
                />
            </div>
        </div>
    );
};

export default SecurityActivity;
