import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import * as config from "../../../utils/apiVersion";
import {
  reportedUserSuccessfully,
  startLoading,
  stopLoading,
} from "../../feature";
import { buildQueryString } from "../../../helpers";
import { toastSelector } from "../../selector";
import { message } from "antd";

export const getUserProfile = createAsyncThunk(
  "userInfo/getUserProfile",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/user_profile/user_details`;
      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      return rejectWithValue(e);
    }
  }
);

export const getBidUserProfile = createAsyncThunk(
  "userInfo/getBidUserProfile",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      const res = await API.get(config.p2p)(
        `/market/user_profile/bid_user_details?${buildQueryString(payload)}`
      );
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      if (e.code !== 401) {
        dispatch(toastSelector(e, "error"));
      }
      return rejectWithValue(e);
    }
  }
);

export const reportUser = createAsyncThunk(
  "userInfo/reportUser",
  async ({ formData, t }, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/user_profile/report_user`;
      let res = await API.post(config.p2p)(url, formData);
      dispatch(stopLoading());
      dispatch(toastSelector({ message: t("services.profile.reported_succ") }, "success"));
      dispatch(
        reportedUserSuccessfully({ reportStatus: true, reportError: false })
      );
      return { status: true, adId: res.id };
    } catch (e) {
      dispatch(
        reportedUserSuccessfully({ reportStatus: false, reportError: true })
      );
      dispatch(stopLoading());
      message.error(e.message);
      return false;
    }
  }
);
export const blockUserPro = createAsyncThunk(
  "userInfo/blockUserPro",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      let url = `/market/user_profile/block_user`;
      const res = await API.post(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(toastSelector({ message: t("services.profile.blocked") }, "success"));
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getBlockedUsers = createAsyncThunk(
  "userInfo/getBlockedUsers",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/user_profile/blocked_users?${buildQueryString(
        payload
      )}`;
      const { data, headers } = await API.get(config.p2pWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, total: headers?.total };
    } catch (e) {
      dispatch(stopLoading());
      if (e.code !== 401) {
        dispatch(toastSelector(e, "error"));
      }
      return rejectWithValue(e);
    }
  }
);

export const unBlockUsers = createAsyncThunk(
  "advertisement/unBlockUsers",
  async (payload, { dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      let url = `/market/user_profile/unblock_user`;
      await API.delete(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(toastSelector({ message: t("services.profile.unblock") }, "success"));
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);
