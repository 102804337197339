import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { getUser } from "./user";

export const createResetPass = createAsyncThunk(
  "setting/createResetPass",
  async (payload, { dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `identity/users/password/generate_code`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message: t("services.email_sent"),
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const changePass = createAsyncThunk(
  "setting/createResetPass",
  async (payload, { dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `identity/users/password/confirm_code`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message: t("services.pass_change"),
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const verifyToken = createAsyncThunk(
  "setting/verifyToken",
  async (payload, { dispatch }) => {
    try {
      let url = `identity/users/password/link_expire`;
      await API.post(config.barong)(url, payload);
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const changePassword = createAsyncThunk(
  "setting/changePassword",
  async (payload, { dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `resource/users/password`;
      const res = await API.put(config.barong)(url, payload);
      dispatch(
        successToastSelector({
          message: t("services.pass_change"),
        })
      );
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getOtpCode = createAsyncThunk(
  "setting/getOtpCode",
  async ({ otpClicked, ...rest }, { dispatch }) => {
    const { t } = rest
    try {
      dispatch(startLoading());
      let url = `resource/users/password/otp_confirmation`;
      const res = await API.get(config.barong)(url, rest);
      dispatch(
        successToastSelector({
          message: `${t("services.otp")} ${otpClicked ? t("services.resent") : t("services.sent")} ${t("services.successfully")}`,
        })
      );
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const updateProfile = createAsyncThunk(
  "setting/updateProfile",
  async (payload, { dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `resource/profiles`;
      const res = await API.put(config.barong)(url, payload);
      dispatch(
        successToastSelector({
          message: t("services.updated_profile"),
        })
      );
      await dispatch(getUser());
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
