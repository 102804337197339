import { Input } from "antd";
import React from "react";
import "./index.scss";
function CustomTextarea(props) {
  const { TextArea } = Input;
  const { label, rows, onChange, value, max, placeholder, className, name, disable, showCount } =
    props;

  return (
    <div className="text_AreaCustom">
      {label && <label>{label}</label>}
      <TextArea
        name={name}
        rows={rows ? rows : 4}
        onChange={onChange}
        value={value}
        maxLength={max}
        disabled={disable}
        showCount={showCount}
        placeholder={placeholder}
        className={`customTxtArea ${className}`}
      />
    </div>
  );
}

export default CustomTextarea;
