import React, { useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";
import { Col, Radio, Row, Skeleton, Tabs, Tooltip } from "antd";
import GridDetailsCard from "../../../Common/GridDetailsCard/GridDetailsCard";
import TabPane from "antd/lib/tabs/TabPane";
import WorkingDetails from "./WorkingDetails";
import CompletedDetailsCard from "./CompletedDetailsCard";
import { useParams } from "react-router-dom";
import {
  cancelGridSetting,
  getCurrencies,
  getGridDetailOrderHistory,
  getGridDetailPendingOrder,
  getGridSettingDetail,
  getMarkets,
} from "../../../../redux/services";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateDuration,
  capitalize,
  truncateData,
  localeDateTime,
  uppercase,
  preciseData,
  lowercase,
} from "../../../../helpers";
import { gridDetailTabs, upDownPriceColor } from "../../../../staticObjects";
import CommonModal from "../../../CommonModal";
import CommonButton from "../../../Ui/button/CommonButton";
import { NoRecord } from "../../../NoRecord";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";

function GridDetails() {
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [durationMinutes, setDurationMinutes] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { currenciesList: currencies } = useSelector(
    (state) => state?.commonApiData
  );
  const [lastPriceChanged, setLastPriceChanged] = useState(
    t("workingdetails.positive")
  );
  const marketList = useSelector((state) => state?.exchange?.marketList);
  const [orderHistoryObject, setOrderHistoryObject] = useState({
    count: 0,
    data: [],
  });

  const { working, completed } = gridDetailTabs


  const {
    gridSettingDetail,
    gridSettingPendingOrders,
    gridSettingOrdersHistory,
    gridDetailsLoading,
  } = useSelector((state) => state?.exchange);
  const [selectedPane, setSelectedPane] = useState(t("griddetails.working"));

  useEffect(() => {
    if (Object.keys(gridSettingOrdersHistory).length > 0) {
      setOrderHistoryObject({
        count: gridSettingOrdersHistory?.count,
        data: gridSettingOrdersHistory?.result,
      });
    }
  }, [gridSettingOrdersHistory]);

  const totalSingleGridProfit = useMemo(() => {
    if (orderHistoryObject?.data.length > 0) {
      return orderHistoryObject?.data.reduce(
        (sum, order) => sum + parseFloat(order.single_grid_profit),
        0
      );
    }
  }, [orderHistoryObject]);

  const {
    id,
    mode,
    upper_band_limit,
    lower_band_limit,
    grid_interval,
    profit_per_grid,
    investment,
    strategy_start_price,
    initial_buy_quantity,
    status,
    sell_quantity_at_stop,
    strategy_stop_price,
    reserved_fee,
    created_at,
    total_profit,
    market_id,
    quantity_per_order,
    grid_profit,
    annualized_yield,
    current_balance,
    balance_at_stop,
    total_current_balance,
    end_time,
    total_balance_at_stop,
    quote_balance,
    base_balance,
  } = gridSettingDetail;


  const [isTrue, setIsTrue] = useState(true);
  const handleRadio = (e) => {
    setIsTrue(e.target.value);
  };

  const { up, down } = upDownPriceColor;
  const { marketTickers, kline } = useSelector((state) => state?.exchange);
  const [floatingPnl, setFloatingPnl] = useState({ value: 0, percent: 0 });
  const [prevVal, setPrevVal] = useState("");

  const defaultTicker = {
    low: 0,
    last: 0,
    high: 0,
    total_volume: 0,
    price_change_percent: "0.00%",
    total_volume_base_currency: 0,
  };

  const getTickerValue = (value) => {
    return marketTickers[market_id]?.ticker !== undefined
      ? (marketTickers[market_id]?.ticker || defaultTicker)[value]
      : (marketTickers[market_id] || defaultTicker)[value];
  };

  const getPricePercision = (markets, market_id) => {
    let market = markets.find((item) => item.id === market_id);
    if (market !== undefined) return market.price_precision;
  };

  useEffect(() => {
    let change = "";
    if (market_id !== undefined) {
      let currLastVal = market_id && marketTickers[market_id]?.last;
      if (currLastVal < prevVal) {
        change = "negative";
        setLastPriceChanged(t("workingdetails.negative"));
      } else if (currLastVal > prevVal) {
        change = "positive";
        setLastPriceChanged(t("workingdetails.positive"));
      } else {
        change = "stable";
        setLastPriceChanged(t("workingdetails.stable"));
      }

      setPrevVal(currLastVal);
    }
  }, [marketTickers]);

  useEffect(() => {
    // Function to calculate the duration in minutes
    const updateDuration = () => {
      const now = moment();
      const createdMoment = moment(created_at);
      const durationMinutes = now.diff(createdMoment, "minutes"); // Calculate the difference in minutes
      setDurationMinutes(durationMinutes);
    };

    // Update the duration immediately and then every minute
    updateDuration();
    const interval = setInterval(updateDuration, 60000); // Update every 60 seconds (1 minute)

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [created_at]);

  const last = getTickerValue("last")

  useEffect(() => {
    if (params.id) {
      dispatch(getMarkets());
      dispatch(getCurrencies());
    }
  }, []);

  useEffect(() => {
    if (!gridSettingDetail?.id) {
      dispatch(
        getGridSettingDetail({
          id: params.id,
          loaderShow: true,
        })
      );
    }
  }, []);

  const rangeColor = useMemo(() => {
    if (last > upper_band_limit) {
      return up;
    } else if (last < lower_band_limit) {
      return down;
    } else {
      return "";
    }
  }, [last]);

  useEffect(() => {
    if (gridSettingDetail?.id) {
      let data = {
        grid_setting_id: gridSettingDetail?.id,
      };
      dispatch(getGridDetailPendingOrder(data));
      dispatch(getGridDetailOrderHistory(data));
    }
  }, [gridSettingDetail?.id]);

  useEffect(() => {
    const calculateFloatingPnL = () => {
      const totalBuyPrice = gridSettingPendingOrders
        .filter((order) => order.side === "buy")
        .reduce((sum, order) => sum + parseFloat(order.price), 0);

      const totalSellOrders = gridSettingPendingOrders.filter(
        (order) => order.side === "sell"
      ).length;
      const {
        investment,
        quantity_per_order,
        base_reserved_fee,
        quote_reserved_fee,
      } = gridSettingDetail;

      const floatingPnl =
        totalBuyPrice * quantity_per_order +
        totalSellOrders * parseFloat(quantity_per_order) * last +
        parseFloat(base_reserved_fee) * last +
        parseFloat(quote_reserved_fee) -
        parseFloat(investment);
      const percent = (floatingPnl / parseFloat(investment)) * 100;

      setFloatingPnl({
        value: truncateData(floatingPnl, 2),
        percent: truncateData(percent, 2),
      });
    };

    calculateFloatingPnL();
  }, [last, gridSettingPendingOrders, gridSettingDetail]);

  function changeOrderTabPane(value) {
    setSelectedPane(value);
  }

  const getMarketData = (market_id, type) => {
    let market = marketList?.find((item) => item?.id === market_id);

    switch (type) {
      case "name":
        return market?.name || "-";
      case "quote_unit":
        return uppercase(market?.quote_unit) || "-";
      case "base_unit":
        return uppercase(market?.base_unit) || "-";
      case "quote_icon":
        return (
          (currencies &&
            currencies.find((item) => item.id === market?.quote_unit)
              ?.icon_url) ||
          ""
        );
      default:
        return "";
    }
  };

  const gridDetailObj = useMemo(() => {
    return {
      quote_unit: getMarketData(market_id, "quote_unit"),
      base_unit: getMarketData(market_id, "base_unit"),
      quote_icon: getMarketData(market_id, "quote_icon"),
      name: getMarketData(market_id, "name"),
    };
  }, [gridSettingDetail, marketList, currencies]);

  const { quote_icon, quote_unit, name, base_unit } = gridDetailObj;

  const gridDetailsColumn1 = [
    {
      key: t("griddetails.mode"),
      value: capitalize(mode ? mode : "-"),
    },
    {
      key: t("griddetails.price_range"),
      value: (
        <>
          <span style={{ color: rangeColor }}>
            {`${lower_band_limit} ${quote_unit}` || "0.00"} -{" "}
            {`${upper_band_limit} ${quote_unit}` || "0.00"}{" "}
          </span>
          {rangeColor && (
            <Tooltip
              overlayClassName="toolCustom_wdt"
              autoAdjustOverflow={false}
              placement="topRight"
              title={
                rangeColor == "#1EA0AB"
                  ? t("griddetails.high_tip")
                  : t("griddetails.low_tip")
              }
            >
              <span
                className={rangeColor == "#1EA0AB" ? "green_icn" : "red_icn"}
                style={{ cursor: "pointer" }}
              >
                <ExclamationCircleFilled />
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      key: t("griddetails.number_grid"),
      value: grid_interval || "-",
    },
    {
      key: t("griddetails.profit_per_grid"),
      value: profit_per_grid || "-",
    },
    {
      key: t("griddetails.initial_investment"),
      underLine: true,
      value: investment ? `${investment} ${quote_unit}` : "-",
    },
    {
      key: t("griddetails.trading_bot"),
      value: `${strategy_start_price} ${quote_unit}` || "-",
    },
  ];

  const isActive = status === "active" || status === "errored";



  const gridDetailColumn2 = [
    {
      key: t("griddetails.initial_buy"),
      value: initial_buy_quantity
        ? `${initial_buy_quantity} ${base_unit}`
        : "-",
    },
    {
      key: t("griddetails.initial_entry"),
      value: `${strategy_start_price} ${quote_unit}` || "-",
    },
    {
      key: t("griddetails.invest_earn"),
      value: quote_unit,
    },
    {
      key: t("griddetails.status"),
      value: isActive ? ` Working` : "Cancelled",
      dot: isActive,
    },

    {
      key: t("griddetails.strategy_number"),
      underLine: true,
      value: id,
    },
  ];

  if (!isActive) {
    gridDetailColumn2.splice(0, 0, {
      key: t("griddetails.sell_qty"),
      underLine: true,
      value: sell_quantity_at_stop,
    });

    gridDetailColumn2.splice(1, 0, {
      key: t("griddetails.avg_sell"),
      underLine: true,
      value: `${gridSettingDetail?.sell_quantity_at_stop
        ? gridSettingDetail?.strategy_stop_price
        : "-"
        }`,
    });

    gridDetailColumn2.splice(3, 0, {
      key: t("griddetails.strategy_stop_price"),
      underLine: true,
      value: strategy_stop_price,
    });
  }

  if (isActive) {
    gridDetailColumn2.splice(3, 0, {
      key: t("griddetails.reserved_fee"),
      underLine: true,
      value: `${reserved_fee}`,
    });
  }

  const gridDetailsColumn3 = [
    {
      key: (
        <span className="advancedOption">
          {t("griddetails.advance_optional")}
        </span>
      ),
    },

    {
      key: t("griddetails.sell_all"),
      underLine: true,
      value: gridSettingDetail?.sell_at_stop ? "Enabled" : "Disabled",
    },
  ];

  const duration = isActive
    ? calculateDuration(created_at)
    : calculateDuration(created_at, end_time);

  const handleEnd = async () => {
    const data = {
      id: id,
      status: "cancelled",
      t,
    };

    if (!gridSettingDetail?.sell_at_stop) {
      data["sell_at_stop"] = isTrue;
    }
    const res = await dispatch(cancelGridSetting(data));

    if (res.payload.status) {
      dispatch(
        getGridSettingDetail({
          id: params.id,
          loaderShow: true,
        })
      );
      setOpenCancelModal(false);
    }
  };

  const getAmountColour = (val) => {
    switch (Math.sign(val)) {
      case 1:
        return up;
      case -1:
        return down;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (isActive) {
      setSelectedPane(t("griddetails.working"));
    } else {
      setSelectedPane(t("griddetails.completed"));
    }
  }, [isActive]);

  const renderComp = (child) => {
    if (gridDetailsLoading) {
      return <Skeleton round active />;
    } else {
      return child;
    }
  };

  if (!Object.keys(gridSettingDetail).length && !gridDetailsLoading) {
    return (
      <div className={style.port}>
        <NoRecord text={t("griddetails.no_record")} />
      </div>
    );
  }

  const priceFixed = getPricePercision(
    marketList,
    lowercase(base_unit + quote_unit)
  );

  const currBal =
    investment && preciseData(quote_balance + (base_balance * last), priceFixed);

  const totalProInPer = currBal
    ? preciseData(((currBal - investment) / investment) * 100, priceFixed)
    : total_profit?.percent;
  const totalProfit = currBal
    ? preciseData(currBal - investment, priceFixed)
    : "--";
  const annualizedYield =
    currBal && durationMinutes !== 0
      ? preciseData(
        ((currBal - investment) / investment * 365 * 24 * 60 /
          durationMinutes),
        2
      )
      : "--";

  const durationString = () => {
    const parts = [];
    if (duration?.years) parts.push(`${duration.years}Y`);
    if (duration?.months) parts.push(`${duration.months}M`);
    if (duration?.days) parts.push(`${duration.days}d`);
    if (duration?.hours) parts.push(`${duration.hours}hr`);
    if (duration?.minutes) parts.push(`${duration.minutes || 0}m`);

    return parts.join(" ");
  };

  return (
    <div className={style.port}>
      <div className={style.details}>
        <div className={style.details__blueBg}>
          <div className={style.details__blueBg__left}>
            <img src={quote_icon} alt="" />
            <h3>{name}</h3>
            <button>{t("griddetails.stop_grid")}</button>
          </div>
          <div className={style.details__blueBg__right}>
            <div>
              <p>
                {t("griddetails.duration")}: {durationString() || "0m"}
              </p>
              <p>
                {t("griddetails.time_created")}: {localeDateTime(created_at)}
              </p>
              <p>
                {!isActive &&
                  `${t("griddetails.time_ended")}: ${localeDateTime(end_time)}`}
              </p>
            </div>
            {isActive && (
              <button onClick={() => setOpenCancelModal(true)}>
                {t("griddetails.end")}
              </button>
            )}
          </div>
        </div>
        <div className={`details__chart ${style.details__chart}`}>
          <div className={style.details__chart__left}>
            <table>
              <tbody>
                <tr>
                  <td className="underLine">
                    {t("griddetails.total_investment")}
                  </td>
                  <td>
                    <p>
                      <h6 className="underLine">
                        {t("griddetails.total_profit")}
                      </h6>{" "}
                      <span
                        className="tpp"
                        style={{
                          color: getAmountColour(total_profit?.percent),
                        }}
                      >
                        {status === "cancelled"
                          ? total_profit?.percent !== undefined
                            ? `${total_profit.percent}%`
                            : "-"
                          : totalProInPer !== undefined
                            ? `${totalProInPer}%`
                            : "--"}
                      </span>
                    </p>
                  </td>

                  <td>
                    <p>
                      <h6 className="underLine">
                        {t("griddetails.grid_profit")}{" "}
                      </h6>
                      <span
                        style={{
                          color: getAmountColour(grid_profit?.percent),
                        }}
                      >
                        {grid_profit?.percent && `${grid_profit?.percent}%`}
                      </span>
                    </p>
                  </td>

                  <td>
                    <p>
                      <h6 className="underLine">
                        {t("griddetails.annualized")}{" "}
                      </h6>
                      {/* <span
                        style={{
                          color: getAmountColour(annualized_yield?.percent),
                        }}
                      >
                        {annualized_yield?.percent &&
                          `${annualized_yield?.percent}%`}
                      </span> */}
                    </p>
                  </td>

                  <td className="underLine">
                    {isActive
                      ? t("griddetails.current_balance")
                      : t("griddetails.balance_at_stop")}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{investment}</strong> {quote_unit}
                  </td>
                  <td>
                    <span
                      className="aa"
                      style={{
                        color: getAmountColour(total_profit?.value),
                      }}
                    >
                      {status !== "cancelled" &&
                        totalProfit !== undefined &&
                        totalProfit !== "--"
                        ? ` ${totalProfit} ${quote_unit}`
                        : `${total_profit?.value !== undefined
                          ? total_profit.value
                          : "--"
                        } ${quote_unit}`}
                    </span>
                  </td>

                  <td>
                    <strong
                      style={{
                        color: getAmountColour(totalSingleGridProfit),
                      }}
                      className="gp"
                    >
                      {status === "cancelled"
                        ? grid_profit?.value !== undefined &&
                          grid_profit?.value !== null
                          ? `${grid_profit.value || "--"} ${grid_profit.value ? quote_unit : ""
                          }`
                          : "--"
                        : totalSingleGridProfit !== undefined
                          ? `${preciseData(totalSingleGridProfit, priceFixed) ||
                          "--"
                          } ${totalSingleGridProfit ? quote_unit : ""}`
                          : "--"}
                    </strong>
                  </td>
                  {/* <td>
                    <strong
                      style={{
                        color: getAmountColour(floatingPnl?.value),
                      }}
                      className="flp"
                    >
                      {floatingPnl.value
                        ? `${floatingPnl.value} ${quote_unit}`
                        : "--"}{" "}
                    </strong>
                  </td> */}
                  <td>
                    <strong
                      style={{
                        color: getAmountColour(annualized_yield?.value),
                      }}
                      className="az"
                    >
                      {status === "cancelled"
                        ? annualized_yield?.value !== undefined
                          ? `${annualized_yield.value || "--"}${annualized_yield.value ? "%" : ""
                          }`
                          : "-"
                        : annualizedYield !== undefined
                          ? `${annualizedYield || "--"} ${annualizedYield !== "--" ? "%" : ""
                          }`
                          : "-"}
                    </strong>
                    {/* {annualized_yield && quote_unit} */}
                  </td>
                  <td>
                    <strong className="ss">
                      {isActive
                        ? `${quote_balance} ${quote_unit}` +
                        " + " +
                        `${base_balance} ${base_unit}`
                        : balance_at_stop || "--"}
                    </strong>
                    <br />
                    {currBal ? "≈" : ""}
                    {isActive ? currBal : total_balance_at_stop || "--"}{" "}
                    {quote_unit}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className={style.details__chart__right}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <h6 className="underLine">
                        {t("griddetails.grid_profit")}{" "}
                      </h6>
                      <span
                        style={{
                          color: getAmountColour(grid_profit?.percent),
                        }}
                      >
                        {grid_profit?.percent && `${grid_profit?.percent}%`}
                      </span>
                    </p>
                  </td>

                  <td>
                    <p>
                      <h6 className="underLine">
                        {t("griddetails.floating_pnl")}{" "}
                      </h6>
                      <span
                        style={{
                          color: getAmountColour(floatingPnl?.percent),
                        }}
                      >
                        {floatingPnl?.percent
                          ? `${floatingPnl?.percent}%`
                          : "--"}
                      </span>
                    </p>
                  </td>

                  <td>
                    <p>
                      <h6 className="underLine">
                        {t("griddetails.annualized")}{" "}
                      </h6>
                      <span
                        style={{
                          color: getAmountColour(annualized_yield?.percent),
                        }}
                      >
                        {annualized_yield?.percent &&
                          `${annualized_yield?.percent}%`}
                      </span>
                    </p>
                  </td>

                  <td className="underLine">
                    {isActive
                      ? t("griddetails.current_balance")
                      : t("griddetails.balance_at_stop")}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong
                      style={{
                        color: getAmountColour(totalSingleGridProfit),
                      }}
                      className="gp"
                    >
                      {
                        status === "cancelled"
                          ? (
                            grid_profit?.value !== undefined && grid_profit?.value !== null
                              ? `${grid_profit.value || "--"} ${grid_profit.value ? quote_unit : ""}`
                              : "--"
                          )
                          : (
                            totalSingleGridProfit !== undefined
                              ? `${totalSingleGridProfit || "--"} ${totalSingleGridProfit ? quote_unit : ""}`
                              : "--"
                          )
                      }

                    </strong>
                  </td>
                  <td>
                    <strong
                      style={{
                        color: getAmountColour(floatingPnl?.value),
                      }}
                      className="flp"
                    >
                      {floatingPnl.value
                        ? `${floatingPnl.value} ${quote_unit}`
                        : "--"}{" "}
                    </strong>
                  </td>
                  <td>
                    <strong
                      style={{
                        color: getAmountColour(annualized_yield?.value),
                      }}
                      className="az"
                    >
                      {console.log(annualized_yield.value, "dsfdsfdsfdsfds")}
                      {status === "cancelled"
                        ? (annualized_yield?.value !== undefined
                          ? `${annualized_yield.value || "--"} ${annualized_yield.value ? "%" : ""}`
                          : "-"
                        )
                        : (annualizedYield !== undefined
                          ? `${annualizedYield || "--"} ${annualizedYield !== "--" ? "%" : ""}`
                          : "-"
                        )
                      }

                    </strong>
                    {annualized_yield && quote_unit}
                  </td>
                  <td>
                    <strong className="ss">
                      {isActive
                        ? `${quote_balance} ${quote_unit}` +
                        " + " +
                        `${base_balance} ${base_unit}`
                        : balance_at_stop || "--"}
                    </strong>
                    <br />
                    {currBal ? "≈" : ""}
                    {isActive ? currBal : total_balance_at_stop || "--"}{" "}
                    {quote_unit}
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
        <div className={style.details__gridDetails}>
          <div className={`${style.heading} headingport`}>
            <h4>{t("griddetails.grid_details")}</h4>
          </div>
          <Row gutter={[15, 15]}>
            <Col xl={8} lg={12} md={24} sm={24} xs={24}>
              <GridDetailsCard
                data={gridDetailsColumn1}
                renderComp={renderComp}
              />
            </Col>{" "}
            <Col xl={8} lg={12} md={24} sm={24} xs={24}>
              <GridDetailsCard
                data={gridDetailColumn2}
                renderComp={renderComp}
              />
            </Col>{" "}
            <Col xl={8} lg={12} md={24} sm={24} xs={24}>
              <GridDetailsCard
                data={gridDetailsColumn3}
                renderComp={renderComp}
              />
            </Col>
          </Row>
        </div>
        <div className={style.details__gridDetails}>
          <div className={`${style.heading} headingport`}>
            <h4>
              {isActive
                ? t("griddetails.ruunng_order_detail")
                : t("griddetails.order_detail")}
            </h4>
          </div>
          <div className={style.details__running}>
            <Tabs
              className="runningOrder"
              activeKey={selectedPane}
              defaultActiveKey={selectedPane}
              onChange={(value) => changeOrderTabPane(value)}
            >
              {isActive && (
                <TabPane
                  className="runningOrder_tabs"
                  tab={`${t("griddetails.working")} (${gridSettingPendingOrders.length
                    })`}
                  key={t("griddetails.working")}
                >
                  <WorkingDetails
                    market_id={market_id}
                    data={gridSettingPendingOrders}
                    quote_unit={quote_unit}
                    base_unit={base_unit}
                    quantity_per_order={quantity_per_order}
                  />
                </TabPane>
              )}
              <TabPane
                tab={`${t("griddetails.completed")} (${orderHistoryObject?.count
                  }) `}
                key={t("griddetails.completed")}
              >
                <CompletedDetailsCard
                  data={orderHistoryObject?.data}
                  quote_unit={quote_unit}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      <CommonModal
        centered
        isOpen={openCancelModal}
        onCancel={() => setOpenCancelModal(false)}
        className="buySellModal"
      >
        <div className={style.cancelModal}>
          <div className={style.cancelModal__inner}>
            <h1>{t("griddetails.grid_end")}</h1>
            <p>
              {`${t("griddetails.are_you_sure")} ${name} ${t(
                "griddetails.grid"
              )}?`}
              <span
                style={{ display: "block" }}
                className={style.cancelModal__inner__note}
              >
                {t("griddetails.desc")} <b>{t("griddetails.72hr")}.</b>)
              </span>
            </p>

            <br />
            {gridSettingDetail?.sell_at_stop ? (
              <p>
                {`${t("griddetails.all")} ${base_unit} ${t(
                  "griddetails.off_this"
                )}.`}
              </p>
            ) : (
              <>
                <p>{`${t("griddetails.would_you")} ${base_unit} ${t(
                  "griddetails.into"
                )} ${quote_unit}?`}</p>

                <Radio.Group onChange={handleRadio} value={isTrue}>
                  <Radio value={true}>
                    <p>{t("griddetails.yes")}</p>
                  </Radio>
                  <Radio value={false}>
                    <p>{t("griddetails.no")}</p>
                  </Radio>
                </Radio.Group>
              </>
            )}
            <hr />
            <span className={style.cancelModal__inner__note}>
              {t("griddetails.all_open")}
            </span>
            <div className={style.cancelModal__inner__btn}>
              <CommonButton
                btn_custom
                themegraybtn
                title={t("buttons.cancel")}
                onClick={() => setOpenCancelModal(false)}
              ></CommonButton>
              <CommonButton
                btn_custom
                title={t("buttons.confirm")}
                onClick={() => handleEnd()}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
}

export default GridDetails;
