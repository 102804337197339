import { createSlice } from "@reduxjs/toolkit";
import {
  getConversionValue,
  getOneUnitPrice,
} from "../../../services/P2P/conversionService";

export const conversionSlice = createSlice({
  name: "conversion",
  initialState: {
    oneUnitPrice: "0",
    converisonValue: "0",
    error: false,
  },
  reducers: {
    initResetUnitPrice: (state) => {
      state.oneUnitPrice = "0";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneUnitPrice.pending, (state) => {
        state.error = false;
      })
      .addCase(getOneUnitPrice.fulfilled, (state, action) => {
        state.oneUnitPrice = action.payload;
      })
      .addCase(getOneUnitPrice.rejected, (state) => {
        state.error = true;
      })
      .addCase(getConversionValue.pending, (state) => {
        state.error = false;
      })
      .addCase(getConversionValue.fulfilled, (state, action) => {
        state.converisonValue = action.payload;
      })
      .addCase(getConversionValue.rejected, (state) => {
        state.error = true;
      });
  },
});

export default conversionSlice.reducer;
export const { initResetUnitPrice } = conversionSlice.actions;
