export const periodsMapString = {
  1: "1m",
  5: "5m",
  15: "15m",
  30: "30m",
  60: "1h",
  120: "2h",
  240: "4h",
  360: "6h",
  720: "12h",
  1440: "1d",
  4320: "3d",
  10080: "1w",
};

export const generateSocketURI = (baseUrl, s) =>
  `${baseUrl}/?stream=${s.sort().join("&stream=")}`;

export const streamsBuilder = (marketId, period = "15m", uid) => {
  if (marketId !== "" && uid) {
    return [
      `global.tickers`,
      `${marketId}.trades`,
      `${marketId}.update`,
      streamsBuilderKline(marketId, period),
      "gateway.deposit",
      "order",
      "trade",
      "message",
      "advertisement",
      "appeal",
      "bid",
      "chat",
      "admin_action",
      `watchlist`,
      `grid_data`,
      "follower",
      "Announcement",
      "currency",
      "profile",
      "copy_order",
      "Alert",
      "position",
      "copy_position"
    ];
  }
  else if (marketId !== "" && !uid) {
    return [
      "global.tickers",
      `${marketId}.trades`,
      `${marketId}.update`,
      streamsBuilderKline(marketId, period),
      // "trade",
      // "order"
    ]
  }
  else if (marketId === "" && uid) {
    return [
      `global.tickers`,
      `${marketId}.trades`,
      `${marketId}.update`,
      streamsBuilderKline(marketId, period),
      "gateway.deposit",
      "order",
      "trade",
      "message",
      "advertisement",
      "appeal",
      "bid",
      "chat",
      "admin_action",
      `watchlist`,
      "follower",
      "Announcement",
      "currency",
      "profile",
      "grid_data",
      "copy_order",
      "Alert",
      "position",
      "copy_position"    ];
  }
  else {
    return [
      "global.tickers",
    ];
  }
};

export const streamsBuilderKline = (marketId, period) => {
  return `${marketId}.kline-${period}`;
};

export const periodMinutesToString = (period) => periodsMapString[period];

export const klineArrayToObject = (el) => {
  const [time, open, high, low, close, volume] = el.map((e) => {
    switch (typeof e) {
      case "number":
        return e;

      case "string":
        return Number.parseFloat(e);

      default:
        throw new Error(`unexpected type ${typeof e} in kline: ${el}`);
    }
  });

  return {
    time: time * 1e3,
    open,
    high,
    isBarClosed: false,
    isLastBar: true,
    low,
    close,
    volume,
  };
};
