import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { setEnableUserData, startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { getUser, updateUserExtraData } from "./user";

export const getIdentity = createAsyncThunk(
  "login/getIdentity",
  async (payload, { dispatch }) => {
    let { email, session_type, phone_number } = payload;

    try {
      dispatch(startLoading());
      let url = `identity`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      if (
        e.message[0] === "identity.session.not_active" ||
        e.message[0] === "identity.session.disabled" ||
        e.message[0] === "identity.session.deactivated"
      ) {
        dispatch(setEnableUserData({ email, session_type, phone_number }));
      }

      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getOtpService = createAsyncThunk(
  "login/getOtpService",
  async ({ otpClicked, ...rest }, { dispatch }) => {
    const { t } = rest;
    try {
      let url = `identity/sessions/resent_otp`;
      await API.post(config.barong)(url, rest);
      dispatch(
        successToastSelector({
          message: `${t("services.otp")} ${
            otpClicked ? t("services.resent") : t("services.sent")
          }  ${t("services.successfully")}`,
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const createIdentitySession = createAsyncThunk(
  "login/createIdentitySession",
  async (payload, { dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `identity/sessions`;
      let res = await API.post(config.barong)(url, payload);
      localStorage.setItem("csrfToken", res.csrf_token);
      dispatch(successToastSelector({ message: t("services.login_succ") }));
      dispatch(stopLoading());
      dispatch(getUser());
      dispatch(
        updateUserExtraData({
          user_time_zone: payload?.userTimeZone,
        })
      );
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getReactivateOtp = createAsyncThunk(
  "login/getReactivateOtp",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/users/reactivation_otp`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { uid: res?.uid, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const reactivateAccount = createAsyncThunk(
  "login/reactivateAccount",
  async (payload, { dispatch }) => {
    let t = payload.t;

    delete payload.t;

    try {
      dispatch(startLoading());
      let url = `identity/users/reactivate`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(
        successToastSelector({ message: t("services.account_enabled") })
      );

      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
