import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import "antd/dist/antd.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./i18n";
import { Provider } from "react-redux";
import { store } from "./redux/configureStore";
import { BrowserRouter } from "react-router-dom";
import { InternetConnectionWraper } from "./Components/internetConnectionWraper";

import { WagmiProvider, createConfig } from 'wagmi';
import { bsc, bscTestnet, goerli, mainnet, sepolia } from 'wagmi/chains';
import { http, injected } from "@wagmi/core";
import { coinbaseWallet, safe, walletConnect } from '@wagmi/connectors'
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

const projectId = '0df0634d02709b772e1ef3d733e0b115';

const metadata = {
  name: 'Future Exchange',
  description: 'Connect to DAPP to release the power of blockchain.',
  url: 'https://www.futureexchange.io/',
  icons: ['https://i.ibb.co/pb5Z7Yc/loginlogo.png'],
};

const queryClient = new QueryClient()

export const wagmiConfig = createConfig({
  chains: [bsc, bscTestnet, goerli, mainnet, sepolia],
  connectors: [
    injected({ target: 'metaMask' }),
    coinbaseWallet(),
    safe(),
    walletConnect({ projectId, showQrModal: false, metadata }),
  ],
  transports: {
    [bsc.id]: http(),
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [goerli.id]: http(),
    [bscTestnet.id]: http()
  },
})

createWeb3Modal({
  metadata,
  projectId,
  wagmiConfig,
  enableAnalytics: false,
})

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {/* <InternetConnectionWraper> */}
          <ScrollToTop>
            <App />
          </ScrollToTop>
          {/* </InternetConnectionWraper> */}
        </BrowserRouter>
      </QueryClientProvider>
    </WagmiProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log); // or another reporting function
