import * as moment from 'moment-timezone';

import { getTimezone } from './timezone';

export const localeDateSec = (
  date,
  timezone = getTimezone(),
  format = 'DD/MM/YYYY HH:mm:ss'
) => {
  const isUnix = typeof date === 'number';

  const momentObj = isUnix ? moment.unix(date) : moment(date);

  return momentObj.tz(timezone).format(format);
};

export const localeDateTime = (
  date,
  timezone = getTimezone(),
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const isUnix = typeof date === 'number';

  const momentObj = isUnix ? moment.unix(date) : moment(date);

  return momentObj.tz(timezone).format(format);
};

export const localeTime = (
  date,
  timezone = getTimezone(),
  format = 'HH:mm:ss'
) => {
  const isUnix = typeof date === 'number';

  const momentObj = isUnix ? moment.unix(date) : moment(date);

  return momentObj.tz(timezone).format(format);
};

export const localeBinanceDateTime = (
  date,
  timezone = getTimezone(),
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  var date1 = new Date(date);

  return moment(date1).format('HH:mm:ss');
};

// export const dateToUtc = (date, format) => {
//   return moment.utc(date).format(format)
// }

export const dateToUtc = (
  date,
  format,
  timezone = getTimezone(),
) => {
  const isUnix = typeof date === 'number';

  const momentObj = isUnix ? moment.unix(date) : moment.utc(date);

  return momentObj.tz(timezone).format(format);
};

export const convertToLocalTimezone = (
  date,
  timezone = getTimezone(),
  format = "YYYY-DD-MM HH:mm:ss"
) => {
  const isUnix = typeof date === "number";

  const momentObj = isUnix ? moment.unix(date) : moment(date);

  return momentObj.tz(timezone).format(format);
};

export const localeFullDate = (
  date,
  timezone = getTimezone(),
  format = "YYYY-DD-MM HH:mm:ss"
) => {
  const isUnix = typeof date === "number";

  const momentObj = isUnix ? moment.unix(date) : moment(date);

  return momentObj.tz(timezone).format(format);
};

export const localeFullDateOnly = (
  date,
  timezone = getTimezone(),
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const isUnix = typeof date === "number";

  const momentObj = isUnix ? moment.unix(date) : moment(date);

  return momentObj.tz(timezone).format(format);
};