import { totalUSDType } from "../staticObjects";

// Parameters need to pass in sequence
export const calculateTotalUsdBal = (
  balanceList,
  type = totalUSDType.all,
  usingPriceList = false,
  priceList = {},
  precision = 2
) => {
  let totalUsdBls = 0;
  if (!Array.isArray(balanceList)) {
    console.error('balanceList is not an array or is invalid:', balanceList);
    balanceList = [];
  }
  for (const data of balanceList) {
    const balance = Number(data.balance);
    const locked = Number(data.locked);
    const escrow_balance = Number(data.escrow_balance);
    const p2p_balance = Number(data.p2p_balance);
    const usdtValue = Number(
      usingPriceList ? priceList[data.currency] : data.currency_usdt
    );
    const inOrderVal = locked + escrow_balance;

    let totalValue = 0;
    if (type === totalUSDType.all) {
      totalValue = (balance + p2p_balance + inOrderVal) * usdtValue;
    } else if (type === totalUSDType.spot || type === totalUSDType.grid || type === totalUSDType.copy) {
      totalValue = (balance + locked) * usdtValue;
    } else if (type === totalUSDType.funding) {
      totalValue = (p2p_balance + escrow_balance) * usdtValue;
    }
    totalUsdBls += totalValue;
  }
  return totalUsdBls.toFixed(precision);
};

export const calculateLocTotalUsdBal = (
  balanceList,
  type = totalUSDType.all,
  usingPriceList = false,
  priceList = {},
  precision = 2
) => {
  let totalLocUsdBls = 0;
  if (!Array.isArray(balanceList)) {
    balanceList = []; // Prevent the function from failing by assigning an empty array
  }
  for (const data of balanceList) {
    const locked = Number(data.locked);
    const escrow_balance = Number(data.escrow_balance);
    const usdtValue = Number(
      usingPriceList ? priceList[data.currency] : data.currency_usdt
    );
    let totalValue = 0;
    if (type === totalUSDType.spot || type === totalUSDType.grid || type === totalUSDType.copy) {
      totalValue = locked * usdtValue;
    } else if (type === totalUSDType.funding) {
      totalValue = escrow_balance * usdtValue;
    }
    totalLocUsdBls += totalValue;
  }
  return totalLocUsdBls.toFixed(precision);
};
