import React from "react";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import style from "./style.module.scss";
import { Tooltip } from "antd";

const Points = ({
  Heading,
  Content,
  color,
  detail,
  greentext,
  greenColor,
  greyColor,
  tooltip,
}) => {
  const { exclamination } = StoreImages;
  return (
    <>
      <div className={style.point}>
        <h6>
          {Heading}

          <Tooltip color="black" placement="topLeft" title={tooltip}>
            <img src={exclamination} alt="icon" />
          </Tooltip>
        </h6>
        <p className={style.point_mainText}>
          <span className={greenColor}>{Content}</span>
          {greentext && <span className={greyColor}>{greentext}</span>}
        </p>
        {detail && <p className={color}>{detail}</p>}
      </div>
    </>
  );
};

export default Points;
