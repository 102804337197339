import { API } from "../../api";
import { startLoading, stopLoading } from "../feature";
import * as config from "../../utils/apiVersion";
import { errorToastSelector, successToastSelector } from "../selector";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryString } from "../../helpers";

export const createPriceAlert = createAsyncThunk(
  "priceAlert/createPriceAlert",
  async (payload, { dispatch }) => {
    const t = payload?.t;

    delete payload?.t;
    try {
      dispatch(startLoading());
      let url = `account/alerts`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());

      dispatch(
        successToastSelector({
          message: t("price_alert.price_alert_success"),
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getPriceAlertList = createAsyncThunk(
  "priceAlert/getPriceAlertList",
  async (payload, { dispatch }) => {
    try {
      let url = "account/alerts/all";
      dispatch(startLoading());
      const { data, headers } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, total: headers.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getSingleMarketPriceAlert = createAsyncThunk(
  "priceAlert/getSingleMarketPriceAlert",
  async (payload, { dispatch }) => {
    try {
      let url = `/account/alerts?${buildQueryString(payload)}`;
      dispatch(startLoading());
      const { data, headers } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, total: headers.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const deletePriceAlert = createAsyncThunk(
  "priceAlert/deletePriceAlert",
  async (payload, { rejectWithValue, dispatch }) => {
    const t = payload?.t;

    delete payload?.t;
    try {
      dispatch(startLoading(true));
      let url = `/account/alerts`;
      let res = await API.delete(config.peatio)(url, payload);
      dispatch(stopLoading(false));

      dispatch(
        successToastSelector({
          message: t("price_alert.delete_alert_success"),
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);
