import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { setConvertedAmount, startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";


export const getrewardData = createAsyncThunk(
  "earn/getrewardData",
  async (payload, { dispatch }) => {
    try {
      let url = 'account/reward/reward_data'
      dispatch(startLoading());
      const { data } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { data, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);


export const getParentUid = createAsyncThunk(
  "earn/getParentUid",
  async (payload, { dispatch }) => {
    try {
      let url = 'account/parent_members'
      dispatch(startLoading());
      const { data } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { data: data?.id, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);


export const getrewardHistory = createAsyncThunk(
  "earn/getrewardHistory",
  async (payload, { dispatch }) => {
    try {
      let url = payload
        ? `/account/reward?${buildQueryString(payload)}`
        : "/account/reward";
      dispatch(startLoading());
      const { data, headers } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, status: true, total: headers?.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getreferredMembers = createAsyncThunk(
  "earn/getreferredMembers",
  async (payload, { dispatch }) => {
    try {
      let url = payload
        ? `/account/referred_members?${buildQueryString(payload)}`
        : "/account/referred_members";

      dispatch(startLoading());
      const { data, headers } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, total: headers?.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const getredeemReward = createAsyncThunk(
  "earn/getredeemReward",
  async (payload, { dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      let url = `account/redeem_reward`;
      dispatch(startLoading());
      const data = await API.post(config.peatio)(url, payload);
      dispatch(successToastSelector({ message: t("services.redeem") }));
      dispatch(getrewardData());
      dispatch(stopLoading());
      return { list: data, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
// export const getrrewardSettings = createAsyncThunk(
//   "earn/getrrewardSettings",
//   async (payload, { dispatch }) => {
//     try {
//       let url = `account/reward_setting`;
//       dispatch(startLoading());
//       const data = await API.get(config.peatio)(url);
//       dispatch(stopLoading());
//       return { list: data, status: true };
//     } catch (e) {
//       dispatch(stopLoading());
//       dispatch(errorToastSelector(e));
//       return false;
//     }
//   }
// );


export const rebatePercentage = createAsyncThunk(
  "login/rebatePercentage",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/update_rebate_percentage`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const shareWithParent = createAsyncThunk(
  "login/shareWithParent",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/reward`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(successToastSelector({ message: "Reward  successfully share to parent spot balance" }));
      dispatch(stopLoading());
      dispatch(setConvertedAmount())
      dispatch(getrewardData());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);