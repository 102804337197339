import CoinDetail from "../Components/Pages/CoinDetail/CoinDetail.jsx";
import {
  Dashboard,
  Login,
  ChangePassword,
  ResetPassword,
  CreateAcount,
  VerifyEmail,
  Identity,
  Portfolio,
  GridTrading,
  CopyTrading,
  VerifyIdentity,
  Setting,
  Order,
  EmailConfirmation,
  Notifications,
  Landing,
  AdressManagement,
  Kyc,
  Referral,
  ReferralViewAll,
  Swap,
  MyCopyTrading,
  TraderDetail,
  TradingParameter,
  CopyBotSetting,
  Announcement,
  Contact,
  PriceAlert,
  Privacy,
  Listing,
  LaunchpadViewAll,
  EnableUser,
  Orders,
  Buysellorderinfo,
  Progressstep,
  EditAdv,
  Paymentmethod,
  ProfileCompletion,
  Progresslast,
  PaymentSteps,
  Appeal,
  AppealHistory,
  P2P,
} from "./lazyRoute";
import {
  sidebarRouteObj,
  publicRouteObj,
  primaryRoutes,
} from "../staticObjects/routing";

import Transactions from "../Components/Pages/Transactions/index.jsx";
import AccountActivity from "../Components/Pages/Setting/AccountActivity.jsx";
import DeviceManagement from "../Components/Pages/Setting/DeviceManagement.jsx";
import GridDetail from "../Components/Pages/GridTrading/Details/GridDetails.jsx";
// Public routing array

const {
  login,
  changePassword,
  resetPassword,
  createAccount,
  emailVerify,
  emailConfirmation,
  identity,
  verifyIdentity,
  contactPublic,
  privacy,
  launchpadViewAll,
  reactiveUser
} = publicRouteObj;

// Private routing array

const { home, port, gridtrade, copytrade, transactions, referral, swap } =
  sidebarRouteObj;
const {
  overview,
  setting,
  order,
  notfication,
  addressManagement,
  authLanding,
  kyc,
  deviceManagement,
  accountActivity,
  referralViewAll,
  myCopyTrade,
  traderDetail,
  tradingParameter,
  copybotdetail,
  announcement,
  contactPrivate,
  priceAlert,
  p2p,
  orders,
  orderId,
  buysellorderinfo,
  progress,
  editAd,
  paymentMethod,
  profilecomp,
  remark,
  paymentstep,
  appeal,
  appealhistory
} = primaryRoutes;

export const publicRoutesData = [
  {
    path: primaryRoutes.landing,
    component: <Landing />,
  },
  {
    path: login,
    component: <Login />,
  },
  {
    path: `${changePassword}/:id`,
    component: <ChangePassword />,
  },
  {
    path: resetPassword,
    component: <ResetPassword />,
  },
  {
    path: createAccount,
    component: <CreateAcount />,
  },
  {
    path: emailVerify,
    component: <VerifyEmail />,
  },
  {
    path: `${emailConfirmation}/:id`,
    component: <EmailConfirmation />,
  },
  {
    path: `${contactPublic}`,
    component: <Contact />,
  },
  {
    path: identity,
    component: <Identity />,
  },
  {
    path: verifyIdentity,
    component: <VerifyIdentity />,
  },
  {
    path: privacy,
    component: <Privacy />,
  },

  {
    path: reactiveUser,
    component: <EnableUser />
  }
];

export const privateRoutesData = [
  {
    path: ``,
    component: <Dashboard />,
  },
  {
    path: `${swap}`,
    component: <Swap />,
  },
  {
    path: authLanding,
    component: <Landing />,
  },
  {
    path: `${home}`,
    component: <Dashboard />,
  },
  {
    path: `${port}`,
    component: <Portfolio />,
  },
  {
    path: `${gridtrade}`,
    component: <GridTrading />,
  },
  {
    path: `${copytrade}`,
    component: <CopyTrading />,
  },
  {
    path: `${transactions}`,
    component: <Transactions />,
  },

  {
    path: `${overview}/:id`,
    component: <CoinDetail />,
  },
  {
    path: `${setting}`,
    component: <Setting />,
  },

  {
    path: `${order}`,
    component: <Order />,
  },
  {
    path: `${notfication}`,
    component: <Notifications />,
  },
  { path: `${addressManagement}`, component: <AdressManagement /> },
  {
    path: `${kyc}`,
    component: <Kyc />,
  },
  {
    path: `${deviceManagement}`,
    component: <DeviceManagement />,
  },
  {
    path: `${accountActivity}`,
    component: <AccountActivity />,
  },
  {
    path: `${gridtrade}/:id`,
    component: <GridDetail />,
  },
  {
    path: `${referral}`,
    component: <Referral />,
  },
  {
    path: `${referralViewAll}`,
    component: <ReferralViewAll />,
  },

  {
    path: `${myCopyTrade}`,
    component: <MyCopyTrading />,
  },
  {
    path: `${traderDetail}/:id`,
    component: <TraderDetail />,
  },
  {
    path: `${tradingParameter}/:id`,
    component: <TradingParameter />,
  },
  {
    path: `${copybotdetail}/:id`,
    component: <CopyBotSetting />,
  },
  {
    path: `${announcement}`,
    component: <Announcement />,
  },
  {
    path: `${contactPrivate}`,
    component: <Contact />,
  },
  {
    path: `${priceAlert}`,
    component: <PriceAlert />,
  },

  {
    path: `${p2p}`,
    component: <P2P />,
  },
  {
    path: `${orders}`,
    component: <Orders />,
  },
  {
    path: `${orderId}`,
    component: <Orders />,
  },
  {
    path: `${buysellorderinfo}`,
    component: <Buysellorderinfo />,
  },
  {
    path: `${progress}`,
    component: <Progressstep />,
  },
  {
    path: `${editAd}`,
    component: <EditAdv />,
  },
  {
    path: `${paymentMethod}`,
    component: <Paymentmethod />,
  },
  {
    path: `${profilecomp}`,
    component: <ProfileCompletion />,
  },
  {
    path: `${remark}`,
    component: <Progresslast />,
  },
  {
    path: `${paymentstep}`,
    component: <PaymentSteps />,
  },
  {
    path: `${appeal}`,
    component: <Appeal />,
  },
  {
    path: `${appealhistory}`,
    component: <AppealHistory />,
  },

];

export const commonRoutesData = [
  {
    path: "listing",
    component: <Listing />,
  },
  {
    path: launchpadViewAll,
    component: <LaunchpadViewAll />,
  }
];
