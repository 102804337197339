import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";

const Countdown = ({ targetDate, afterTimeCompletionHandler }) => {
  const { t } = useTranslation();
  const isValidDate = (targetDate) => {
    const now = moment();
    const futureDate = moment(targetDate);
    return futureDate.isValid()
  }

  const findDifference = (targetDate) => {
    const now = moment();
    const futureDate = moment(targetDate);
    return futureDate.diff(now);
  }

  const calculateTimeLeft = () => {
    if (!isValidDate(targetDate)) {
      // console.error("Invalid target date format");
      return {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
      };
    }

    const difference = findDifference(targetDate);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    if (
      timeLeft?.days === 0 &&
      timeLeft?.hours === 0 &&
      timeLeft?.minutes === 0 &&
      timeLeft?.seconds === 1
    ) {
      if (afterTimeCompletionHandler) {
        afterTimeCompletionHandler();
      }
    } else if (timeLeft?.days === 0 &&
      timeLeft?.hours === 0 &&
      timeLeft?.minutes === 0 &&
      timeLeft?.seconds === 0) {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [targetDate, timeLeft]);

  return (
    <div className={styles.cardBlock_Content_Duration}>
      <span>
        <strong>{String(timeLeft.days).padStart(2, "0")}</strong> {t("launchpad.days")}
      </span>
      <span>
        <strong>{String(timeLeft.hours).padStart(2, "0")}</strong> {t("launchpad.hours")}
      </span>
      <span>
        <strong>{String(timeLeft.minutes).padStart(2, "0")}</strong> {t("launchpad.mins")}
      </span>
      <span>
        <strong>{String(timeLeft.seconds).padStart(2, "0")}</strong>  {t("launchpad.secs")}
      </span>
    </div>
  );
};

export default Countdown;
