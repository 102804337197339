import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import { buildQueryString } from "../../../helpers";
import * as config from "../../../utils/apiVersion";
import { startLoading, stopLoading } from "../../feature";
import { toastSelector } from "../../selector/toastSelector";

export const getNickName = createAsyncThunk(
  "nickName/getNickName",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/market/nick_name`;
      const res = await API.get(config.p2p)(url);
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setNickName = createAsyncThunk(
  "nickName/setNickName",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      const res = await API.put(config.p2p)(
        `/market/nick_name`, payload
      );
      dispatch(stopLoading());
      dispatch(
        toastSelector({ message: t("services.nickname.successfully") }, "success")
      );
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      if (e.code !== 401) {
        dispatch(toastSelector(e, "error"));
      }
      return rejectWithValue(e);
    }
  }
);

export const setEditNickName = createAsyncThunk(
  "nickName/setEditNickName",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading());
      const res = await API.put(config.p2p)(
        `/market/nick_name/edit`, payload
      );
      dispatch(stopLoading());
      dispatch(
        toastSelector({ message: t("services.nickname.updated") }, "success")
      );
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      if (e.code !== 401) {
        dispatch(toastSelector(e, "error"));
      }
      return rejectWithValue(e);
    }
  }
);
