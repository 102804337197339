import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { clearState, showAlert, startLoading, stopLoading } from "../feature";
import { successToastSelector } from "../selector";
import { getUser } from "./user";

export const fetchLogout = createAsyncThunk(
  "logout/fetchLogout",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t, showMessHide } = payload
    delete payload.showMessHide;
    delete payload.t
    try {
      dispatch(startLoading(true));
      let url = `/identity/sessions`;
      await API.delete(config.barong)(url, payload);
      sessionStorage.clear();
      localStorage.clear();
      localStorage.removeItem("csrfToken");
      localStorage.removeItem("language");
      localStorage.removeItem("setBal");
      dispatch(clearState())
      await dispatch(getUser());
      if (!showMessHide) {
        dispatch(
          successToastSelector({ message: t("services.logout") }, "success")
        );
      }
      dispatch(stopLoading(false));
      return true;
    } catch (e) {
      dispatch(stopLoading(false));
      return false;
    }
  }
);
