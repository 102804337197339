import React from "react";
import style from "./style.module.scss";
import CommonButton from "../../Ui/button/CommonButton";
import { InfoCircleOutlined } from "@ant-design/icons";
import {  useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {  getReactivateOtp } from "../../../redux/services";
import { useNavigate } from "react-router-dom";
import { setEnableUser } from "../../../redux/feature";
import { loginTabsKey } from "../../../staticObjects";
function EnableUser({ onClose }) {
  const { enableUserData } = useSelector((state) => state?.login);


  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToEnableVerify = async () => {
    const sendData = {}
    let { email, session_type, phone_number } = enableUserData

    if(session_type === loginTabsKey.email){
      sendData["email"] = email
    }else{
      sendData["phone_number"] = phone_number

    }
    let res = await dispatch(getReactivateOtp(sendData))
    if (res.payload.status) {
        dispatch(setEnableUser(false))
        navigate("/reactiveUser");
    }
  };


  return (
    <div className={style.enableUser}>
      <div className={`${style.enableUserdata} kycModal`}>
        <InfoCircleOutlined />
        <h2>{t("enable_user.safety_tip")}</h2>
        <p>
        {t("enable_user.account_blocked")}
        </p>
        
        <div className="modalBtn">
          <CommonButton
            btn_custom
            themegraybtn
            title={t("enable_user.cancel")}
            width={50}
            onClick={onClose}
          />
          <CommonButton
             onClick={() =>redirectToEnableVerify()}
            btn_custom
            title={t("enable_user.unblock")}
          />
        </div>
      </div>
    </div>
  );
}
export default EnableUser;
