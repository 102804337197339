import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { setSuccess } from "../feature/exchange/exchanges.slice";

// service for fetching weather current user is PRO trader or not
export const checkIsProTrader = createAsyncThunk(
  "copyTrade/checkIsProTrader",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // dispatch(startLoading(true));
      let url = `/account/pro_trader/user`;
      let res = await API.get(config.copy)(url);
      // dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      // dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to become pro trader
export const becomeProTrade = createAsyncThunk(
  "copyTrade/becomeProTrade",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/pro_trader/new`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Pro trader created successfully " })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to fetch all the pro traders
export const getProTradersList = createAsyncThunk(
  "copyTrade/getProTradersList",
  async (payload, { rejectWithValue, dispatch }) => {
    const { isAuth } = payload;
    delete payload.isAuth;
    try {
      // dispatch(startLoading(true));
      let url;
      if (isAuth) {
        url = `/account/pro_trader/?${buildQueryString(payload)}`;
      } else {
        url = `/public/pro_trader/?${buildQueryString(payload)}`;
      }
      let res = await API.get(config.copyWithHeader)(url);
      // dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total > 0 ? res?.headers?.total : 0,
        status: true,
      };
    } catch (e) {
      // dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to get current user's follower list
export const getFollowerList = createAsyncThunk(
  "copyTrade/getFollowerList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/follower?${buildQueryString(payload)}`;
      let res = await API.get(config.copyWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getProTraderFollowerList = createAsyncThunk(
  "copyTrade/getProTraderFollowerList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/follower/list?${buildQueryString(payload)}`;
      let res = await API.get(config.copyWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to add  following for normal user
export const addToFollowingList = createAsyncThunk(
  "copyTrade/addToFollowingList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/follower`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Added to following successfully " })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to remove  following for normal user
export const removeFromFollowingList = createAsyncThunk(
  "copyTrade/removeFromFollowingList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `account/bot_setting/unfollow`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Remove from following successfully " })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to current  user's watch list
export const getWatchList = createAsyncThunk(
  "copyTrade/getWatchList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/watch_list?${buildQueryString(payload)}`;
      let res = await API.get(config.copyWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to add  user's watch list
export const addIntoWatchList = createAsyncThunk(
  "copyTrade/addIntoWatchList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/watch_list`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Added to watchlist successfully " })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to remove form  current user's watch list
export const removeFromWatchList = createAsyncThunk(
  "copyTrade/addIntoWatchList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/watch_list`;
      let res = await API.delete(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Remove from watchlist successfully " })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to get single pro trader detalil

export const singleProTraderDetails = createAsyncThunk(
  "copyTrade/singleProTraderDetails",
  async (payload, { rejectWithValue, dispatch }) => {
    const { show_details, id } = payload;
    delete payload.id;
    try {
      dispatch(startLoading(true));
      let url;
      if (show_details) {
        url = `/account/pro_trader/${id}?${buildQueryString(payload)}`;
      } else {
        delete payload.show_details;
        url = `/account/pro_trader/${payload}`;
      }
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// Service to create BOT Setting Copy trade

export const createCopyBotSetting = createAsyncThunk(
  "copyTrade/createCopyBotSetting",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `account/bot_setting`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const updateProTrader = createAsyncThunk(
  "copyTrade/updateProTrader",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/pro_trader/update`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Pro Trader Updated successfully " })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const updateTradingPairs = createAsyncThunk(
  "copyTrade/updateTradingPairs",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/pro_trader/update/trading_pairs`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Trading Pairs Updated successfully" })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// Update Pro Trader trading pair

export const updateProTraderPairs = createAsyncThunk(
  "copyTrade/updateProTraderPairs",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/pro_trader/update/trading_pairs`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Trading pair Updated successfully " })
      );
      dispatch(stopLoading(false));
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// API to get conversion analytics data

export const getProTraderCommision = createAsyncThunk(
  "copyTrade/getProTraderCommision",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/commissions?${buildQueryString(payload)}`;
      let res = await API.get(config.copyWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// API to get conversion revenue data

export const getProTraderRevenue = createAsyncThunk(
  "copyTrade/getProTraderRevenue",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/commissions?${buildQueryString(payload)}`;
      let res = await API.get(config.copyWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getProTraderBalance = createAsyncThunk(
  "copyTrade/getProTraderBalance",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/pro_trader_balance?${buildQueryString(payload)}`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

// API Service to get market position

export const getCopyMarketPosition = createAsyncThunk(
  "copyTrade/getCopyMarketPosition",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/market/positions?${buildQueryString(payload)}`;
      let res = await API.get(config.copyWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getCopierDetails = createAsyncThunk(
  "copyTrade/getCopierDetails",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/portfolio?${buildQueryString(payload)}`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

//service for updatePosition status

export const updatePostionProtrader = createAsyncThunk(
  "copyTrade/updatePostionProtrader",
  async (payload, { rejectWithValue, dispatch }) => {
    const id = payload?.id;
    const market = payload?.market;

    delete payload?.id;
    delete payload?.market;

    try {
      dispatch(startLoading(true));
      let url = `/market/position/${id}`;
      let res = await API.put(config.copy)(url, payload);
      dispatch(stopLoading(false));
      dispatch(
        successToastSelector({ message: "Position closed successfully " })
      );
      dispatch(
        getCopyMarketPosition({
          limit: 10,
          page: 1,
          market,
          tab: "current",
        })
      );
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const submitCopyOrders = createAsyncThunk(
  "copyTrade/submitCopyOrders",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    const tab = payload.tab;
    delete payload.tab;
    try {
      dispatch(startLoading(true));
      let url = `market/orders`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: t("services.order_created") })
        );
        dispatch(setSuccess(true));
      }

      let data = {
        limit: 10,
        page: 1,
        market: payload.market,
      };

      switch (tab) {
        case "Current Positions":
          data["tab"] = "current";
          dispatch(getCopyMarketPosition(data));
          break;

        case "Orders History":
          data["tab"] = "history";
          data["state"] = ["done", "cancel", "reject"];
          dispatch(getCopyOrderHistory(data));

          break;

        case "Current orders":
          data["tab"] = "current";
          data["state"] = ["wait"];
          dispatch(getCopyOrderHistory(data));

          break;

        case "Positions History":
          data["tab"] = "history";
          dispatch(getCopyMarketPosition(data));
          break;
        default:
      }
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getCopyUsdtBalance = createAsyncThunk(
  "copyTrade/getCopyUsdtBalance",

  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/usdt_balance`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getBotProperties = createAsyncThunk(
  "copyTrade/getBotProperties",
  async (payload, { rejectWithValue, dispatch }) => {
    const { isAuth } = payload;
    delete payload.isAuth;
    try {
      dispatch(startLoading());
      let url;
      if (isAuth) {
        url = `/account/bot_property`;
      } else {
        url = `/public/markets/bot_property`;
      }
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const transferCopyFund = createAsyncThunk(
  "copyTrade/transferCopyFund",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/pro_trader/transfer_balance`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Fund transfered successfully " })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getCopyOrderHistory = createAsyncThunk(
  "copyTrade/getCopyOrderHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `market/orders?${buildQueryString(payload)}`;
      let res = await API.get(config.copyWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getSingleBotSettingData = createAsyncThunk(
  "copyTrade/getSingleBotSettingData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `account/bot_setting?${buildQueryString(payload)}`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading(false));
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// Service to update BOT Setting Copy trade

export const updateCopyBotSetting = createAsyncThunk(
  "copyTrade/updateCopyBotSetting",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `account/bot_setting/update`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(successToastSelector({ message: "Updated successfully " }));
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getCopyPnlGraph = createAsyncThunk(
  "copyTrade/getCopyPnlGraph",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/pnl_graph/?${buildQueryString(payload)}`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getCopyRoiGraph = createAsyncThunk(
  "copyTrade/getCopyRoiGraph",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/roi_graph?${buildQueryString(
        payload
      )}                                                                                                                                                                                                                                                                                           `;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getTradingVolumeGraph = createAsyncThunk(
  "copyTrade/getTradingVolumeGraph",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/trade_volume_graph/?${buildQueryString(payload)}`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading(false));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelCopyOrder = createAsyncThunk(
  "copyTrade/cancelCopyOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading(true));
      let url = `/market/orders/${payload.id}/cancel`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(successToastSelector({ message: t("services.order_cancel") }));
        dispatch(setSuccess(true));
      }
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelAllCopyOrder = createAsyncThunk(
  "copyTrade/cancelAllCopyOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading(true));
      let url = `/market/orders/cancel`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(successToastSelector({ message: t("services.all_cancel") }));
        dispatch(setSuccess(true));
      }
      return { status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getProTraderAvatar = createAsyncThunk(
  "copyTrade/getProTraderAvatar",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/account/avatar`;
      let res = await API.get(config.copy)(url, payload);
      return { list: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

// service to become pro trader
export const reapplyProTrader = createAsyncThunk(
  "copyTrade/reapplyProTrader",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/pro_trader/reapply`;
      let res = await API.post(config.copy)(url, payload);
      dispatch(
        successToastSelector({ message: "Pro trader reapplied successfully" })
      );
      dispatch(stopLoading(false));
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);
