export const priceTableDataIndex = {
  name: "name",
  price: "price",
  graph: "graph",
  change24h: "change24h",
  marketCap: "marketCap",
  action: "action",
};

export const portTableDataIndex = {
  coin: "coin",
  name: "name",
  totalBal: "totalBal",
  availableBal: "availableBal",
  inOrder: "inOrder",
  action: "action",
  funding_balance: "funding_balance",
  grid_balance: "grid_balance",
  copy_balance: "copy_balance"
};

export const earnTableDataIndex = {
  coin: "coin",
  price: "price",
  percentage: "Percentage",
  stakedAmount: "stakedAmount",
  totalDuration: "totalDuration",
  durationLeft: "durationLeft",
  earnings: "earnings",
};
export const addressTableIndex = {
  AddressLable: "AddressLable",
  Coin: "Coin",
  Address: "Address",
  Network: "Network",
  Action: "Action",
};

export const assetTableDataIndex = {
  asset: "asset",
  balance: "balance",
  action: "action",
};

export const transHistoryTableDataIndex = {
  date: "date",
  txid: "txid",
  spend: "spend",
  youBuy: "youBuy",
  fee: "fee",
  order: "order",
  payment: "payment",
  status: "status",
  action: "action",
};

export const settingOrdersTableDataIndex = {
  datetime: "datetime",
  pair: "pair",
  type: "type",
  side: "side",
  average: "average",
  price: `price`,
  filled: `filled`,
  remainingAmt: `remainingAmt`,
  total: "total",
  status: "status",
};

export const coinViewTableIndex = {
  coin: "coin",
  ratio: "ratio",
  amount: "amount",

}

export const spotTableIndex = {
  coin: "coin",
  name: "name",
  totalBal: "totalBal",
  availableBal: "availableBal",
  inOrder: "inOrder",
  action: "action",
  today_pL: "today_pL",
  trade: "trade",
  spot_balance: "spot_balance"
}


export const walletTableIndex = {
  wallet: "wallet",
  ratio: "ratio",
  amount: "amount",
}

export const landingTableIndex = {
  price: "price",
  name: "name",
  volume: "volume",
  graph: "graph",
  price_change_percent: "price_change_percent"
}