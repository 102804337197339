import { createSlice } from "@reduxjs/toolkit";
export const selectedData = createSlice({
  name: "selectedData",
  initialState: {
    data: {},
    tab: "0",
  },
  reducers: {
    initSelectedData: (state, action) => { 
      state.data = { ...action.payload };
    },
    setSelectedTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});
export default selectedData.reducer;
export const { initSelectedData, setSelectedTab } = selectedData.actions;
