import React, { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import CommonButton from "../button/CommonButton.jsx";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { createPairList, currencyDropObj } from "../../../helpers";
import {
  buySellFilterOpt,
  convertFilterOpt,
  orderFilterOpt,
  receiveFilterOpt,
  sendFilterOpt,
  settingHisotryTabsKey,
  walletFilterOpt,
} from "../../../staticObjects/primary.jsx";
import {
  getConvertHistory,
  getDepositHistory,
  getTransHisTrades,
  getWithdrawHistory,
} from "../../../redux/services/transHistories.js";
import {
  getBalanceHistory,
  getDefiWalletData,
  getOrderHistory,
  getTrades,
} from "../../../redux/services";
import { viewForTrans } from "../../../redux/feature/index.js";
import { fetchBuySellHistory } from "../../../redux/services/buySellServices.js";
import DatePickerCustom from "../DatePickerCustom/DatePickerCustom.jsx";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { setFilter } from "../../../redux/feature/exchange/exchanges.slice.js";

function Filter(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currenciesList, currentMarketId } = useSelector(
    (state) => state.commonApiData
  );
  const initData = {
    startDate: "",
    endDate: "",
  };
  const { marketPairList } = useSelector((state) => state.transactionHistories);
  const [selectCurrency, setSelectCurrency] = useState("");
  const [selectPair, setSelectPair] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState({ ...initData });
  const { startDate, endDate } = selectedDate;
  const {
    buy,
    sell,
    convert,
    send,
    receive,
    orders,
    trades,
    transfer,
    wallet,
  } = settingHisotryTabsKey;
  const { currentTab, cbFun } = props;

  const onloadSelectPair = () => {
    if (!marketPairList.length) return;
    const pairList = createPairList(marketPairList, t);
    let pair = currentMarketId ? currentMarketId : pairList[0]?.value;
    return { pair, zerothIndexPair: pairList[0]?.value };
  };

  useEffect(() => {
    if (!marketPairList?.length) return;
    setSelectPair(onloadSelectPair().pair);
  }, [marketPairList]);

  const isShowStatus = [
    buy,
    sell,
    convert,
    send,
    receive,
    orders,
    wallet,
  ].includes(currentTab);
  const isShowCurrency = [send, receive, transfer].includes(currentTab);
  const isShowPair = [orders, trades].includes(currentTab);

  const filterFun = (clicked = "filter") => {
    const init = { limit: 10, page: 1 };
    const unixStartDate = Math.floor(moment(startDate).valueOf() / 1000);
    // Add one day to the end date and then convert to Unix timestamp (in seconds)
    const unixEndDate = Math.floor(
      moment(endDate).add(1, "day").valueOf() / 1000
    );
    const isState =
      isShowStatus && filterStatus.length > 0
        ? filterStatus === "collected" && currentTab !== "wallet"
          ? { state: ["collected", "collecting"] } :
          filterStatus === "collected" && currentTab === "wallet"
            ? { state: ["collected"] } :
            currentTab === "wallet"
              ? { status: filterStatus }
              : { state: filterStatus }
        : {};
    const isCurrency =
      isShowCurrency && selectCurrency
        ? {
          [currentTab === transfer ? "currency_id" : "currency"]:
            selectCurrency,
        }
        : {};
    const isMarket = isShowPair && selectPair ? { market: selectPair } : {};
    const sendData =
      clicked === "filter"
        ? {
          ...init,
          ...isState,
          ...isCurrency,
          ...isMarket,
        }
        : {
          ...init,
          ...(isShowPair && {
            market: onloadSelectPair().zerothIndexPair,
          }),
        };

    if (
      (currentTab === "trades" || currentTab === "orders") &&
      clicked === "filter"
    ) {
      sendData["from"] = startDate && unixStartDate;
      sendData["to"] = endDate && unixEndDate;
    }
    cbFun({ ...sendData });
    switch (currentTab) {
      case buy:
        sendData["transak_type"] = "BUY";
        dispatch(fetchBuySellHistory({ ...sendData }));

        break;
      case sell:
        sendData["transak_type"] = "SELL";

        dispatch(fetchBuySellHistory({ ...sendData }));

        break;
      case send:
        sendData["transaction_type"] = props?.withdrawType;
        dispatch(getWithdrawHistory({ ...sendData }));
        break;
      case receive:
        sendData["transaction_type"] = props?.depositType == "internal" ? 300 : 400;
        dispatch(getDepositHistory({ ...sendData }));
        break;
      case wallet:
        dispatch(getDefiWalletData({ ...sendData }));
        break;
      case convert:
        dispatch(getConvertHistory({ ...sendData }));
        break;
      case orders:
        if (endDate !== "" && startDate > endDate) {
          message.error(t("validations.fromDate"));
          return;
        }
        dispatch(
          getOrderHistory({
            ...sendData,
            callFrom: "setting",
            orderBy: "desc",
          })
        );
        break;
      case trades:
        if (endDate !== "" && startDate > endDate) {
          message.error(t("validations.fromDate"));
          return;
        }
        dispatch(setFilter({ ...sendData }));
        dispatch(getTransHisTrades({ ...sendData }));
        break;
      case transfer:
        dispatch(
          getBalanceHistory({
            ...sendData
          })
        );
        break;
      default:
    }
  };

  const resetStates = () => {
    setSelectCurrency("");
    setFilterStatus("");
    setSelectedDate({
      startDate: "",
      endDate: "",
    });
    if (marketPairList.length > 0) {
      setSelectPair(onloadSelectPair().zerothIndexPair);
    }
    dispatch(viewForTrans({ clickViewFrom: "", marketId: "" }));
  };
  const optionObj = () => {
    if (currentTab === buy || currentTab == sell) return buySellFilterOpt;
    if (currentTab === convert) return convertFilterOpt;
    if (currentTab === send) return sendFilterOpt;
    if (currentTab === receive) return receiveFilterOpt;
    if (currentTab === orders) return orderFilterOpt;
    if (currentTab === wallet) return walletFilterOpt;
  };

  return (
    <>
      <div className="filtrspacing">
        <Row gutter={21}>
          <Col lg={20} md={24} xs={24}>
            <Row gutter={21}>
              {isShowCurrency && (
                <Col lg={6} xl={4} md={12} xs={24}>
                  <CustomSelect
                    style={{ minWidth: "150px" }}
                    placeholder={t("placeholder.all_curr")}
                    label={t("labels.select_currency")}
                    drop_data={currencyDropObj(currenciesList, t)}
                    onChange={(currency) => setSelectCurrency(currency)}
                    value={selectCurrency}
                  />
                </Col>
              )}
              {isShowStatus && (
                <Col lg={6} xl={4} md={12} xs={24}>
                  <CustomSelect
                    placeholder={t("placeholder.all_status")}
                    label={t("labels.status")}
                    drop_data={optionObj()}
                    onChange={(val) => setFilterStatus(val)}
                    value={filterStatus}
                  />
                </Col>
              )}

              {isShowPair && (
                <Col lg={6} xl={4} md={12} xs={24}>
                  <CustomSelect
                    placeholder={t("placeholder.all_pair")}
                    label={t("labels.pair")}
                    drop_data={createPairList(marketPairList, t)}
                    onChange={(val) => setSelectPair(val)}
                    value={selectPair}
                  />
                </Col>
              )}

              {isShowPair && (
                <>
                  <Col lg={6} xl={4} md={12} xs={24}>
                    <div style={{ marginBottom: "10px" }}>
                      <DatePickerCustom
                        datepickerSimple
                        value={selectedDate?.startDate}
                        label={t("rewardRefHis.start_date")}
                        placeholder="2005-05-02"
                        className="datepickereasy"
                        allowClear={false}
                        onChange={(value, dateString) => {
                          const startdata = dateString
                            ? moment(dateString)
                            : "";
                          setSelectedDate({
                            ...selectedDate,
                            startDate: startdata,
                          });
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg={6} xl={4} md={12} xs={24}>
                    <div style={{ marginBottom: "10px" }}>
                      <DatePickerCustom
                        datepickerSimple
                        value={selectedDate?.endDate}
                        label={t("rewardRefHis.end_date")}
                        placeholder="2005-05-02"
                        className="datepickereasy"
                        onChange={(value, dateString) => {
                          const enddate = dateString ? moment(dateString) : "";
                          setSelectedDate({
                            ...selectedDate,
                            endDate: enddate,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col lg={24} xl={4} md={24} xs={24}>
            <div className="mb24 centerbutns">
              <CommonButton
                title={t("buttons.search")}
                className="btn"
                onClick={() => filterFun("filter")}
              />
              <CommonButton
                title={t("buttons.reset_btn")}
                className="btn"
                onClick={() => {
                  resetStates();
                  filterFun("reset");
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Filter;
