import { createSlice } from "@reduxjs/toolkit";
import { getMessage, sendMessage } from "../../../services/P2P";

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messageList: [],
    totalMessage: 0,
    error: false,
    messageLoader: false,
    newMessageList: [],
  },
  reducers: {
    appendNewMessageP2p: (state, action) => {
      state.messageList = [...state.messageList, action.payload];
      state.newMessageList = [action.payload, ...state.newMessageList];
    },
    blankNewMessage: (state) => {
      state.newMessageList = [];
    },
    blankMessageList: (state) => {
      state.messageList = [];
      state.totalMessage = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessage.pending, (state) => {
        state.error = false;
        state.messageLoader = true;
        state.messageList = [];
      })
      .addCase(getMessage.fulfilled, (state, action) => {
        let reversNew = [...action.payload.data]?.reverse();
        state.messageList = [...reversNew, ...state.messageList];
        state.totalMessage = action.payload.headers.total;
        state.messageLoader = false;
      })
      .addCase(getMessage.rejected, (state) => {
        state.error = true;
        state.messageLoader = false;
      })
      .addCase(sendMessage.pending, (state) => {
        state.error = false;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        if (action.payload.fromWhere === "enter") {
          state.messageList = [...state.messageList, action.payload.res];
        }
      })
      .addCase(sendMessage.rejected, (state) => {
        state.error = true;
        state.messageLoader = false;
      });
  },
});

export default chatSlice.reducer;
export const { appendNewMessageP2p, blankNewMessage, blankMessageList } =
  chatSlice.actions;
