import { createSlice } from "@reduxjs/toolkit";
import { getIdentity } from "../../services";

export const cryptoAddressSlice = createSlice({
    name: "cryptoAddress",
    initialState: {
        error: false,
        address: ""
    },
    reducers: {
        // setCryptoAddress: (state, action) => {
        //     state.address = action.payload;
        // },
    },

});
export default cryptoAddressSlice.reducer;
export const { } = cryptoAddressSlice.actions;
