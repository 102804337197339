export function calculateDuration(startData, endDate) {
    // Specified date
    const specifiedDate = new Date(startData);

    // Current date
    const currentDate = endDate ? new Date(endDate) : new Date();

    // Calculate the difference in milliseconds
    const differenceMs = currentDate - specifiedDate;

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(differenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));

    // Return the duration as an object
    return { hours, minutes };
}