import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import { buildQueryString } from "../../../helpers";
import { startLoading, stopLoading } from "../../feature";
import { toastSelector } from "../../selector";
import * as config from "../../../utils/apiVersion";

export const sendFeedback = createAsyncThunk(
  "feedback/sendFeedback",
  async (payload, { dispatch }) => {
    const { t } = payload;
    try {
      dispatch(startLoading())
      let url = `/feedback?${buildQueryString(payload)}`;
      const res = await API.post(config.p2p)(url, payload);
      dispatch(stopLoading());
      dispatch(
        toastSelector({ message: t("services.feedback.submitted") }, "success")
      );
      return { status: true, state: res.state };
    }
    catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
)

export const getFeedback = createAsyncThunk(
  "feedback/getFeedback",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading())
      let url = `/feedback/bid/feedback?${buildQueryString(payload)}`;
      const res = await API.get(config.p2p)(url, payload);
      dispatch(stopLoading());
      return { list: res, status: true };
    }
    catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
)

export const getUserFeedback = createAsyncThunk(
  "feedback/getUserFeedback",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading())
      let url = `/feedback/user/feedback?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.p2pWithHeader)(url, payload);
      dispatch(stopLoading());
      return { data: data, status: true, total: headers?.total };
    }
    catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
)