import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../api";
import { buildQueryString } from "../../../helpers";
import * as config from "../../../utils/apiVersion";
import { startLoading, stopLoading } from "../../feature";
import { toastSelector } from "../../selector/toastSelector";

export const getAllP2pOrders = createAsyncThunk(
  "p2pOrder/getAllP2pOrders",
  async (payload, { rejectWithValue, dispatch }) => {
    const pageName = payload.pagename;
    try {
      dispatch(startLoading());
      if (pageName) dispatch(startLoading());
      let url = `/market/orders?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.p2pWithHeader)(url);
      if (pageName) dispatch(stopLoading());
      dispatch(stopLoading());
      return { list: data, total: headers.total };

    } catch (e) {
      if (pageName) dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      dispatch(stopLoading());
      return rejectWithValue(e);
    }
  }
);

export const getUsdPrice = createAsyncThunk(
  "p2pOrder/getUsdPrice",
  async (payload, { rejectWithValue, dispatch }) => {
    const pageName = payload.pagename;
    try {
      if (pageName) dispatch(startLoading());
      let url = `/market/orders/currency/best_price?${buildQueryString(payload)}`;
      const res = await API.get(config.p2p)(url);
      if (pageName) dispatch(stopLoading());
      return { data: res };
    } catch (e) {
      if (pageName) dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getLimitPrice = createAsyncThunk(
  "currencies/getLimitPrice",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/public/currencies/best_price`;
      const res = await API.post(config.peatio)(url, payload);
      return res;
    } catch (e) {
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getMyOrders = createAsyncThunk(
  "p2pOrder/getMyOrders",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/orders?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.p2pWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, total: headers?.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleOrder = createAsyncThunk(
  "p2pOrder/getSingleOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    const { location, id } = payload
    try {
      dispatch(startLoading());
      let url;
      if (location) {
        url = `/market/orders/${id}`;
      }
      else {
        url = `/market/orders/${payload}`;
      }
      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createBid = createAsyncThunk(
  "p2pOrder/createBid",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/bids`;
      const res = await API.post(config.p2p)(url, payload);
      dispatch(stopLoading());
      return { bidId: res.id, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const getBid = createAsyncThunk(
  "p2pOrder/getBid",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/bids/${payload}`;
      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const postBid = createAsyncThunk(
  "p2pOrder/postBid",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/bids`;
      const res = await API.post(config.p2p)(url, payload);
      dispatch(stopLoading(false));
      return { bidId: res.id, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return false;
    }
  }
);

export const getAllBids = createAsyncThunk(
  "p2pOrder/getAllBids",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/bids?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.p2pWithHeader)(url);
      dispatch(stopLoading());
      return { data: data, total: headers?.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const profitandloss = createAsyncThunk(
  "p2pOrder/profitandloss",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "/market/user_profile/profit_&_loss";
      if (typeof payload != "undefined") {
        url = `/market/user_profile/profit_&_loss?${buildQueryString(payload)}`;
      }
      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getExpressBid = createAsyncThunk(
  "p2pOrder/getExpressBid",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/market/bid/express?${buildQueryString(payload)}`;
      const res = await API.get(config.p2p)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getCountries = createAsyncThunk(
  "p2pOrder/getCountries",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/public/p2p_country`;
      const res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);