import React from "react";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
import Edit from "../../Assets/Images/Edit.svg";
import { useTranslation } from "react-i18next";
function GridDetailsCard({ data, renderComp }) {
  const { t } = useTranslation();
  return (
    <div className={style.detailsCard}>
      {renderComp(
        data.map((ele, index) => (
          <div className={style.detailsCard__card} key={index}>
            {ele.key && (
              <p className={ele.underLine ? t("griddetails.underLine") : ""}>{ele.key} </p>
            )}
            <p>
              {ele.dot && <div className="dot"></div>}
              {ele.value}
            </p>
            {ele.editBtn && (
              <Link>
                <span className="editButton">{t("griddetails.buttons.edit")}</span>{" "}
                <img src={Edit} alt="Edit" />
              </Link>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default GridDetailsCard;
